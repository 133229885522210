import Konva from "konva";
import { useEffect, useState } from "react";

type CoordinateSystem = "screen-space" | "stage-space";

const FrameRate = 30;
const Delay = 1000 / FrameRate;

export default function useStagePointerPosition(stage: Konva.Stage | null | undefined, coords: CoordinateSystem) {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  useEffect(() => {
    let id = 0;
    let tr = new Konva.Transform();
    if (!stage) return;

    function pollScreen() {
      let pos = stage?.getPointerPosition();
      setMousePos(prev => {
        if (pos && (prev.x != pos.x || prev.y != pos.y)) {
          return pos;
        }
        return prev;
      })
    }

    function pollStage() {
      let pos = stage?.getPointerPosition();
      if (!pos) return;
      stage!.getAbsoluteTransform().copyInto(tr);
      tr.invert();
      let absolutePos = tr.point(pos);
      absolutePos.x = Math.round(absolutePos.x);
      absolutePos.y = Math.round(absolutePos.y);
      setMousePos(prev => {
        if (prev.x != absolutePos.x || prev.y != absolutePos.y) {
          return absolutePos;
        }
        return prev;
      })
    }

    id = window.setInterval(coords == "screen-space" ? pollScreen : pollStage, Delay);

    return () => window.clearInterval(id);
  }, [stage, coords]);
  return mousePos;
}