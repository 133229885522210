import React from "react";
import { Html } from "react-konva-utils";
import { GanttAddTaskIcon } from "elements/gantt/icons/gantt-add-task-icon";
import styles from "./add-actions.module.css";

type AddTaskButtonProps = {
  x: number;
  y: number;
  onNewTask: () => void;
  onNewGroup: () => void;
  onNewColumn: () => void;
};

export const AddActions = ({ x, y, onNewTask, onNewGroup, onNewColumn }: AddTaskButtonProps) => {
  return (
    <Html
      groupProps={{
        x: x,
        y: y,
      }}
    >
      <div
        className={styles.wrapper}
        style={{
          transform: "translate(-100%, 0)",
        }}
      >
        <div className={styles.button} onClick={onNewGroup}>
          <GanttAddTaskIcon /> Add Row
        </div>

        <div className={styles.button} onClick={onNewColumn}>
          <GanttAddTaskIcon /> Add Column
        </div>

        <div className={styles.button} onClick={onNewTask}>
          <GanttAddTaskIcon /> Add Task
        </div>
      </div>
    </Html>
  );
};
