// A "Trait" is any property of a canvas-element that can be changed in
// the floating toolbar menus
export enum Trait {
  textColor = "textColor",
  fontSize = "fontSize",
  align = "align",
  fontProps = "fontProps",
  font = "font",

  stickyNoteColor = "stickyNoteColor",

  shape = "shape",
  shapeStrokeColor = "shapeStrokeColor",
  shapeStrokeWidth = "shapeStrokeWidth",
  fillColor = "fillColor",
  drawingLineWidth = "drawingLineWidth",

  lineColor = "lineColor",
  connectorLineWidth = "connectorLineWidth",
  lineStyle = "lineStyle",
  dash = "dash",
  arrowHeads = "arrowHeads",
  visible = "visible",

  mindmapColor = "mindmapColor",
  mindmapOrientation = "mindmapOrientation",

  startDate = "startDate",
  endDate = "endDate",

  mindmapOrgChartFields = "mindmapOrgChartFields",
  mindmapOrgChartColor = "mindmapOrgChartColor",
  mindmapOrgChartColumnValues = "mindmapOrgChartColumnValues",

  orgchartOrientation = "orgchartOrientation",
  orgChartFields = "orgChartFields",
  orgChartNodeColor = "orgChartNodeColor",
  orgChartNodeStroke = "orgChartNodeStroke",
  orgChartNodeStrokeDash = "orgChartNodeStrokeDash",
  orgChartNodeStrokeWidth = "orgChartNodeStrokeWidth",
  orgChartItemViewType = "orgChartItemViewType",

  frameAspectRatio = "frameAspectRatio",

  liveIntegration = "liveIntegration",

  integrationItemColor = "integrationItemColor",

  tableFillColor = "tableFillColor",
  tableStrokeColor = "tableStrokeColor",

  tableAddColumn = "tableAddColumn",
  tableAddRow = "tableAddRow",
}

// object with all traits as fields
type IAllTraits = Record<keyof typeof Trait, any>;

// partial object of traits
export type ITraits = Partial<IAllTraits>;

// Mapping of trait names to the field name in canvas element data schema
export const TraitToFieldName: Record<Trait, string> = {
  align: "align",
  fontProps: "fontProps",
  fontSize: "fontSize",
  font: "font",
  stickyNoteColor: "fill",
  textColor: "textColor",
  shape: "type",
  shapeStrokeColor: "border",
  fillColor: "fill",
  lineColor: "stroke",
  lineStyle: "lineType",
  dash: "dash",
  arrowHeads: "pointerStyles",
  visible: "visible",
  connectorLineWidth: "strokeWidth",
  shapeStrokeWidth: "strokeWidth",
  drawingLineWidth: "strokeWidth",
  mindmapColor: "color",
  mindmapOrientation: "orientation",
  mindmapOrgChartFields: "selectedFields",
  mindmapOrgChartColor: "selectedColorFieldId",
  mindmapOrgChartColumnValues: "selectedFilter",
  orgchartOrientation: "orientation",
  orgChartFields: "extraFields",
  orgChartNodeColor: "orgchart-node-color",
  orgChartNodeStroke: "orgchart-node-stroke",
  orgChartNodeStrokeDash: "orgchart-node-stroke-dash",
  orgChartNodeStrokeWidth: "orgchart-node-stroke-width",
  orgChartItemViewType: "viewType",
  frameAspectRatio: "frameAspectRatio",
  liveIntegration: "liveIntegration",
  integrationItemColor: "fill",
  startDate: "startDate",
  endDate: "endDate",
  tableFillColor: "fill",
  tableStrokeColor: "stroke",
  tableAddColumn: "cols",
  tableAddRow: "rows",
};

// names chosen for backward compatibility with old tracking events
export const TraitTrackingName: Record<Trait, string> = {
  textColor: "text-color",
  fontSize: "text-size",
  font: "font",
  align: "text-align",
  fontProps: "text-style",
  stickyNoteColor: "color",
  shape: "shape-type",
  shapeStrokeColor: "stroke-color",
  fillColor: "color",
  lineColor: "color",
  lineStyle: "line-type",
  dash: "dash",
  arrowHeads: "endpoint-type",
  visible: "visible",
  connectorLineWidth: "connector-line-width",
  shapeStrokeWidth: "shape-stroke-width",
  drawingLineWidth: "drawing-line-width",
  mindmapColor: "mindmap-color",
  mindmapOrientation: "mindmap-orientation",
  mindmapOrgChartFields: "mindmap-org-chart-fields",
  mindmapOrgChartColor: "mindmap-org-chart-color",
  mindmapOrgChartColumnValues: "mindmap-org-column-value",
  orgchartOrientation: "orgchart-orientation",
  orgChartFields: "orgchart-displayed-fields",
  liveIntegration: "live-integration",
  orgChartNodeColor: "orgchart-node-color",
  orgChartNodeStroke: "orgchart-node-stroke",
  orgChartNodeStrokeDash: "orgchart-node-stroke-dash",
  orgChartNodeStrokeWidth: "orgchart-node-stroke-width",
  orgChartItemViewType: "orgchart-item-view",
  frameAspectRatio: "frame-aspect-ratio",
  integrationItemColor: "integration-color",
  startDate: "start-date",
  endDate: "end-date",
  tableFillColor: "table-fill",
  tableStrokeColor: "table-stroke",
  tableAddColumn: "table-add-column",
  tableAddRow: "table-add-row",
};
