import React from "react";

export const GanttAddTaskIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" fill="none" viewBox="0 0 13 12">
      <path
        fill="#0072FF"
        fillRule="evenodd"
        d="M6.825 5.214V0H5.25v5.214H0v1.565h5.25V12h1.575V6.78h5.247V5.213z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
