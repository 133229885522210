import { getPathPrefix } from "frontend/utils/getPathPrefix";
import { TipName } from "shared/datamodel/schemas";

export type WalkthroughTipModel = {
  id: TipName;
  title: string;
  text: string;
  image: string;
  buttonTitle?: string;
  video?: string;
};

export const walkthroughTips = {
  [TipName.walkthroughExploreOrgChart]: {
    id: TipName.walkthroughExploreOrgChart, 
    title: "Get started with org charts",
    text: "Check out this step-by-step guide to create an org chart tailored to your needs",
    image: getPathPrefix("/images/walkthrough/org-chart.png"),
    video: "UhUeyqMYNok",
  },
  [TipName.walkthroughInviteMembers]: {
    id: TipName.walkthroughInviteMembers,
    title: "Invite your teammates",
    text: "Invite team members to your canvas via email or by copying and sharing the link.",
    image: getPathPrefix("/images/walkthrough/invite-teammates.png"),
  },
  [TipName.walkthroughAddTemplate]: {
    id: TipName.walkthroughAddTemplate,
    title: "Add a template",

    text: "Explore and choose the perfect template for your workflow, get started in seconds.",
    image: getPathPrefix("/images/walkthrough/add-template.png"),
  },
  [TipName.walkthroughAddElement]: {
    id: TipName.walkthroughAddElement,
    title: "Use the tool kit",
    text: "Add  shapes, connectors, text, sticky notes & sketches. Change colors and sizes to fit your needs.",
    image: getPathPrefix("/images/walkthrough/add-elements.png"),
  },
  [TipName.walkthroughDropComment]: {
    id: TipName.walkthroughDropComment,
    title: "Drop a comment",
    text: "Comment on anything, @mention your teammates and respond to feedback. Manage your comments from the side panel.",
    image: getPathPrefix("/images/walkthrough/add-comment.png"),
  },
  [TipName.walkthroughCreateFrame]: {
    id: TipName.walkthroughCreateFrame,
    title: "Create a frame",
    text: "Draw a frame to create structure to your canvas’s content and navigate through the frame list. Manage your frames from the side panel.",
    image: getPathPrefix("/images/walkthrough/add-frame.png"),
  },
  [TipName.walkthroughAddTask]: {
    id: TipName.walkthroughAddTask,
    title: "Add a task",
    text: "Expand to edit the title, add a description, tags, assignee and due date.",
    image: getPathPrefix("/images/walkthrough/add-task.png"),
  },
  [TipName.walkthroughUploadImage]: {
    id: TipName.walkthroughUploadImage,
    title: "Upload an image",
    text: "Browse your files and upload or drag & drop an image on your canvas.",
    image: getPathPrefix("/images/walkthrough/add-image.png"),
  },
  [TipName.walkthroughPresentationMode]: {
    id: TipName.walkthroughPresentationMode,
    title: "Present your work",
    text: "Showcase your work in a slideshow, navigate through frames you created.",
    image: getPathPrefix("/images/walkthrough/presentation.png"),
  },
  [TipName.walkthroughExportCanvas]: {
    id: TipName.walkthroughExportCanvas,
    title: "Export your canvas",
    text: "Choose a file format and export your entire canvas as an image.",
    image: getPathPrefix("/images/walkthrough/export.png"),
  },
};

export type WalkthroughTipName = keyof typeof walkthroughTips;

export const walkthroughTipNames = Object.keys(walkthroughTips) as WalkthroughTipName[];
export const walkthroughTipsOrder: { [tip in WalkthroughTipName]: number } = walkthroughTipNames.reduce(
  (acc, tip, index) => {
    acc[tip] = index;
    return acc;
  },
  {} as { [tip in WalkthroughTipName]: number }
);
