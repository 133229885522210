import { BoardContext, getElementProvider } from "elements/index";
import { useCanvasElementById } from "subscriptions";
import { useEffect, useMemo, useRef } from "react";
import { TypeCanvasElement } from "shared/consts";
import { CanvasElement } from "shared/datamodel/schemas";
import { ElementController } from "elements/base/controller";
import { ElementProvider } from "elements/base/provider";

export default function useCanvasElement(
  id: string,
  type: TypeCanvasElement,
  context: BoardContext,
  elementData?: any,
  allElementsData?: [string, any][]
): {
  controller: ElementController<CanvasElement> | null;
  element: CanvasElement | null;
  provider: ElementProvider<CanvasElement> | null;
} {
  const element: CanvasElement | null = elementData || useCanvasElementById(context.reflect, id);
  const controllerRef = useRef<ElementController<CanvasElement> | null>(null);

  const { controller, provider } = useMemo(() => {
    if (!element) {
      return { controller: null, provider: null };
    }
    const provider = getElementProvider(type);
    if (!provider) {
      throw new Error("Element provider not found");
    }
    const controller = provider.createController(id, element, context, allElementsData);
    controllerRef.current?.destroy();
    controllerRef.current = controller;
    return { controller, provider };
  }, [id, !!element]); // we don't want to recreate the controller/provider if the element is the same

  useEffect(() => {
    // TODO: because we're using the "proxy" controller
    // this method will trigger a render, so we need to do it better
    // to avoid unnecessary renders
    controller?.updateElement(element);
  }, [element]);

  useEffect(() => {
    controller?.updateContext(context);
  }, [context]);

  return { controller, provider, element };
}
