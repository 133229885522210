import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let ShapeRect = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M2 2H18V18H2V2ZM3.54839 3.54839V16.4516H16.4516V3.54839H3.54839Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

ShapeRect.displayName = "ShapeRect";

export { ShapeRect };
