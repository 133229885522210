import { IObservableController } from "elements/base/observable";
import { useEffect, useState } from "react";

export default function useObservableController<T extends IObservableController>(
  controller?: T | null,
  updateFunc?: () => void
) {
  const [num, update] = useState(0);

  useEffect(() => {
    if (!controller) return;
    const updateFunc = () => update((prev) => prev + 1);
    controller?.subscribe(updateFunc);
    return () => controller?.unsubscribe(updateFunc);
  }, [controller]);

  useEffect(() => {
    if (updateFunc) {
      updateFunc();
    }
  }, [num]);
}
