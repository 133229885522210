import style from "./big-modal-container.module.css";
import Modal from "../modal/modal";
import AppLogo from "../ui-components/logo";

export default function BigModalContainer({
  withPortal = true, children,
}: {
  withPortal?: boolean; children: any;
}) {
  return (<Modal dimBackground withPortal={ withPortal }>
    <div className={ style.container }>
      <div className={ style.logo }>
        <AppLogo/>
      </div>
      <div className={ style.content }>{ children }</div>
    </div>
  </Modal>);
}