import React from "react";
import { Html } from "react-konva-utils";
import { Floater } from "frontend/ui-components/floaters/floater";
import BoundingBox from "frontend/geometry/bounding-box";
import { TrashIcon } from "frontend/icons/trash-icon";
import { trackGanttEvent } from "elements/gantt/utils";
import styles from "./gantt-connector-toolbar.module.css";

type SplitCellToolbarProps = {
  cellBbox: BoundingBox;
  removeConnector: () => void;
};

export const GanttConnectorToolbar = ({ cellBbox, removeConnector }: SplitCellToolbarProps) => {
  return (
    <Html transform={false}>
      <Floater relativeTo={cellBbox} arrowSize={0} margin={10} boundary="no-controls-area" className={styles.wrapper}>
        <div className={styles.toolbarActions}>
          <button
            className={styles.remove}
            onClick={() => {
              removeConnector();
              trackGanttEvent("gantt_connector_removed");
            }}
          >
            <TrashIcon size={20} color="#FEFEFE" />
          </button>
        </div>
      </Floater>
    </Html>
  );
};
