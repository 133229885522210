import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let TextAlignLeft = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
            <path
            fillRule="evenodd"
            d="M12.5762 3H2V5H12.5762V3ZM12.5762 11H2V13H12.5762V11ZM2 7H17.9999V9H2V7ZM17.9999 15H2V17H17.9999V15Z"
            fill={color}
            />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

TextAlignLeft.displayName = "TextAlignLeft";

export { TextAlignLeft };
