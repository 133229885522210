import { createElementId, getUnixTimestampUTC } from "../util/utils";
import { GanttElement, ganttSchema, Point } from "./schemas";
import { TasksColors } from "elements/gantt/constants";

export function placeGanttElement({ x, y }: Point, startDate: number, endDate: number) {
  return {
    id: createElementId(),
    item: ganttSchema.parse({
      type: "gantt",
      x,
      y,
      startDate,
      endDate,
      splits: [
        {
          id: createElementId(),
          type: "custom",
          rows: [
            {
              id: createElementId(),
              title: "Row 1",
              order: 1024,
              color: TasksColors[0],
            },
            {
              id: createElementId(),
              title: "Row 2",
              order: 2048,
              color: TasksColors[1],
            },
            {
              id: createElementId(),
              title: "Row 3",
              order: 3072,
              color: TasksColors[2],
            },
          ],
        },
      ],
      zIndexLastChangeTime: getUnixTimestampUTC(),
    }) as GanttElement,
  };
}
