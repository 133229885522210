import { Rect } from "react-konva";
import consts from "shared/consts";
import { CanvasElement, StickyNote } from "shared/datamodel/schemas";
import { ITraits, Trait } from "../elements-toolbar/elements-toolbar-types";
import { textEnabledTraits } from "./text-block-element";
import { ITransformHooks } from "frontend/hooks/use-transform-hooks";
import { Group, Line } from "react-konva";
import { Portal } from "react-konva-utils";
import { Html } from "react-konva-utils";
import Modal from "frontend/modal/modal";
import EditElementLinkModal from "frontend/modals/edit-element-link-modal";
import EdgeLines from "./sticky-note-element-edge-lines";
import { useEffect, useRef } from "react";

export const StickyNoteMarginX = 20;
export const StickyNoteMarginY = 30;

export default function StickyNoteCanvasElement({
  id,
  element,
  mutation,
  changeElement,
  isSelected,
  isSelectable,
  isEditing,
  isFrameHighlighted,
  isEditingLink,
  renderTextEditor,
  renderLink,
  changeElementLink,
  isInContainer,
}: {
  id: string;
  element: StickyNote;
  mutation: ITransformHooks;
  changeElement: (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;
  isSelected: boolean;
  isSelectable: boolean;
  isEditing: boolean;
  isFrameHighlighted: boolean;
  isEditingLink: boolean;
  renderTextEditor: (element: CanvasElement) => void;
  renderLink: (element: CanvasElement) => any;
  changeElementLink: (element: CanvasElement, newLink?: string) => void;
  isInContainer: boolean;
}) {
  const { x, y, width, height, fill, scaleX, scaleY } = element;
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.getStage().container().style.cursor = isEditing ? "text" : "inherit";
    }
  }, [isEditing]);

  function renderShadow() {
    const buffer = 10;
    const y = height + StickyNoteMarginY - buffer;
    return (
      <Line
        width={width}
        height={y}
        fill={fill}
        strokeEnabled={true}
        shadowColor="black"
        shadowOffsetY={5}
        shadowOffsetX={0}
        shadowBlur={15}
        shadowOpacity={0.6}
        perfectDrawEnabled={false}
        points={[buffer, y, width + StickyNoteMarginX - buffer, y]}
        strokeWidth={20}
        stroke="black"
      />
    );
  }

  function renderBackground() {
    return (
      <Rect
        width={width + StickyNoteMarginX}
        height={height + StickyNoteMarginY}
        fill={fill}
        strokeEnabled={false}
        perfectDrawEnabled={false}
      />
    );
  }

  function renderHighlight() {
    return (
      <Rect
        width={width + StickyNoteMarginX}
        height={height + StickyNoteMarginY}
        perfectDrawEnabled={false}
        stroke="#1973FF"
        strokeWidth={5}
        shadowBlur={10}
        shadowColor="#1973FF"
      />
    );
  }

  return (
    <>
      <Group
        ref={ref}
        id={id}
        name={id}
        key={id}
        type={consts.CANVAS_ELEMENTS.STICKY_NOTE}
        x={x}
        y={y}
        scaleX={scaleX}
        scaleY={scaleY}
        isSelectable={isSelectable}
        {...mutation.getCallbacks()}
        isCanvasElement={true}
        isConnectable={true}
        isConnector={false}
        isDraggable={true}
        isFrame={false}
        element={element}
        isTaskConvertible={!!element.text}
        attachedConnectors={element.attachedConnectors}
        onMouseEnter={(e) => {
          if (isSelected && isEditing) {
            e.target.getStage()!.container().style.cursor = "text";
          }
        }}
        onMouseLeave={(e) => {
          if (isSelected && isEditing) {
            e.target.getStage()!.container().style.cursor = "default";
          }
        }}
        onMouseDown={(e) => {
          if (isSelected && isEditing) {
            e.evt.preventDefault();
          }
        }}
        isInContainer={isInContainer}
      >
        {renderShadow()}
        {renderBackground()}
        {renderTextEditor(element)}
        {renderLink(element)}
        {/* <CanvasElementVoteButtons noderef={ref} /> */}
        {isFrameHighlighted && renderHighlight()}
      </Group>
      {isSelected && !element.lock && (
        <Portal enabled selector=".Overlay">
          <EdgeLines
            element={element}
            sticky={ref.current}
            changeElement={changeElement}
          />
        </Portal>
      )}
      {isEditingLink && (
        <Html>
          <Modal dimBackground={true}>
            <EditElementLinkModal element={element} onChangeLink={changeElementLink} />
          </Modal>
        </Html>
      )}
    </>
  );
}

export function stickyNoteTraits(element: StickyNote): ITraits {
  return {
    ...textEnabledTraits(element),
    // in sticky notes the font size changes with scale
    fontSize: ((element.fontSize as number) ?? consts.DEFAULTS.FONTSIZE) * element.scaleX,
    stickyNoteColor: element.fill,
  };
}

export function stickyNoteValidateTrait(element: StickyNote, trait: Trait, value: any) {
  switch (trait) {
    case Trait.fontSize:
      const maxFontSize = Math.min(value as number, element.width * 0.7);
      return maxFontSize / element.scaleX;
    default:
      return value;
  }
}
