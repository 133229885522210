import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let AlignTop = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M18 3.35211L2.00001 3.35211L2.00001 2L18 2L18 3.35211ZM5.00002 5.9998L8.00002 5.9998L8.00002 17.9998L5.00002 17.9998L5.00002 5.9998ZM15 5.9998L12 5.9998L12 13.9998L15 13.9998L15 5.9998Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

AlignTop.displayName = "AlignTop";

export { AlignTop };
