import { markTipSeen as markTipSeenApi } from "frontend/api";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { Board, TipName } from "shared/datamodel/schemas";
import { userTipsAtom } from "state-atoms";
import { WalkthroughTipName, walkthroughTipsOrder } from "./walkthrough-tip-model";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";

const queuedTipsAtom = atom<WalkthroughTipName[]>([]);

export function useNextWalkthroughTip(board: Board | null) {
  const [userTips, setUserTips] = useAtom(userTipsAtom);
  const [queuedTips, setQueuedTips] = useAtom(queuedTipsAtom);
  const isOrgChartWalkthroughEnabled = useFeatureFlag("org-chart-walkthrough");
  

  const finishedWalkthrough = userTips.has(TipName.canvasWalkthrough);

  //filters out the org chart walkthrough tip unless the user is actually viewing a board created from the org chart template
  function isValidTipForBoard(tip: WalkthroughTipName) {
    return tip !== TipName.walkthroughExploreOrgChart ||
      (board?.createdFromTemplateId === process.env.ORGCHART_TEMPLATE_ID &&
        tip === TipName.walkthroughExploreOrgChart && isOrgChartWalkthroughEnabled);
  }

  // filter out tips that have already been seen and sort by priority
  const unseenWalkthroughTips = queuedTips
    .filter((tip) => userTips && !userTips.has(tip))
    .filter(isValidTipForBoard);
  unseenWalkthroughTips.sort((a, b) => {
    const aIndex = walkthroughTipsOrder[a];
    const bIndex = walkthroughTipsOrder[b];
    return aIndex - bIndex; // sort in descending order (lower index = higher priority)
  });

  // get the first unseen tip
  const nextTip = unseenWalkthroughTips.length > 0 && !finishedWalkthrough ? unseenWalkthroughTips[0] : null;

  function markTipSeen(tipName: TipName) {
    markTipSeenApi(tipName);
    if (unseenWalkthroughTips.length == 1) {
      markTipSeenApi(TipName.canvasWalkthrough);
    }
    setUserTips((tips) => new Set([...tips, tipName]));
  }

  function queueTip(tipName: WalkthroughTipName) {
    if (queuedTips.includes(tipName)) return; // don't queue tips twice
    setQueuedTips((tips) => [...tips, tipName]);
  }

  return { nextTip, markTipSeen, queueTip };
}

export default function useWalkthroughTip(tipName: WalkthroughTipName, board: Board | null) {
  const { nextTip, markTipSeen, queueTip } = useNextWalkthroughTip(board);

  useEffect(() => {
    queueTip(tipName);
  }, []);

  // show the tip if it's the next tip to be shown
  const showTip = nextTip === tipName;

  return { showTip, markTipSeen };
}
