import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let DistributeVertical = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M2 3.15002L17.9998 3.15003V4.50212L2 4.50212V3.15002ZM2 15.5442L17.9998 15.5442V16.8963L2 16.8963V15.5442ZM15.0703 8.33303V11.7133L4.92961 11.7133L4.92961 8.33303L15.0703 8.33303Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

DistributeVertical.displayName = "DistributeVertical";

export { DistributeVertical };
