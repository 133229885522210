import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let Unlock = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M7.97622 3.5303C8.42581 3.05801 9.02747 2.8 9.64655 2.8C10.2656 2.8 10.8673 3.05801 11.3169 3.5303C11.7677 4.00386 12.027 4.65368 12.027 5.33854V6.08854L13.527 6.08854V5.33854C13.527 4.27905 13.1267 3.25597 12.4033 2.49608C11.6788 1.73494 10.688 1.3 9.64655 1.3C8.60514 1.3 7.61432 1.73494 6.88977 2.49608C6.16641 3.25597 5.76611 4.27905 5.76611 5.33854V8.95218H3.2998V18.6748H16.6259V8.95218H7.26611V5.33854C7.26611 4.65368 7.52544 4.00386 7.97622 3.5303ZM4.7998 10.4522V17.1748H15.1259V10.4522H4.7998ZM9.96291 14.9196C10.5392 14.9196 11.0064 14.4524 11.0064 13.8761C11.0064 13.2998 10.5392 12.8326 9.96291 12.8326C9.38661 12.8326 8.91943 13.2998 8.91943 13.8761C8.91943 14.4524 9.38661 14.9196 9.96291 14.9196Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

Unlock.displayName = "Unlock";

export { Unlock };
