import { IRect } from "../utils/math-utils";
import BoundingBox from "./bounding-box";

type number2 = [number, number];

function sub(v1: number2, v2: number2) {
  return [v1[0] - v2[0], v1[1] - v2[1]] as number2;
}
function cross(v1: number2, v2: number2) {
  return v1[0] * v2[1] - v1[1] * v2[0];
}

export function rectIntersectRect(a: IRect, b: IRect) {
  const aRight = a.x + a.width;
  const bRight = b.x + b.width;
  const aBottom = a.y + a.height;
  const bBottom = b.y + b.height;
  return (a.x < bRight && b.x < aRight && a.y < bBottom && b.y < aBottom);
}

export function boxIntersectBox(a: BoundingBox, b: BoundingBox) {
  return (a.minX < b.maxX && b.minX < a.maxX && a.minY < b.maxY && b.minY < a.maxY);
}

export function boxIntersectRect(a: BoundingBox, r: IRect) {
  const xmax = r.x + r.width, ymax = r.y + r.height;
  return (a.minX < xmax && r.x < a.maxX && a.minY < ymax && r.y < a.maxY);
}

// Check if a rectangle intersects a segment
// Fast test that only returns true/false and not the intersection point
// Adaptation of Liang Barsky algorithm for checking intersection of line with rectangle.
// https://en.wikipedia.org/wiki/Liang%E2%80%93Barsky_algorithm
// Nice pseudo code in: https://gist.github.com/ChickenProp/3194723 (it has a bug, this code is correct)
export function rectIntersectSegment(rect: IRect, x0: number, y0: number, x1: number, y1: number) {
  const min = {
    x: Math.min(rect.x, rect.x + rect.width),
    y: Math.min(rect.y, rect.y + rect.height),
  };
  const max = {
    x: Math.max(rect.x, rect.x + rect.width),
    y: Math.max(rect.y, rect.y + rect.height),
  };
  const dx = x1 - x0;
  const dy = y1 - y0;
  var p = [-dx, dx, -dy, dy];
  var q = [x0 - min.x, max.x - x0, y0 - min.y, max.y - y0];
  var u1 = 0;
  var u2 = 1;

  for (let i = 0; i < 4; i += 1) {
    if (p[i] == 0) {
      if (q[i] < 0) return false;
    } else {
      var t = q[i] / p[i];
      if (p[i] < 0 && u1 < t) u1 = t;
      else if (p[i] > 0 && u2 > t) u2 = t;
    }
  }

  if (u1 > u2) return false;
  return true;
}


/**
 * Algorithm taken from "Graphic gems 3"
 * Can be found at https://www.realtimerendering.com/resources/GraphicsGems/gemsiii/insectc.c
 * @returns {boolean}
 */
export function segmentIntersectSegmentFast(x1: number, y1: number, x2: number, y2: number,
  x3: number, y3: number, x4: number, y4: number): boolean {
  let Ax, Bx, Cx, Ay, By, Cy, d, e, f;
  let x1lo, x1hi, y1lo, y1hi;

  Ax = x2 - x1;
  Bx = x3 - x4;

  if (Ax < 0) {
    x1lo = x2; x1hi = x1;
  } else {
    x1hi = x2; x1lo = x1;
  }
  if (Bx > 0) {
    if (x1hi < x4 || x3 < x1lo) return false;
  } else {
    if (x1hi < x3 || x4 < x1lo) return false;
  }

  Ay = y2 - y1;
  By = y3 - y4;

  if (Ay < 0) {
    y1lo = y2; y1hi = y1;
  } else {
    y1hi = y2; y1lo = y1;
  }
  if (By > 0) {
    if (y1hi < y4 || y3 < y1lo) return false;
  } else {
    if (y1hi < y3 || y4 < y1lo) return false;
  }


  Cx = x1 - x3;
  Cy = y1 - y3;

  f = Ay * Bx - Ax * By;

  if (f == 0) return false; // parallel lines

  d = By * Cx - Bx * Cy;
  if (f > 0) {
    if (d < 0 || d > f) return false;
  } else {
    if (d > 0 || d < f) return false;
  }

  e = Ax * Cy - Ay * Cx;
  if (f > 0) {
    if (e < 0 || e > f) return false;
  } else {
    if (e > 0 || e < f) return false;
  }

  return true;
}

// https://stackoverflow.com/questions/563198/how-do-you-detect-where-two-line-segments-intersect
export function segmentIntersectSegment(x1: number, y1: number, x2: number, y2: number,
  x3: number, y3: number, x4: number, y4: number) {
  const p: number2 = [x1, y1], r: number2 = [x2 - x1, y2 - y1];
  const q: number2 = [x3, y3], s: number2 = [x4 - x3, y4 - y3];
  const q_minus_p = sub(q, p);
  const rs = cross(r, s)
  const term1 = cross(q_minus_p, s);
  const term2 = cross(q_minus_p, r)
  if (rs == 0) {
    if (term2 == 0) {
      //collinear - maybe overlapping or disjoint
      //I don't care about overlapping
      return false;
    } else {
      // parallel - no intersection
      return false;
    }
  } else {
    const t = term1 / rs;
    const u = term2 / rs;
    if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
      // intersection
      return { t, u, point: [p[0] + t * r[0], p[1] + t * r[1]] as number2 };
    } else {
      // no intersection
      return false;
    }
  }
}
