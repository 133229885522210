import Konva from "konva";
import type { CanvasElement } from "shared/datamodel/schemas";
import { StandardAnchorPoints } from "frontend/canvas-designer-new/elements/connector/anchor-point-utils";
import { IClosestPointOnOutline, NoneClosestPoint, ResizeType, TransformerConfig } from "elements/base/types";
import type { CanvasElementBox, Degrees } from "utils/transform";

export interface ElementGraphicsProvider<T extends CanvasElement> {
  /**
   * Check if point is inside node
   * @param point
   * @param node
   */
  isPointInNode(point: { x: number; y: number }, node: Konva.Node): boolean;

  /**
   * Check if normalized point is inside element
   * @param point it would be normalized point between 0 and 1
   */
  isNormalizedPointInsideElement(point: { x: number; y: number }): boolean;

  /**
   * Get transform params of element
   * @param element
   */
  getTransformParams(element: T): CanvasElementBox;

  /**
   * Get vertices of element normalized
   * @param element
   */
  getVerticesNormalized(element: T): number[];

  /**
   * Determines the standard anchor points for connectors on the given element.
   * @param element The element to calculate anchor points for.
   * @returns StandardAnchorPoints if applicable, or null if the element does not support anchor points.
   */
  getConnectorAnchorPoints(element: T): StandardAnchorPoints | null;

  /**
   * Retrieves the type of resizing allowed for the element.
   * @returns The ResizeType indicating how the element can be resized.
   */
  getResizeType(): ResizeType;

  /**
   * Provides configuration for the Konva Transformer used to manipulate the element.
   * @returns A Partial<TransformerConfig> with properties to customize the transformer's appearance and behavior.
   */
  getTransformerConfig(): Partial<TransformerConfig>;

  /**
   * Calculates the position for a link badge relative to the element.
   * @param element The element to calculate the link badge position for.
   * @returns An object containing the x and y coordinates for the link badge position.
   */
  getLinkBadgePosition(element: T): { x: number; y: number };

  /**
   * Calculates the closest point on the element's outline to a given point.
   * @param element The element to calculate the closest point for.
   * @param p The point to find the closest outline point to, with x and y coordinates.
   * @returns A function that, when called with a point, returns the closest point on the element's outline as an IClosestPointOnOutline object.
   */
  closestPointOnOutline(element: T): (p: { x: number; y: number }) => IClosestPointOnOutline;

  calculatePointAt(element: T): (t: number) => { x: number; y: number };
}

export class BaseGraphicsProvider<T extends CanvasElement> implements ElementGraphicsProvider<T> {
  isPointInNode(point: { x: number; y: number }, node: Konva.Node): boolean {
    return true;
  }

  isNormalizedPointInsideElement(point: { x: number; y: number }): boolean {
    return point.x >= 0 && point.x <= 1 && point.y >= 0 && point.y <= 1;
  }

  getTransformParams(element: T): CanvasElementBox {
    const { x = 0, y = 0, width = 0, height = 0, rotate = 0 as Degrees } = element as any;
    return { x, y, width, height, rotate };
  }

  getVerticesNormalized(element: T): number[] {
    return [0, 0, 1, 0, 1, 1, 0, 1];
  }

  getConnectorAnchorPoints(element: T): StandardAnchorPoints | null {
    return null;
  }

  getResizeType(): ResizeType {
    return ResizeType.None;
  }

  getTransformerConfig(): Partial<TransformerConfig> {
    return {};
  }

  getLinkBadgePosition(element: T): { x: number; y: number } {
    return { x: 0, y: 0 };
  }

  closestPointOnOutline(element: T): (p: { x: number; y: number }) => IClosestPointOnOutline {
    return () => NoneClosestPoint;
  }

  calculatePointAt(element: T): (t: number) => { x: number; y: number } {
    return () => ({ x: 0, y: 0 });
  }
}
