import React from "react";
import { Shape } from "react-konva";
import { Frame } from "shared/datamodel/schemas/frame";

export default function TitleTextInput({
    id,
    element,
  }: {
    id: string;
    element: Frame;
  }) {
    const { x, y, width, title, scaleX = 1 } = element;
    return (
        <Shape
            x={x}
            y={y}
            sceneFunc={(context: any, shape: any) => {
                const stage = shape.getStage();
                if (!stage) return;
                const scale = stage.scaleX();
                const scaledFontSize = 12 / scale;
                context._context.font = `300 ${scaledFontSize}px Poppins`;
                context.fillStyle = '#797E93'; 
                context.beginPath();
                
                let displayText = '';
                const roundedFontSize = Math.round(scaledFontSize);
                const maxWidth = width * scaleX;
                if (shape.attrs.displayText && shape.attrs.scaledFontSize === roundedFontSize && shape.attrs.maxWidth === maxWidth) {
                    displayText = shape.attrs.displayText;
                } else {
                    // Measure the width of the full text
                    let textWidth = context._context.measureText(title).width;

                    // If text width exceeds maxWidth, truncate and add ellipsis
                    if (textWidth > maxWidth) {
                    
                        let truncatedText = title;
                        let len = truncatedText.length;
                        
                        // Binary search to find the maximum substring that fits
                        let start = 0;
                        let end = len;
                        let fitText = '';
                        const ellipsis = '...';

                        while (start < end) {
                            const mid = Math.ceil((start + end) / 2);
                            const testText = truncatedText.slice(0, mid);
                            const testWidth = context._context.measureText(testText + ellipsis).width;

                            if (testWidth <= maxWidth) {
                                start = mid;
                                fitText = testText;
                            } else {
                                end = mid - 1;
                            }
                        }

                        // Ensure the fitText actually fits
                        if (context._context.measureText(fitText + ellipsis).width > maxWidth) {
                            fitText = fitText.slice(0, -1);
                        }

                        displayText = fitText + ellipsis;
                    } else {
                        // Draw the full text
                        displayText = title;
                    }

                    // set in cache
                    shape.attrs.scaledFontSize = roundedFontSize;
                    shape.attrs.displayText = displayText;
                    shape.attrs.maxWidth = maxWidth;
                }

                // Draw the text
                context.fillText(displayText, 0, -10 / scale);
            }}
            hitFunc={(context, shape) => {
                // Customize the hit area, e.g., making it match the width of the text and height of the font
                const stage = shape.getStage();
                if (!stage) return;
                const scale = stage.scaleX();
                const scaledFontSize = 12 / scale
                const maxWidth = width * scaleX;
                const additionalHeight = 12 / scale; // Add extra height
                const yPosition = -scaledFontSize - additionalHeight; // Move hit area higher
                const height = scaledFontSize + additionalHeight ; // Expand hit area

                
                // Define the rectangular hit area
                context.beginPath();
                context.rect(0, yPosition, maxWidth, height);
                context.closePath();
                context.fillStrokeShape(shape);
              }}
            id={id}
            isCanvasElement={true}
            isFrameTitle={true}
            isFrame={true}
            isConnector={false}
            isConnectable={false}
            isSelectable={true}
            isTaskConvertible={false}
            element={element}
    />
    );
  };