import { useEffect, useRef } from "react";
import { Group, Rect } from "react-konva";

export default function KonvaShimmer({
  width,
  height,
  interval = 1500,
}: {
  width: number;
  height: number;
  interval?: number;
}) {
  useEffect(() => {
    let timer: NodeJS.Timeout;
    function animateToEnd() {
      ref.current.to({
        x: width,
        duration: interval / 1000,
      });
      timer = setTimeout(() => {
        moveToStart();
      }, interval);
    }
    function moveToStart() {
      ref.current.x(-width);
      animateToEnd();
    }

    moveToStart();
    return () => clearTimeout(timer);
  }, []);

  const ref = useRef<any>(null);
  return (
    <Group
      clipFunc={(ctx: any) => {
        ctx.rect(0, 0, width, height);
      }}
    >
      <Rect
        ref={ref}
        fillLinearGradientStartPoint={{ x: 0, y: 0 }}
        fillLinearGradientEndPoint={{ x: width, y: 40 }}
        fillLinearGradientColorStops={[0.2, "white", 0.5, "black", 0.8, "white"]}
        opacity={0.05}
        width={width}
        height={height}
      />
    </Group>
  );
}
