import * as Sentry from "@sentry/nextjs";
import tracking from "frontend/tracking";
import { ErrorBoundary } from "react-error-boundary";
import consts from "shared/consts";

export default function CanvasElementErrorBoundary({
  elementId,
  documentId,
  children,
}: {
  elementId: string;
  documentId: string | null | undefined;
  children: any;
}) {
  function handleError(error: Error, info: { componentStack: string }) {
    console.error("Error rendering canvas element", { elementId, error });
    const type = elementId.includes("-") ? elementId.split("-")[0] : elementId;
    tracking.trackEvent(consts.TRACKING_CATEGORY.CANVAS_ACTION, "error-rendering-canvas-element", type, documentId);
    Sentry.addBreadcrumb({
      message: "Error rendering canvas element",
      category: "error",
      level: "error",
      data: { type, elementId, documentId },
    });
    Sentry.captureException(error, { tags: { type: "canvas-element", elementType: type } });
  }

  return (
    <ErrorBoundary fallback={null} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
}
