import { useBoardTasks } from "frontend/hooks/use-board-integrations";
import style from "./integration-configuration.module.css";
import Checkbox from "frontend/ui-components/checkbox";
import { useCallback, useRef, useState } from "react";
import useOutsideRef from "frontend/utils/click-outside-handler";
import { useAtomValue } from "jotai";
import { transformerRefAtom } from "state-atoms";

export default function IntegrationConfiguration({
  documentId,
  integrationId,
  onDismiss,
}: {
  documentId: string;
  integrationId: string;
  onDismiss: () => void;
}) {
  const { getBoardIntegrationConfig, updateBoardIntegration } = useBoardTasks(documentId);
  const ref = useRef<HTMLDivElement>(null);
  const transformerRef = useAtomValue(transformerRefAtom);

  useOutsideRef(ref, onDismiss);

  const [error, setError] = useState<string | null>(null);

  const { config, columns = [] } = getBoardIntegrationConfig(integrationId) ?? {};

  if (!config) {
    return null;
  }

  const selectedColumnIds = config.columns.map((column: any) => column.id);

  function validateNewColumns(newColumns: any[]) {
    // // should have at least 1 status, 1 person, 1 date
    // const statusColumn = newColumns.find((column: any) => column.type === "status");
    // const personColumn = newColumns.find((column: any) => column.type === "people");
    // const dateColumn = newColumns.find((column: any) => column.type === "date");
    // console.log(newColumns, { statusColumn, personColumn, dateColumn });
    // if (!statusColumn || !personColumn || !dateColumn) {
    //   setError("Must have at least 1 status, 1 person and 1 date columns");
    //   return false;
    // }
    if (newColumns.length < 1) {
      setError("Must have at least 1 column");
      return false;
    }
    setError(null);
    return true;
  }

  function toggleColumnSelection(column: any, selected: boolean) {
    const { id, type } = column;
    const newColumns = [...config.columns];
    if (selected) {
      newColumns.push({ id, type });
    } else {
      const index = newColumns.findIndex((column: any) => column.id === id);
      newColumns.splice(index, 1);
    }
    if (validateNewColumns(newColumns)) {
      updateBoardIntegration(integrationId, { ...config, columns: newColumns });
    }
    setTimeout(() => {
      transformerRef.current?.forceUpdate();
    });
  }

  function renderColumn(column: any) {
    const isSelected = selectedColumnIds.includes(column.id);
    return (
      <div key={column.id} className={style.columnItem} onClick={() => toggleColumnSelection(column, !isSelected)}>
        <Checkbox checked={isSelected} />
        <div>{column.title}</div>
      </div>
    );
  }

  function renderColumns() {
    const onlyRealColumns = columns.filter((column: any) => !["name", "subtasks"].includes(column.type));
    const selected = onlyRealColumns.filter((column: any) => selectedColumnIds.includes(column.id));
    const unselected = onlyRealColumns.filter((column: any) => !selectedColumnIds.includes(column.id));
    return (
      <div className={style.columnsList}>
        {selected.map(renderColumn)}
        <div className={style.separator} />
        {unselected.map(renderColumn)}
      </div>
    );
  }

  return (
    <div ref={ref} className={style.container}>
      {error && <div className={style.error}>{error}</div>}
      {renderColumns()}
    </div>
  );
}
