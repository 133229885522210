import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let Lock = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M8.1799 3.5468C8.65807 3.06863 9.3066 2.8 9.98283 2.8C10.6591 2.8 11.3076 3.06863 11.7858 3.5468C12.2639 4.02496 12.5326 4.6735 12.5326 5.34972V8.63274H7.43311V5.34972C7.43311 4.6735 7.70174 4.02496 8.1799 3.5468ZM5.93311 8.63274V5.34972C5.93311 4.27567 6.35977 3.24561 7.11924 2.48614C7.87871 1.72667 8.90878 1.3 9.98283 1.3C11.0569 1.3 12.0869 1.72667 12.8464 2.48614C13.6059 3.24561 14.0326 4.27567 14.0326 5.34972V8.63274H16.9656V18.8H3V8.63274H5.93311ZM4.5 10.1327V17.3H15.4656V10.1327H4.5ZM9.98272 14.8822C10.5902 14.8822 11.0826 14.3898 11.0826 13.7823C11.0826 13.1748 10.5902 12.6824 9.98272 12.6824C9.37526 12.6824 8.88281 13.1748 8.88281 13.7823C8.88281 14.3898 9.37526 14.8822 9.98272 14.8822Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

Lock.displayName = "Lock";

export { Lock };
