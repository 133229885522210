import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let Ungroup = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M4 2H2V4H2.51602L2.51602 11H2V13H4V12.484H7.51602V16H7V18H9V17.484H16V18H18V16H17.484L17.484 9H18V7H16V7.51599L12.484 7.51599L12.484 4H13V2H11V2.51599L4 2.51599V2ZM11.516 4H11V3.48399L4 3.48399V4H3.48402L3.48402 11H4V11.516L7.51602 11.516V9H7V7H9V7.51599L11.516 7.51599V4ZM11.516 8.48399H9V9H8.48402L8.48402 11.516L8.48402 12.484L8.48402 16H9V16.516L16 16.516V16H16.516V9H16V8.48399L12.484 8.48399H11.516Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

Ungroup.displayName = "Ungroup";

export { Ungroup };
