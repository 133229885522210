import { useRef, useLayoutEffect } from "react";
import { Group, Rect } from "react-konva";

export default function FlexBox({
  direction = "row",
  align = "leading",
  padding = 0,
  paddingX = padding,
  paddingY = padding,
  gap = 0,
  width,
  height,
  background,
  children,
}: {
  direction?: "row" | "column";
  align?: "leading" | "center" | "trailing";
  padding?: number;
  paddingX?: number;
  paddingY?: number;
  gap?: number;
  background?: {
    fill?: string;
    shadowColor?: string;
    shadowBlur?: number;
    shadowOffsetY?: number;
    stroke?: string;
    strokeWidth?: number;
  };
  width?: number;
  height?: number;
  children: any;
}) {
  const ref = useRef<any>(null);
  const backgroundRef = useRef<any>(null);

  function calculatePosition(elementSize: number, boxSize: number, padding: number) {
    switch (align) {
      case "leading":
        return padding;
      case "center":
        return (boxSize - elementSize) / 2;
      case "trailing":
        return boxSize - elementSize - padding;
    }
  }

  useLayoutEffect(() => {
    if (!ref.current || !backgroundRef.current) {
      return;
    }
    const stage = ref.current.getStage();
    const getClientRectOptions = { relativeTo: stage, skipTransform: true };

    const children = ref.current.children;
    let pos = direction === "column" ? paddingY : paddingX;
    let maxSize = 0;
    const attrs = new Array(children.length);
    for (const [i, child] of children.entries()) {
      const { width, height } = child.getClientRect(getClientRectOptions);
      if (direction === "row") {
        attrs[i] = { x: pos };
        maxSize = Math.max(maxSize, height + paddingY * 2);
        pos += width + gap;
      } else {
        attrs[i] = { y: pos };
        maxSize = Math.max(maxSize, width + paddingX * 2);
        pos += height + gap;
      }
    }
    for (const [i, child] of children.entries()) {
      const { width, height } = child.getClientRect(getClientRectOptions);
      if (direction === "row") {
        attrs[i].y = calculatePosition(height, maxSize, paddingY);
      } else {
        attrs[i].x = calculatePosition(width, maxSize, paddingX);
      }
    }
    for (const [i, child] of children.entries()) {
      child.setAttrs(attrs[i]);
    }

    let { width: contentWidth, height: contentHeight } = ref.current.getClientRect(getClientRectOptions);
    contentWidth += paddingX * 2;
    contentHeight += paddingY * 2;

    if (!width || !height) {
      backgroundRef.current.size({ width: width ?? contentWidth, height: height ?? contentHeight });
    }
  }, [children, width, height]);

  return (
    <Group>
      <Rect ref={backgroundRef} width={width} height={height} {...background} />
      <Group ref={ref}>{children}</Group>
    </Group>
  );
}
