import React, { CSSProperties, forwardRef, useRef } from "react";
import style from "./footer.module.css";
import cn from "classnames";
import { useHover } from "frontend/ui-components/floaters/use-hover";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";

export function Footer({ customStyle, children }: {customStyle?: CSSProperties, children: any}) {
  return <div style={customStyle} className={style.footer}>{children}</div>;
}

export function FooterButtonGroup({ children }: React.PropsWithChildren<{}>) {
  return <div className={style.footerButtonGroup}>{children}</div>;
}

type FooterButtonProps = React.PropsWithChildren<{
  tooltip?: string;
  onClick?: any;
  disabled?: boolean;
  styles?: string[];
  testid?: string;
}>;

export const FooterButton = forwardRef<HTMLDivElement, FooterButtonProps>(
  ({ tooltip, onClick, disabled, styles, testid, children }, ref: any) => {
    let className = style.footerButton;
    if (styles) className = cn([...styles, style.footerButton]);
    const internalRef = useRef<HTMLDivElement>(null);
    const hover = useHover(internalRef);
    return (
      <div ref={ref}>
        <div ref={internalRef} className={className} onClick={disabled ? undefined : onClick} data-testid={testid}>
          {children}
        </div>
        {hover && tooltip && (
          <Tooltip label={tooltip} relativeTo={internalRef} boundary="controls-area" />
        )}
      </div>
    );
  }
);
