import type { GanttElement, GanttSplit, Granularity } from "elements/gantt/schema";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
} from "date-fns";
import Constants from "elements/gantt/constants";
import { CornerRadius } from "./utils";
import { TaskCard } from "shared/datamodel/schemas";
import { getTaskFieldValue } from "shared/datamodel/task-card";
import ganttConstant from "elements/gantt/constants";
import { cleanDate } from "shared/util/date-utils";

export interface GanttCellLayout {
  x: number;
  y: number;
  width: number;
  height: number;
  cornerRadius: CornerRadius;
}

export interface GanttDateCell extends GanttCellLayout {
  id: string;
  title: string;
  fill: string;
  isHeader: boolean;
  isFirstSubHeader: boolean;
  isLastSubHeader: boolean;
}

export interface GanttSplitCell extends GanttCellLayout {
  splitIndex: number;
  splitId: string;
  rowId: string;
  title: string;
  parentRowId?: string;
  color: string;
}

export interface GanttGridCell extends GanttCellLayout {
  rowId: string;
  date: Date;
}

export interface GanttTaskCell extends GanttCellLayout {
  elementId: string;
  rowId: string;
  isCutStart: boolean;
  isCutEnd: boolean;
}

export interface GanttConnectorLayout {
  id: string;
  from: { id: string; x: number; y: number };
  to: { id: string; x: number; y: number };
}

type Tasks = { id: string; task: TaskCard }[];

export default class GanttLayout {
  private originalStartDate!: Date;
  private originalEndDate!: Date;
  private startDate!: Date;
  private endDate!: Date;
  private granularity!: Granularity;
  private splits!: GanttSplit[];

  private gridCells: GanttGridCell[] = [];

  private splitCellsMap: Record<string, GanttSplitCell> = {};
  private dateHeaderCells: GanttDateCell[] = [];
  private dateCellsMap: Record<number, GanttDateCell> = {};
  private taskCellsMap: Record<string, GanttTaskCell> = {};
  private connectorsLayout: GanttConnectorLayout[] = [];
  private maxPoint: { x: number; y: number } = { x: 0, y: 0 };

  private connectors: { id: string; from: string; to: string }[] = [];
  private tasksByRows: Record<string, Tasks> = {};

  constructor(element: GanttElement) {
    this.setElement(element);
  }

  setElement(element: GanttElement) {
    this.originalStartDate = new Date(element.startDate);
    this.originalEndDate = new Date(element.endDate);
    this.granularity = element.granularity;
    this.splits = element.splits;
    this.connectors = element.connectors ?? [];

    // needs to happen after granularity is set
    this.startDate = cleanDate(this.#roundStartDateByGranularity(this.originalStartDate));
    this.endDate = cleanDate(this.#roundEndDateByGranularity(this.originalEndDate));

    this.updateLayout();
  }

  setTasks(tasks: Tasks) {
    this.tasksByRows = tasks.reduce((acc, { id, task }) => {
      const relevantRowIds = this.splits.reduce((acc, split) => {
        const value = getTaskFieldValue(task, split.id);
        if (value && typeof value === "string") {
          acc.add(value);
        }
        return acc;
      }, new Set<string>());
      if (relevantRowIds.size === 0) {
        console.log("Task doesn't have a rowId", task);
        return acc;
      }
      for (const rowId of relevantRowIds) {
        if (!acc[rowId]) {
          acc[rowId] = [];
        }
        acc[rowId].push({ id, task });
      }
      return acc;
    }, {} as Record<string, Tasks>);

    this.updateLayout();
  }

  getDateCells() {
    return Object.values(this.dateCellsMap).concat(this.dateHeaderCells);
  }

  getSplitCells() {
    return Object.values(this.splitCellsMap);
  }

  getLayoutCells() {
    return this.gridCells;
  }

  getTaskCells() {
    return Object.values(this.taskCellsMap);
  }

  getConnectors() {
    return this.connectorsLayout;
  }

  getLayoutSize() {
    return { height: this.maxPoint.y, width: this.maxPoint.x, x: 0, y: 0 };
  }

  getGridCellAtPoint(point: { x: number; y: number }) {
    return this.gridCells.find((cell) => {
      return (
        cell.x <= point.x && cell.x + cell.width >= point.x && cell.y <= point.y && cell.y + cell.height >= point.y
      );
    });
  }

  getTaskCellAtPoint(point: { x: number; y: number }) {
    return this.getTaskCells().find((cell) => {
      return (
        cell.x <= point.x && cell.x + cell.width >= point.x && cell.y <= point.y && cell.y + cell.height >= point.y
      );
    });
  }

  #invalidateLayout() {
    this.dateCellsMap = {};
    this.splitCellsMap = {};
    this.taskCellsMap = {};
    this.gridCells = [];
  }

  #getTitleForDateColumn(date: Date, shouldShowYear: boolean): string {
    switch (this.granularity) {
      case "day": {
        const formatString = shouldShowYear ? "yyyy/MM/dd" : "LLL dd";
        return format(date, formatString);
      }
      case "week": {
        const startOfWeek = addDays(date, -date.getDay());
        const endOfWeek = addDays(date, 6 - date.getDay());
        const isSameMonth = startOfWeek.getMonth() === endOfWeek.getMonth();
        const differentYearString = shouldShowYear ? ", yyyy" : "";
        const startDateString = format(startOfWeek, `LLL dd - `);
        const endFormat = isSameMonth ? "dd" : "LLL dd";
        const endDateString = format(endOfWeek, `${endFormat}${differentYearString}`);
        return `${startDateString}${endDateString}`;
      }
      case "month": {
        const differentYearString = shouldShowYear ? "yy " : "";
        return format(date, `${differentYearString}LLL`);
      }
      case "quarter": {
        const differentYearString = shouldShowYear ? "yy " : "";
        return format(date, `${differentYearString}'Q'q`);
      }
    }
  }

  #getTitleForSubheader(date: Date): string {
    switch (this.granularity) {
      case "day":
        return format(date, "EEE");
      case "week":
        return format(date, "EEE dd");
      case "month": {
        // week days
        let startWeek = addDays(date, -date.getDay());
        const endWeek = addDays(date, 6 - date.getDay());
        const isSameMonth = startWeek.getMonth() === endWeek.getMonth();
        const startString = format(startWeek, "dd");
        const endString = format(endWeek, isSameMonth ? "dd" : "MMM dd");
        return `${startString} - ${endString}`;
      }
      case "quarter":
        return format(date, "MMM");
    }
  }

  #calculateSplitCellsLayout(splitIndex: number, parentRowId?: string): number {
    if (splitIndex === this.splits.length) {
      // we got to the last split column
      // now we'll create the layout grid for each row and calculate tasks layout
      if (parentRowId && this.splitCellsMap[parentRowId]) {
        const parentLayout = this.splitCellsMap[parentRowId];
        const height = this.#calculateTaskCellsForRow(parentRowId);
        this.#calculateRowLayoutCells({
          y: parentLayout.y,
          height,
          rowId: parentRowId,
        });
        return height;
      }
      return Constants.RowHeight;
    }

    const split = this.splits[splitIndex];
    const rows = split.rows.filter((r) => r.parentRowId === parentRowId);
    // empty parent will be sent only for the first split column,
    // so we set the layout as the beginning of the table
    const parentRowLayout: GanttSplitCell = this.splitCellsMap[parentRowId ?? ""] ?? {
      x: 0,
      y: Constants.HeaderHeight + Constants.SubHeaderHeight, // date columns header
      width: 0,
      height: Constants.RowHeight,
      title: "",
    };
    let y = parentRowLayout.y;
    const x = parentRowLayout.x + parentRowLayout.width;
    let totalHeight = 0;
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      const row = rows[rowIndex];
      let cell: GanttSplitCell = {
        x,
        y,
        width: split.width ?? Constants.SplitColumnWidth,
        height: Constants.RowHeight,
        rowId: row.id,
        title: row.title,
        splitIndex,
        splitId: split.id,
        parentRowId,
        color: parentRowLayout.color ?? row.color,
        cornerRadius:
          splitIndex > 0
            ? CornerRadius.None
            : rowIndex === 0
            ? CornerRadius.TopLeft
            : rowIndex === rows.length - 1
            ? CornerRadius.BottomLeft
            : CornerRadius.None,
      };
      this.splitCellsMap[row.id] = cell;
      cell.height = this.#calculateSplitCellsLayout(splitIndex + 1, row.id);
      y += cell.height;
      totalHeight += cell.height;
    }
    return totalHeight;
  }

  #getWidthForHeaderInGranularity() {
    const amountOfCells = this.#calculateGanttAmountOfCells();

    if (amountOfCells < ganttConstant.MinimumCellsWidth) {
      return ganttConstant.MaxWidthUnderMinimumCells / amountOfCells;
    }
    switch (this.granularity) {
      case "day":
        return Constants.DateByDayColumnWidth;
      case "week":
        return Constants.DateByWeekColumnWidth;
      case "month":
        return Constants.DateByMonthColumnWidth;
      case "quarter":
        return Constants.DateByMonthColumnWidth;
    }
  }

  #roundStartDateByGranularity(date: Date): Date {
    if (!ganttConstant.UsePaddingForDates) {
      return startOfDay(date);
    }
    switch (this.granularity) {
      case "day":
        return startOfDay(date);
      case "week":
        return startOfWeek(date);
      case "month":
        return startOfMonth(date);
      case "quarter":
        return startOfQuarter(date);
    }
  }

  #roundEndDateByGranularity(date: Date): Date {
    if (!ganttConstant.UsePaddingForDates) {
      return startOfDay(date);
    }
    switch (this.granularity) {
      case "day":
        return endOfDay(date);
      case "week":
        return endOfWeek(date);
      case "month":
        return endOfMonth(date);
      case "quarter":
        return endOfQuarter(date);
    }
  }

  #roundDateByGranularity(date: Date): Date {
    switch (this.granularity) {
      case "day":
        return startOfDay(date);
      case "week":
        return startOfDay(date);
      case "month":
        return startOfWeek(date);
      case "quarter":
        return startOfMonth(date);
    }
  }

  #nextDateColumn(date: Date): Date {
    switch (this.granularity) {
      case "day":
        return addDays(date, 1);
      case "week":
        return addDays(date, 1);
      case "month":
        return addWeeks(date, 1);
      case "quarter":
        return addMonths(date, 1);
    }
  }

  #calculateGanttAmountOfCells() {
    let cells = 0;
    let date = this.startDate;
    while (date <= this.endDate) {
      cells++;
      date = this.#nextDateColumn(date);
    }
    return cells;
  }

  #calculateDateHeaderCellsLayout(startX: number) {
    const isSameYear = this.startDate.getFullYear() === this.endDate.getFullYear();
    const dateHeaderCells: GanttDateCell[] = [];
    const dateCellsMap: Record<number, GanttDateCell> = {};
    const columnWidth = this.#getWidthForHeaderInGranularity();

    let x = startX;
    let columnX = startX;
    let date = this.#roundDateByGranularity(this.startDate);
    let headerTitle = this.#getTitleForDateColumn(date, !isSameYear);
    let headerIndex = 0;
    let columnIndex = 0;
    let isFirstSubHeader = true;
    let lastDate = date;

    while (date <= this.endDate) {
      // create subheader cells
      const fill = Constants.LayoutDateSubheaderFill;
      const title = this.#getTitleForSubheader(date);
      dateCellsMap[cleanDate(date).getTime()] = {
        id: `cell-${date}`,
        x: columnX,
        y: Constants.HeaderHeight,
        width: columnWidth,
        height: Constants.SubHeaderHeight,
        title,
        fill,
        isHeader: false,
        cornerRadius: CornerRadius.None,
        isFirstSubHeader: isFirstSubHeader,
        isLastSubHeader: false,
      };
      isFirstSubHeader = false;
      columnX += columnWidth;
      lastDate = date;
      date = this.#nextDateColumn(date);
      const nextHeaderTitle = this.#getTitleForDateColumn(date, !isSameYear);
      if (headerTitle !== nextHeaderTitle || date > this.endDate) {
        // the next date belongs to the next header -> close the current header
        dateHeaderCells.push({
          id: `header-${date}`,
          x,
          y: 0,
          width: columnX - x,
          height: Constants.HeaderHeight,
          title: headerTitle,
          fill: Constants.LayoutDateHeaderFill,
          cornerRadius:
            dateHeaderCells.length === 0
              ? CornerRadius.TopLeft
              : date >= this.endDate
              ? CornerRadius.TopRight
              : CornerRadius.None,
          isHeader: true,
          isFirstSubHeader: false,
          isLastSubHeader: false,
        });
        headerTitle = nextHeaderTitle;
        x = columnX;
        headerIndex++;
        columnIndex = 0;
      } else {
        columnIndex += 1;
      }
    }
    this.dateHeaderCells = dateHeaderCells;

    if (dateCellsMap[lastDate.getTime()]) {
      dateCellsMap[lastDate.getTime()].isLastSubHeader = true;
    }

    this.dateCellsMap = dateCellsMap;
  }

  #calculateRowLayoutCells({ y, height, rowId }: { y: number; height: number; rowId: string }) {
    for (const [dateString, cell] of Object.entries(this.dateCellsMap)) {
      const date = new Date(parseInt(dateString));
      this.gridCells.push({
        x: cell.x,
        y: y,
        width: cell.width,
        height: height,
        rowId,
        date: date,
        cornerRadius: CornerRadius.None,
      });
    }

    if (this.gridCells.at(-1)?.rowId === rowId) {
      this.gridCells.at(-1)!.cornerRadius = CornerRadius.BottomRight;
    }
  }

  /**
   * Calculate the layout of the tasks for a specific row
   * @param rowId
   * @private
   * @returns the height of the row
   */
  #calculateTaskCellsForRow(rowId: string) {
    if (!this.tasksByRows[rowId]) {
      return Constants.RowHeight;
    }

    const sortedTasks = this.tasksByRows[rowId].sort((a, b) => {
      const { fromDate: aFromDate = a.task.dueDate } = a.task;
      const { fromDate: bFromDate = b.task.dueDate } = b.task;
      return aFromDate - bFromDate;
    });

    if (sortedTasks.length === 0) {
      return Constants.RowHeight;
    }

    // each line will be a set of x values that are occupied by a task
    // we'll use this to calculate the y value of the task to make sure it doesn't overlap with other tasks
    const rowLines: Set<number>[] = [new Set()];
    let maxY = this.splitCellsMap[rowId].y + Constants.RowHeight;
    for (const { id, task } of sortedTasks) {
      const { fromDate = task.dueDate, toDate = task.dueDate } = task;
      // if the task doesn't have a due date we don't need to calculate the layout
      if (fromDate === 0 || toDate === 0) {
        continue;
      }

      const granularityFromDate = this.#roundDateByGranularity(new Date(fromDate));
      const granularityToDate = this.#roundDateByGranularity(new Date(toDate));
      let taskStartDateCell = this.dateCellsMap[granularityFromDate.getTime()];
      let taskEndDateCell = this.dateCellsMap[granularityToDate.getTime()];
      let cutStart = false;
      let cutEnd = false;
      // if the task is outside the range of the gantt chart we don't need to calculate the layout
      if (!taskStartDateCell && !taskEndDateCell) {
        console.log("Task is outside the range of the gantt chart", task);
        continue;
      }
      if (!taskStartDateCell) {
        // if the task starts before the gantt chart we'll set the start date to the first date of the gantt chart
        const startDate = this.#roundDateByGranularity(this.startDate);
        cutStart = true;
        taskStartDateCell = this.dateCellsMap[startDate.getTime()];
      }
      if (!taskEndDateCell) {
        // if the task ends after the gantt chart we'll set the end date to the last date of the gantt chart
        const endDate = this.#roundDateByGranularity(this.endDate);
        cutEnd = true;
        taskEndDateCell = this.dateCellsMap[endDate.getTime()];
      }

      let availableRow = 0;
      outerLoop: while (true) {
        if (!rowLines[availableRow]) {
          rowLines[availableRow] = new Set();
          break;
        }
        let x = taskStartDateCell.x;
        while (x <= taskEndDateCell.x) {
          if (rowLines[availableRow].has(x)) {
            availableRow++;
            continue outerLoop;
          }
          x += taskStartDateCell.width;
        }
        break;
      }
      let x = taskStartDateCell.x;
      while (x <= taskEndDateCell.x) {
        if (!rowLines[availableRow]) {
          rowLines[availableRow] = new Set();
        }
        rowLines[availableRow].add(x);
        x += taskStartDateCell.width;
      }

      const y = this.splitCellsMap[rowId].y + availableRow * Constants.RowHeight;
      maxY = Math.max(maxY, y + Constants.RowHeight);
      this.taskCellsMap[id] = {
        x: taskStartDateCell.x,
        y,
        width: taskEndDateCell.x + taskEndDateCell.width - taskStartDateCell.x,
        height: Constants.RowHeight,
        elementId: id,
        rowId,
        isCutStart: cutStart,
        isCutEnd: cutEnd,
        cornerRadius: CornerRadius.None,
      };
    }
    return maxY - this.splitCellsMap[rowId].y;
  }

  #calculateConnectors() {
    this.connectorsLayout = [];
    for (const connector of this.connectors) {
      const fromCell = this.taskCellsMap[connector.from];
      const toCell = this.taskCellsMap[connector.to];
      if (!fromCell || !toCell) {
        continue;
      }
      const fromX = fromCell.x + fromCell.width - 10;
      const fromY = fromCell.y + fromCell.height / 2;
      const toX = toCell.x + 10;
      const toY = toCell.y + toCell.height / 2;
      this.connectorsLayout.push({
        id: connector.id,
        from: { id: connector.from, x: fromX, y: fromY },
        to: { id: connector.to, x: toX, y: toY },
      });
    }
  }

  private updateLayout() {
    this.#invalidateLayout();
    const headersStartX = this.splits.reduce((acc, split) => acc + (split.width ?? Constants.SplitColumnWidth), 0);
    this.#calculateDateHeaderCellsLayout(headersStartX);
    this.#calculateSplitCellsLayout(0);
    this.#calculateConnectors();
    this.maxPoint = this.gridCells.reduce(
      (acc, cell) => ({
        x: Math.max(acc.x, cell.x + cell.width),
        y: Math.max(acc.y, cell.y + cell.height),
      }),
      { x: 0, y: 0 }
    );
  }

  getStartDate() {
    return this.startDate;
  }

  getEndDate() {
    return this.endDate;
  }
}
