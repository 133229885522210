import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { PlanFeature } from "shared/datamodel/schemas";
import { useAtomValue } from "jotai";
import { userAtom } from "state-atoms";

export default function useFeatureValue(feature: string) {
  const [value, setValue] = useState<any>(null);

  const user = useAtomValue(userAtom);

  useEffect(() => {
    if (!user) {
      return;
    }

    const features = user?.planInfo?.enc_features;
    if (!features) {
      return;
    }
    const decFeaturesStr = decryptData(features, user.id + user.account?.id);
    if (!decFeaturesStr) {
      return;
    }
    const decFeatures = JSON.parse(decFeaturesStr) as PlanFeature[];

    const featureValue = decFeatures.find((f: any) => f.name === feature)?.feature_value;

    setValue(featureValue);
  }, [user]);

  function decryptData(data: string, passphrase: string) {
    try {
      const k = CryptoJS.MD5(passphrase);
      let ciphertext = CryptoJS.format.Hex.parse(data);
      var decrypted = CryptoJS.AES.decrypt(ciphertext, k, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.ZeroPadding,
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch {
      return "";
    }
  }

  return value;
}
