import React from "react";

export const GanttAddSubGroupIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" fill="none" viewBox="0 0 14 20">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M10.301 8.8V5.102H8.7V8.8H5v1.603h3.699v3.699H10.3v-3.7H14V8.8z"
        clipRule="evenodd"
      ></path>
      <path stroke="#fff" strokeWidth="1.5" d="M.75 0v20"></path>
    </svg>
  );
};
