import consts, { TypeCanvasElement } from "shared/consts";
import GanttElementProvider from "elements/gantt/provider";
import { elementGraphicsProvidersRegistry, elementProvidersRegistry } from "elements/registry";
import GanttGraphicsProvider from "elements/gantt/graphics";
import type { Reflect } from "@workcanvas/reflect/client";
import type { M } from "shared/datamodel/mutators";
import UndoRedoStack from "utils/undo-redo/undo-redo-stack";
import { CheckoutSources } from "frontend/billing/checkout";
import { MovingElements } from "frontend/canvas-designer-new/canvast-stage.types";

export interface BoardContext {
  boardId: number;
  isReadOnly: boolean;
  reflect: Reflect<M>;
  selectedElementIds: string[];
  setSelectedIds: (ids: string[], includeGroupsOfElements?: boolean) => void;
  setMovingElements: React.Dispatch<React.SetStateAction<MovingElements | null>>;
  hiddenElementIds: string[];
  editingElementId: string | null;
  undoRedoStack: UndoRedoStack;
  showUpgradeModal?: (reason: CheckoutSources) => void;
}

export function getElementProvider(type: TypeCanvasElement) {
  return elementProvidersRegistry.getProvider(type);
}

export function getElementGraphicsProvider(type: TypeCanvasElement) {
  return elementGraphicsProvidersRegistry.getProvider(type);
}

// Providers
elementProvidersRegistry.registerProvider(consts.CANVAS_ELEMENTS.GANTT, new GanttElementProvider());

// Graphics providers
elementGraphicsProvidersRegistry.registerProvider(consts.CANVAS_ELEMENTS.GANTT, new GanttGraphicsProvider());
