import { ToolbarProps } from "elements/base/provider";
import { GanttElement, Granularity } from "shared/datamodel/schemas";
import { DropdownButton } from "frontend/canvas-designer-new/elements-toolbar/toolbar-buttons";
import { produceWithPatches } from "immer";
import { ButtonGroup } from "react-bootstrap";
import classNames from "classnames";
import { DatePickerRangeInput } from "frontend/ui-components/date-picker-range/date-picker-range-input";
import style from "./gantt-toolbar.module.css";
import { formatRelatedDates } from "shared/util/date-utils";
import { trackGanttEvent } from "elements/gantt/utils";
import { useState } from "react";

export default function GanttToolbar({ ids, elements, context }: ToolbarProps<GanttElement>) {
  const { reflect, undoRedoStack } = context;
  const [startDate, setStartDate] = useState<Date | undefined>(new Date(elements[0].startDate));
  const [endDate, setEndDate] = useState<Date | undefined>(new Date(elements[0].endDate));

  function changeDate(start: Date | undefined, end: Date | undefined) {
    const numericStart = start?.getTime();
    const numericEnd = end?.getTime();
    const changes = ids.map((id, index) => {
      const element = elements[index];
      const [_, patch, inverse] = produceWithPatches(element, (draft) => {
        if (numericStart && numericEnd) {
          if (numericStart < numericEnd) {
            draft.startDate = numericStart;
            draft.endDate = numericEnd;
          }
        }

        if (numericStart && !numericEnd) {
          draft.startDate = numericStart;
          draft.endDate = numericStart;
        }

        if (numericEnd && !numericStart) {
          draft.startDate = numericEnd;
          draft.endDate = numericEnd;
        }
      });
      return { id, patch, inverse };
    });
    const doPatches = changes.filter(({ patch }) => patch.length > 0).map(({ id, patch }) => ({ id, patch }));
    if (!doPatches.length) {
      return;
    }
    const undoPatches = changes.map(({ id, inverse }) => ({ id, patch: inverse }));
    undoRedoStack.addAction({
      do: () => reflect.mutate.patchCanvasEl({ changes: doPatches }),
      undo: () => reflect.mutate.patchCanvasEl({ changes: undoPatches }),
    });
  }

  const renderPicker = () => () => {
    const startDate = new Date(elements[0].startDate);
    const endDate = new Date(elements[0].endDate);
    return (
      <div className={style.pickerContainer}>
        <DatePickerRangeInput
          fromDate={startDate}
          toDate={endDate}
          onSelected={(start, end) => {
            setStartDate(start);
            setEndDate(end);
            changeDate(start, end);
            trackGanttEvent("gantt_dates_changed");
          }}
          theme="dark"
          showActions="none"
          showInputs={false}
        />
      </div>
    );
  };

  function renderDateRange() {
    return <div className={style.title}>{formatRelatedDates(startDate, endDate)}</div>;
  }

  function renderGranularityTitle() {
    const text = granularityTitle(elements[0].granularity);
    return (
      <div className={style.granularityTitle}>
        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 2H0V20H18V2ZM1.25581 7V3.25581H16.7442V7H1.25581ZM1.25581 9V18.7442H16.7442V9H1.25581Z"
            fill="white"
          />
        </svg>
        {text}
      </div>
    );
  }

  function changeGranularity(granularity: Granularity) {
    const changes = ids.map((id, index) => {
      const element = elements[index];
      const [_, patch, inverse] = produceWithPatches(element, (draft) => {
        draft.granularity = granularity;
      });
      return { id, patch, inverse };
    });
    const doPatches = changes.filter(({ patch }) => patch.length > 0).map(({ id, patch }) => ({ id, patch }));
    if (!doPatches.length) {
      return;
    }
    trackGanttEvent("gantt_granularity_changed");

    const undoPatches = changes.map(({ id, inverse }) => ({ id, patch: inverse }));
    undoRedoStack.addAction({
      do: () => reflect.mutate.patchCanvasEl({ changes: doPatches }),
      undo: () => reflect.mutate.patchCanvasEl({ changes: undoPatches }),
    });
  }

  function granularityTitle(granularity: Granularity) {
    switch (granularity) {
      case "day":
        return "Days";
      case "week":
        return "Weeks";
      case "month":
        return "Months";
      case "quarter":
        return "Quarter";
    }
  }

  function renderGranularityPicker() {
    const granularities: Granularity[] = ["day", "week", "month", "quarter"];
    const selectedGranularity = elements[0].granularity;
    return (
      <div className={style.granularityPickerContainer}>
        {granularities.map((granularity) => (
          <div
            key={granularity}
            className={classNames(style.granularityPickerRow, {
              [style.selected]: granularity === selectedGranularity,
            })}
            onClick={() => changeGranularity(granularity)}
          >
            <div className={style.granularityPickerCheckmark}>
              <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 5.5L4.66667 10L12 1"
                  stroke="white"
                  strokeWidth="1.64033"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={style.granularityPickerRowTitle}>{granularityTitle(granularity)}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      <ButtonGroup>
        <div className={style.container}>
          <DropdownButton renderPopup={renderGranularityPicker}>{renderGranularityTitle()}</DropdownButton>
        </div>
      </ButtonGroup>
      <ButtonGroup>
        <div className={style.container}>
          <DropdownButton renderPopup={() => renderPicker()}>{renderDateRange()}</DropdownButton>
        </div>
      </ButtonGroup>
    </>
  );
}
