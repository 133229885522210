import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let Italic = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
            <path
            fillRule="evenodd"
            d="M7 2H17V3.45455H13.0346L9.06061 16.5455H13V18H3V16.5455H7.60606L11.5801 3.45455H7V2Z"
            fill={color}
            />

        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

Italic.displayName = "Italic";

export { Italic };
