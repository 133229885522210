import Konva from "konva";
import { Text } from "react-konva";
import { useEffect, useState } from "react";

/**
 * A component that displays loading text with a fading ellipsis effect.
 *
 */
export default function LoadingText(props: Konva.TextConfig) {
  const { text = "" } = props;
  const [loadingText, setLoadingText] = useState(text);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText((prevText) => {
        if (prevText.slice(-3) === "...") {
          return text;
        }
        return prevText + ".";
      });
    }, 200);

    return () => clearInterval(interval);
  }, [loadingText]);

  return <Text fontFamily="Poppins" {...props} text={loadingText} />;
}
