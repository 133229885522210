import Konva from "konva";
import React, { useState } from "react";
import { Group } from "react-konva";

type AllowedGroupProps = Omit<Konva.ContainerConfig, "onClick" | "onMouseDown" | "onMouseUp" | "onDragStart">;

export default function ClickDragDetectorGroup(
  props: React.PropsWithChildren<AllowedGroupProps> & { onClick: () => void }
) {
  const [isDragging, setIsDragging] = useState(false);
  const [lastClick, setLastClick] = useState({ x: 0, y: 0 });
  const { onClick, children, ...rest } = props;

  const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const pos = e.target.getStage()?.getPointerPosition();
    if (pos) {
      setLastClick({ x: pos.x, y: pos.y });
    }
  };

  const handleMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const pos = e.target.getStage()?.getPointerPosition();
    if (pos) {
      const threshold = 5; // Adjust this value to change sensitivity (px)

      if (Math.abs(pos.x - lastClick.x) < threshold && Math.abs(pos.y - lastClick.y) < threshold && !isDragging) {
        onClick();
      }
    }

    setIsDragging(false);
  };

  const handleDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {
    setIsDragging(true);
  };

  return (
    <Group onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onDragStart={handleDragStart} {...rest}>
      {children}
    </Group>
  );
}
