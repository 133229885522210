import cn from "classnames";
import { CSSProperties } from "react";
import { forwardRef } from "react";

export enum ButtonStyle {
  filled = "filled",
  outline = "outline",
}
export enum ButtonSize {
  regular = "regular",
  small = "small",
  big = "big",
}

const StyledButton = forwardRef(
  (
    {
      icon = null,
      title,
      style = ButtonStyle.filled,
      size = ButtonSize.regular,
      loading = false,
      loadingTitle = "Loading...",
      color = "#1973FF",
      hoverColor,
      enabled = true,
      onClick,
      customStyle,
      tabIndex = 0,
      iconStyle,
    }: {
      icon?: string | null;
      title: string;
      style?: ButtonStyle;
      size?: ButtonSize;
      loading?: boolean;
      loadingTitle?: string;
      color?: string;
      hoverColor?: string;
      enabled?: boolean;
      onClick?: (e: any) => void;
      customStyle?: CSSProperties;
      tabIndex?: number | null;
      iconStyle?: CSSProperties;
    },
    ref: any
  ) => {
    hoverColor ??= style == ButtonStyle.filled ? "#1350AE" : "hsl(217,100%,91%)";

    function handleKeydown(e: any) {
      if (["Enter", " "].includes(e.key)) {
        clicked(e);
      }
    }

    function clicked(e: any) {
      if (!enabled || !onClick) {
        return;
      }
      onClick(e);
    }

    return (
      <div
        className={cn({
          container: true,
          [style]: true,
          [size]: true,
          disabled: !enabled,
        })}
        onClick={clicked}
        onKeyDown={handleKeydown}
        style={customStyle}
        tabIndex={tabIndex as number}
        ref={ref}
        role="button"
      >
        <style jsx>{`
          .container {
            position: relative;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            font-family: Sora;
            font-weight: 600;
            justify-content: center;
          }

          .container.disabled {
            cursor: auto;
            opacity: 1;
          }

          .container img {
            width: 14px;
            z-index: 100;
          }

          .container span {
            z-index: 100;
          }

          .container:focus-visible {
            box-shadow: 0 0 0 4px rgb(0 132 255 / 50%), 0 0 0 1px #1973ff inset;
          }

          .${ButtonSize.regular} {
            padding: 8px 12px;
            font-size: 14px;
            gap: 8px;
          }

          .${ButtonSize.small} {
            padding: 4px 12px;
            font-size: 12px;
            gap: 4px;
          }

          .${ButtonSize.big} {
            padding: 6px 16px;
            font-size: 18px;
            gap: 4px;
          }

          .${ButtonStyle.filled} {
            background-color: ${color};
            color: white;
          }

          .${ButtonStyle.filled}:hover {
            background-color: ${hoverColor};
          }

          .${ButtonStyle.filled}.disabled {
            background-color: rgba(25, 115, 255, 0.30);
            cursor: default;
            pointer-events: none;
          }

          .${ButtonStyle.outline} {
            border: 2px solid ${color};
            color: ${color};
          }

          .${ButtonStyle.outline}:hover {
            background-color: ${hoverColor};
            color: ${color};
          }

          .${ButtonStyle.outline}.disabled {
            border-color: #cecdd6;
            color: #cecdd6;
            cursor: default;
            pointer-events: none;
          }

          .shimmer {
            position: absolute;
            z-index: 99;
            inset: 0;
            animation-duration: 2.2s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: shimmer;
            animation-timing-function: linear;
            background: linear-gradient(45deg, rgba(0, 0, 0, 0) 8%, rgba(240, 240, 240, 0.5) 18%, rgba(0, 0, 0, 0) 33%);
            background-size: 1200px 100%;
          }

          @-webkit-keyframes shimmer {
            0% {
              background-position: -100% 0;
            }
            100% {
              background-position: 100% 0;
            }
          }

          @keyframes shimmer {
            0% {
              background-position: -1200px 0;
            }
            100% {
              background-position: 1200px 0;
            }
          }
        `}</style>
        {loading && <div className="shimmer" />}
        {icon && <img src={icon} style={iconStyle}/>}
        <span>{loading ? loadingTitle : title}</span>
      </div>
    );
  }
);

export default StyledButton;
