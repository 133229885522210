import classNames from "classnames";
import style from "./form-field-container.module.css";

export type FieldProps<T> = {
  id: string;
  title?: string;
  onChange: (value: T) => void;
  onFocus?: (id: string) => void;
  onBlur?: (id: string) => void;
  autoFocus?: boolean;
  error?: string;
  hint?: string;
  tabIndex?: number;
  disabled?: boolean;
  customError?: React.ReactNode;
  height?: number;
};

type FieldContainerProps<T> = FieldProps<T> & {
  children: React.ReactNode;
  isFocused: boolean;
};

export function FormFieldContainer<T>({
  id,
  title,
  children,
  error,
  hint,
  isFocused,
  customError,
  height,
}: FieldContainerProps<T>) {
  function renderErrorOrHint() {
    if (customError) {
      return customError;
    }
    if (error) {
      return <span className={style.error}>{error}</span>;
    }
    if (hint) {
      return <span className={style.hint}>{hint}</span>;
    }
    return null;
  }
  return (
    <div className={style.fieldContainer}>
      {title && (
        <label htmlFor={id} className={style.fieldLabel}>
          {title}
        </label>
      )}
      <div
        className={classNames(style.fieldInputContainer, {
          [style.isFocused]: isFocused,
          [style.isError]: (!!error || customError) && !isFocused,
        })}
        style={{ height }}
      >
        {children}
      </div>
      <div className={classNames({ [style.message]: error || customError || hint })}>{renderErrorOrHint()}</div>
    </div>
  );
}
