import React, { useState } from "react";
import { Group, Shape, Text, Rect } from "react-konva";

let pencilIcon: Path2D | undefined;
function createIcon() {
  return new Path2D(
    "M1 10H3.3L9.5 3.8C9.8 3.5 10 3.1 10 2.6C10 2.2 9.8 1.8 9.5 1.5C9.2 1.2 8.8 1 8.3 1C7.9 1 7.4 1.2 7.1 1.5L1 7.6V10Z M6.6 2L8.9 4.4"
  );
}

export function OrgchartOpenSidePanelButton({
  cx,
  cy,
  color,
  hoverColor = "lightgreen",
  highlight,
  onClick,
}: {
  cx: number;
  cy: number;
  color: string;
  hoverColor: string;
  highlight: boolean;
  onClick: () => void;
}) {
  const [hover, setHover] = useState(false);
  pencilIcon ??= createIcon();

  return (
    <>
      <Shape
        x={cx}
        y={cy}
        strokeWidth={1}
        fill={hover || highlight ? hoverColor : "transparent"}
        innerStrokeColor={color}
        lineCap="round"
        lineJoin="round"
        animation={0}
        sceneFunc={(context, shapeConfig) => {
          context.save();
          context._context.lineCap = "round";
          context._context.lineJoin = "round";

          // draw the outer area with Konva's function so we draw on the hit canvas as well
          context.beginPath();
          context.rect(-2, -2, 15, 15);
          context.fillStrokeShape(shapeConfig);

          // draw the icon our cached path2D objects.
          context.beginPath();
          context._context.strokeStyle = color;
          context._context.stroke(pencilIcon!);

          context.restore();
        }}
        onClick={onClick}
        onMouseLeave={(e) => {
          setHover(false);
          e.currentTarget.getStage()!.container().style.cursor = "default";
        }}
        onMouseEnter={(e) => {
          setHover(true);
          e.currentTarget.getStage()!.container().style.cursor = "pointer";
        }}
      />
      {hover && (
        <Group x={cx - 10} y={cy + 10} listening={false}>
          <Rect x={-15} y={10} width={70} height={22} fill="#113255" />
          <Text
            x={-10}
            y={10}
            height={22}
            text="Open editor"
            fill="white"
            fontFamily="Poppins"
            fontSize={10}
            fontStyle="normal"
            align="center"
            verticalAlign="middle"
          />
        </Group>
      )}
    </>
  );
}
