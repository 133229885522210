// @ts-nocheck
import Konva from "konva";
import { KonvaNodeComponent } from "react-konva";

interface HoverableProps extends Konva.RectConfig {
  hoverFill?: string;
  autoSize?: boolean;
  hoverChange?: (hover: boolean) => void;
  cursor?: string;
}

class Hoverable extends Konva.Rect {
  private _fill: string;
  private _hoverFill: string;
  private _autoSize: boolean;
  private _parent: Konva.Group;
  private _cursor?: string;
  private _onHoverChange: ((hover: boolean) => void) | undefined;
  private _mouseIn: boolean;

  constructor(config: HoverableProps) {
    super(config);
    this._hoverFill = config.hoverFill ?? "transparent";
    this._autoSize = config.autoSize ?? (config.width === undefined || config.height === undefined);
    this._fill = this.fill() ?? "transparent";
    this._onHoverChange = config.hoverChange;
    this._cursor = config.cursor;
    this._mouseIn = false;
    this.getSelfRect = () => ({ x: 0, y: 0, width: 0, height: 0 });
  }

  init() {
    this._parent = this.getParent();
    if (this._parent) {
      let enter = this.onMouseEnter.bind(this);
      let leave = this.onMouseLeave.bind(this);
      this._parent.on("mouseenter", enter);
      this._parent.on("mouseleave", leave);
    }
  }

  remove() {
    if (this._mouseIn && this._cursor) {
      // this logic will fail in there are overlapping Hoverables.
      // I'll need to keep track of mouse position and check if it's still in the hoverable
      this.getStage()?.container().style.setProperty("cursor", "default");
      this._mouseIn = false;
      this.getLayer()?.batchDraw();
    }
    super.remove();
  }

  onMouseEnter() {
    this.fill(this._hoverFill);
    this._mouseIn = true;
    this._onHoverChange && this._onHoverChange(true);
    this._cursor && this.getStage()?.container().style.setProperty("cursor", this._cursor);
    this.getLayer()?.batchDraw();
  }

  onMouseLeave() {
    this.fill(this._fill);
    this._mouseIn = false;
    this._onHoverChange && this._onHoverChange(false);
    this._cursor && this.getStage()?.container().style.setProperty("cursor", "default");
    this.getLayer()?.batchDraw();
  }

  _sceneFunc(context: any) {
    super._sceneFunc(context);
    if (!this._parent) this.init();
    if (this._autoSize && this._parent) {
      // queue a resize check
      // never do this in the same frame as the render
      const parentSize = this._parent.getClientRect({ relativeTo: this.getStage(), skipTransform: true });
      if (this.width() !== parentSize.width || this.height() !== parentSize.height) {
        setTimeout(() => {
          const parentSize = this._parent.getClientRect({ relativeTo: this.getStage(), skipTransform: true });
          this.width(parentSize.width);
          this.height(parentSize.height);
          this.getLayer()?.batchDraw();
        });
      }
    }
  }
}


Konva["Hoverable"] = Hoverable;

export default "Hoverable" as KonvaNodeComponent<
  Konva.Rect,
  HoverableProps
>;
