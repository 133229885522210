import { OnResizeCallback, TransformHooks } from "frontend/hooks/use-transform-hooks";
import { SyncService } from "frontend/services/syncService";
import Konva from "konva";
import { CSSProperties, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Group, Line, Rect, RegularPolygon, Text } from "react-konva";
import consts from "shared/consts";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { TimelineElement } from "shared/datamodel/schemas/timeline";
import React from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { highlightedElementIdsPerContainerAtom, stageRefAtom, transformerRefAtom } from "state-atoms";
import { useEvent } from "react-use";
import { EVT_ELEMENT_DRAG, EVT_ELEMENT_DRAG_START, EVT_ELEMENT_DROP } from "../card-stack/card-stack-utils";
import { isPointInRect } from "frontend/utils/math-utils";
import { KonvaEventObject } from "konva/types/Node";
import { format } from "date-fns";
import { FreeSizeTextEditor } from "../../text-element";
import FlexBox from "frontend/ui-components/konva-box";
import { TimelineUtils, TimelineConsts } from "frontend/utils/timeline-utils";
import { CanvasElement as CanvasElementType, Point } from "shared/datamodel/schemas";
import KonvaShimmer from "frontend/ui-components/konva-shimmer";
import LoadingText from "frontend/ui-components/loading-text";

class TransformHooksTimeline extends TransformHooks {
  constructor(id: string, onResize: OnResizeCallback) {
    super(id, onResize);
    this.callbacks.onTransform = this.onTransform1.bind(this);
  }

  onTransform1(e: KonvaEventObject<Event>) {
    const node = e.currentTarget;
    const { x, y } = node.position();
    const { x: scale } = node.scale();
    const finalScale = Math.max(scale, 0.5);
    node.scaleX(finalScale);
    this.onResize(this.id, { x, y }, finalScale, finalScale, 0);
  }

  onTransformEnd(e: KonvaEventObject<Event>): void {
    const node = e.currentTarget;
    const scale = node.attrs.element.scaleX;
    const { x, y } = node.position();
    this.onResize(this.id, { x, y }, scale, scale, 0);
  }
}

const collapsedColumnWidth = (timelineColumnWidth: number) => 1.2 * timelineColumnWidth;
const expandedDropStopWidth = 30;
const labelsFill = "#DFC7FE";

export type BaseTimelineElementProps = {
  id: string;
  syncService?: SyncService<RW>;
  element: TimelineElement;
  allElementsData?: Array<[string,any]>;
  itemsDates: Record<string, number>;
  itemsMap: Record<string, CanvasElementType>;
  timelineColumnWidth: number;
  renderElement: (
    id: string,
    element: any,
    position: { x: number; y: number }
  ) => { node: JSX.Element | null; nextPosition: { x: number; y: number } };
  changeElement: (
    props: any,
    undoConfig: { shouldAdd: boolean; previousProps?: any },
    submenuData?: any,
    elementId?: string
  ) => void;
  onChangeItems: (
    id: string,
    props: Map<string, any>,
    previousProps: Map<string, any>,
    addUndo: boolean,
    updateSubMenuData?: any
  ) => void;
  onResize: OnResizeCallback;
  isSelected: boolean;
  isSelectable: boolean;
  isReadOnly: boolean;
  canDragElementIds: (ids: string[]) => boolean;
  onStartDrag: (elements: Record<string, any>, position: Point) => void;
  onDropElements: (ids: string[], date: number) => void;
  isLoading?: boolean;
};

export default function BaseTimelineCanvasElement({
  id,
  element,
  itemsDates,
  itemsMap,
  renderElement,
  changeElement,
  onResize,
  isSelectable,
  canDragElementIds,
  onStartDrag,
  onDropElements,
  timelineColumnWidth,
  isLoading = false,
}: BaseTimelineElementProps) {
  const { x, y, scaleX: scale = 1, startDate, endDate, granularity } = element;

  const setHighlightedFrameElementIds = useSetAtom(highlightedElementIdsPerContainerAtom(id));

  const transformerRef = useAtomValue(transformerRefAtom);
  const [expandedColumns, setExpandedColumns] = useState<string[]>([]);

  const [dropStops, setDropStops] = useState<Array<{ x: number; date: number; numberOfColumns: number }>>([]);
  const [expandedDropStop, setExpandedDropStop] = useState<number | null>(null);
  const [ghostElement, setGhostElement] = useState<null | { x: number; date: number }>(null);

  const mutation = useMemo(() => new TransformHooksTimeline(id, onResize), [id]);

  const utils = new TimelineUtils(startDate, endDate, granularity);

  const elementsByDate = useMemo(
    () =>
      Object.entries(itemsDates).reduce((acc, [key, value]) => {
        const element = itemsMap[key];
        if (!element) {
          return acc;
        }
        const date = utils.calculateItemDateByGranularity(value);
        acc[date] = acc[date] || [];
        acc[date].push(key);
        return acc;
      }, {} as Record<number, string[]>),
    [itemsDates, granularity, itemsMap]
  );

  const numberOfItemsOutOfRange = Object.entries(elementsByDate).reduce((acc, [date, elements]) => {
    if (Number(date) > endDate) {
      return acc + elements.length;
    }
    return acc;
  }, 0);

  const [timelineWidth, _setTimelineWidth] = useState(timelineColumnWidth);
  const setTimelineWidth = (width: number) => _setTimelineWidth(Math.max(width, timelineColumnWidth));

  const startTitle = element.startTitle || format(startDate, "dd/MM/yyyy");
  const endTitle = element.endTitle || format(endDate, "dd/MM/yyyy");

  const maxElementsToRenderCollapsed = 2;

  useEvent(EVT_ELEMENT_DRAG_START, (ev) => {
    if (!canDragElementIds(ev.detail.ids)) {
      return;
    }
    const dropArea = {
      x,
      y,
      width: timelineWidth * scale,
      height: TimelineConsts.timelineY * scale,
    };
    const isInside = isPointInRect(ev.detail.mousePosition, dropArea);
    if (isInside) {
      const elements = ev.detail.ids.reduce((acc: any, id: string) => {
        if (!itemsMap[id]) return acc;
        const element = Object.assign({}, itemsMap[id]);
        element.x += x;
        element.y += y + TimelineConsts.timelineY;
        acc[id] = element;
        return acc;
      }, {} as Record<string, any>);
      onStartDrag(elements, ev.detail.mousePosition);
    }
  });

  useEvent(EVT_ELEMENT_DRAG, (ev) => {
    if (!canDragElementIds(ev.detail.ids)) {
      return;
    }
    const dropArea = {
      x,
      y,
      width: timelineWidth * scale,
      height: TimelineConsts.timelineY * scale,
    };
    const isMouseAboveMe = isPointInRect(ev.detail.mousePosition, dropArea);
    if (isMouseAboveMe) {
      const xInTimeline = ev.detail.mousePosition.x - x;
      calculateGhostElementDate(xInTimeline);
      setHighlightedFrameElementIds(ev.detail.ids);
    } else {
      resetGhostElement();
      setHighlightedFrameElementIds([]);
    }
  });

  useEvent(EVT_ELEMENT_DROP, (ev) => {
    if (!canDragElementIds(ev.detail.ids)) {
      return;
    }
    const dropArea = {
      x,
      y,
      width: timelineWidth * scale,
      height: TimelineConsts.timelineY * scale,
    };
    const isDroppedHere = isPointInRect(ev.detail, dropArea);
    if (isDroppedHere && ghostElement) {
      const { date } = ghostElement;
      onDropElements(ev.detail.ids, date);
      resetGhostElement();
      setTimeout(() => {
        transformerRef?.current?.nodes([]);
      }, 50);
    }
  });

  const expandedColumnsString = JSON.stringify(expandedColumns);
  useEffect(() => {
    transformerRef?.current?.forceUpdate();
  }, [expandedColumnsString]);

  const calculateGhostElementDate = useCallback(
    (x: number) => {
      x /= scale; // fix x scale to be the same as the timeline
      const stop = dropStops.find((stop) => x < stop.x);
      if (!stop) return;
      setExpandedDropStop(stop.date);
      const columnWidth = Math.max(
        expandedDropStopWidth * stop.numberOfColumns,
        collapsedColumnWidth(timelineColumnWidth)
      );
      const percent = ((stop.x - x) / columnWidth) * 100;
      const date = stop.date - (percent / 100) * utils.getGranularityInMilliseconds() * stop.numberOfColumns;
      setGhostElement({ x, date });
    },
    [dropStops, scale]
  );

  function resetGhostElement() {
    setGhostElement(null);
    setExpandedDropStop(null);
  }

  function renderTimelineLabel(x: number, y: number, text: string, hAlign: "left" | "center" | "right") {
    return (
      <AlignedEditableText
        x={x}
        y={y}
        text={text}
        hAlign={hAlign}
        fill={labelsFill}
        cornerRadius={4}
        fontSize={20}
        padding={15}
        isEditing={false}
      />
    );
  }

  function renderTimeline(width: number) {
    return (
      <>
        <Line
          points={[0, TimelineConsts.timelineY, width, TimelineConsts.timelineY]}
          stroke={"#797E93"}
          strokeWidth={TimelineConsts.timelineStrokeWidth}
          lineCap="round"
        />
        <RegularPolygon sides={3} rotation={90} x={-25} y={TimelineConsts.timelineY} radius={15} fill={labelsFill} />
        {renderTimelineLabel(-40, TimelineConsts.timelineY, startTitle, "right")}
        {renderTimelineLabel(width + 20, TimelineConsts.timelineY, endTitle, "left")}
        {numberOfItemsOutOfRange > 0 && (
          <ExpandEndDateButton
            x={width + 175}
            y={TimelineConsts.timelineY - 25}
            extraElements={numberOfItemsOutOfRange}
            onClick={() => {
              let maxDate = Math.max(...Object.values(itemsDates).map(Number));
              // add 3 days
              maxDate += 3 * 1000 * 60 * 60 * 24;
              changeElement({ endDate: maxDate }, { shouldAdd: true, previousProps: { endDate } }, undefined, id);
            }}
          />
        )}
      </>
    );
  }

  function renderColumnTitle(title: string, x: number, inverted = false) {
    return (
      <>
        <Line
          x={x}
          points={[0, 0, 0, 20]}
          stroke="#797E93"
          strokeWidth={TimelineConsts.timelineStrokeWidth}
          lineCap="round"
        />
        <AlignedEditableText
          x={x}
          y={30}
          text={title}
          hAlign="center"
          vAlign="top"
          cornerRadius={999} // make sure its rounded
          fontSize={20}
          fontColor={inverted ? "white" : "#000"}
          fill={inverted ? "#000" : "#DDE0E2"}
          paddingX={20}
          paddingY={10}
          isEditing={false}
        />
      </>
    );
  }

  function renderShowMore({
    title,
    moreCount,
    startPosition,
    isExpanded,
  }: {
    title: string;
    moreCount: number;
    startPosition: { x: number; y: number };
    isExpanded: boolean;
  }) {
    return (
      <Group
        {...startPosition}
        onClick={() =>
          setExpandedColumns((columns) => (isExpanded ? columns.filter((c) => c !== title) : [...columns, title]))
        }
      >
        <AlignedEditableText
          x={0}
          y={-20}
          text={isExpanded ? "- Collapse" : `+ Show ${moreCount} more`}
          hAlign="left"
          vAlign="bottom"
          cornerRadius={999} // make sure its rounded
          fontSize={20}
          fill="#D9D9D9"
          fontColor="#000"
          padding={20}
          isEditing={false}
        />
      </Group>
    );
  }

  function renderColumn(elementIds: string[], title: string, x: number) {
    const allElements = elementIds.reduce((acc, id) => {
      const element = itemsMap[id];
      if (!element) return acc;
      acc.push({ id, element });
      return acc;
    }, [] as { id: string; element: any }[]);
    if (allElements.length === 0) return null;

    const elementsToRender = expandedColumns.includes(title)
      ? allElements
      : allElements.slice(0, maxElementsToRenderCollapsed);
    const isExpanded = expandedColumns.includes(title);
    const moreCount = allElements.length - elementsToRender.length;
    const showCollapseExpand = allElements.length > maxElementsToRenderCollapsed;

    let startPosition = { x, y: -120 };
    return (
      <>
        {renderColumnTitle(title, x)}
        {elementsToRender.map(({ id, element }) => {
          const { node, nextPosition } = renderElement(id, element, startPosition);
          startPosition = nextPosition;
          return node;
        })}
        <Line
          x={x}
          points={[0, 0, 0, startPosition.y]}
          stroke={"#848199"}
          strokeWidth={TimelineConsts.timelineColumnStrokeWidth}
        />
        {showCollapseExpand && renderShowMore({ title, moreCount, startPosition, isExpanded })}
      </>
    );
  }

  function renderTimelineElements() {
    const elements = [];
    let previousX = 0;
    let previousDate = startDate;
    const elementByDateSorted = Object.entries(elementsByDate);
    elementByDateSorted.sort(([a], [b]) => Number(a) - Number(b));
    elementByDateSorted.push([`${endDate}`, []]);
    const stops: typeof dropStops = [];
    for (const [value, elementIds] of elementByDateSorted) {
      const date = Number(value);
      if (date < startDate || date > endDate) continue;
      // the space between the columns should be diffInDays * timelineColumnWidth
      // if the space is more than 2 days, shrink the space to 2 days
      const diffInColumns = Math.round((date - previousDate) / utils.getGranularityInMilliseconds());
      let gapWidth = diffInColumns * timelineColumnWidth;
      if (diffInColumns >= 2) {
        if (expandedDropStop === date) {
          gapWidth = Math.max(expandedDropStopWidth * diffInColumns, collapsedColumnWidth(timelineColumnWidth));
        } else {
          gapWidth = collapsedColumnWidth(timelineColumnWidth);
        }
        elements.push(
          <CollapsedMarker
            columnWidth={timelineColumnWidth}
            key={`${value}-marker`}
            x={previousX}
            y={TimelineConsts.timelineY + 7}
            numberOfColumns={diffInColumns}
            granularity={granularity}
            width={gapWidth}
          />
        );
      }
      const x = previousX + gapWidth;
      stops.push({ x, date, numberOfColumns: diffInColumns });
      const title = utils.getTitleByGranularity(date);
      elements.push(
        <Group key={value} y={TimelineConsts.timelineY}>
          {renderColumn(elementIds, title, x)}
        </Group>
      );
      previousDate = date;
      previousX = x;
    }
    if (previousX !== timelineWidth) {
      setTimelineWidth(previousX);
      setDropStops(stops);
    }
    return elements;
  }

  function renderGhostElement() {
    if (ghostElement === null) return null;
    const title = utils.getTitleByGranularity(ghostElement.date);
    return (
      <Group x={ghostElement.x} y={TimelineConsts.timelineY}>
        {renderColumnTitle(title, 0, true)}
      </Group>
    );
  }

  function renderLoading() {
    if (!isLoading) {
      return null;
    }
    return (
      <LoadingText
        x={140}
        y={TimelineConsts.timelineY - 80}
        text="Loading timeline items"
        fontSize={24}
        fill={"#848199"}
      />
    );
  }

  return (
    <Group
      x={x}
      y={y}
      id={id}
      name={id}
      key={id}
      type={consts.CANVAS_ELEMENTS.TIMELINE}
      isSelectable={isSelectable}
      {...mutation.getCallbacks()}
      isCanvasElement={true}
      isConnectable={false}
      isConnector={false}
      isDraggable={true}
      isFrame={false}
      element={element}
      attachedConnectors={element.attachedConnectors}
      scaleX={scale}
      scaleY={scale}
      width={timelineWidth}
      height={TimelineConsts.timelineY}
    >
      {renderTimeline(timelineWidth)}
      {renderTimelineElements()}
      {renderLoading()}
      {renderGhostElement()}
    </Group>
  );
}

interface AlignedEditableTextProps extends Konva.RectConfig {
  x: number;
  y: number;
  text: string;
  onChange?: (prevValue: string, text: string) => void;
  isEditing: boolean;
  setIsEditing?: (value: boolean) => void;
  hAlign?: "left" | "center" | "right";
  vAlign?: "top" | "center" | "bottom";
  fontColor?: string;
  fontSize?: number;
  padding?: number;
  paddingX?: number;
  paddingY?: number;
}

function AlignedEditableText(props: AlignedEditableTextProps) {
  const {
    x,
    y,
    text,
    onChange,
    isEditing,
    setIsEditing,
    hAlign = "center",
    vAlign = "center",
    fontSize = 28,
    fontColor = "#000",
    padding = 10,
    paddingX = padding,
    paddingY = padding,
  } = props;

  const [absolutePosition, setAbsolutePosition] = useState({ x, y });
  const [size, setSize] = useState({ width: 0, height: 0 });

  const stageRef = useAtomValue(stageRefAtom);

  const { width, height } = size;

  const borderRef = useRef<any>(null);
  const textRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (!stageRef) {
      setAbsolutePosition({ x: 0, y: 0 });
    } else if (borderRef.current) {
      const rect = borderRef.current.getClientRect({ relativeTo: stageRef.current });
      setAbsolutePosition({ x: rect.x, y: rect.y });
    }
  }, [size.width, size.height]);

  useEffect(() => {
    const textObj = new Konva.Text({ text, fontSize, fontFamily: "Poppins" });
    const width = textObj.width();
    const height = textObj.height();
    setSize({ width, height });
  }, [text]);

  function renderTextEditor() {
    if (!onChange || !setIsEditing) {
      return null;
    }
    const htmlTextProps: CSSProperties = {
      lineHeight: consts.LINE_HEIGHT,
      fontSize,
      textAlign: "center",
      padding: 0,
      width,
      outline: "none",
      color: fontColor,
    };
    const minWidth = 20;
    return (
      <FreeSizeTextEditor
        absX={absolutePosition.x + paddingX}
        absY={absolutePosition.y + paddingY}
        initialValue={text}
        placeholder={consts.DEFAULTS.MINDMAP_PLACEHOLDER}
        minWidth={minWidth}
        updateText={onChange}
        updateTextSize={(width: number, height: number) => setSize({ width, height })}
        textProps={htmlTextProps}
        positionType="top-left"
        fontSize={fontSize}
        onEnter={() => {
          setIsEditing(false);
          return true;
        }}
      />
    );
  }

  function getAlignment(x: number, y: number) {
    switch (hAlign) {
      case "left":
        break;
      case "center":
        x = x - width / 2 - paddingX;
        break;
      case "right":
        x = x - width - paddingX * 2;
        break;
    }
    switch (vAlign) {
      case "top":
        break;
      case "center":
        y = y - height / 2 - paddingY;
        break;
      case "bottom":
        y = y - height - paddingY * 2;
        break;
    }
    return { x, y };
  }

  const { x: absX, y: absY } = getAlignment(x, y);

  return (
    <>
      <Rect {...props} ref={borderRef} x={absX} y={absY} height={height + paddingY * 2} width={width + paddingX * 2} />
      <Text
        ref={textRef}
        x={absX + paddingX}
        y={absY + paddingY + 2}
        text={text}
        fontSize={fontSize}
        fontFamily="Poppins"
        fill={fontColor}
        opacity={isEditing ? 0 : 1}
        onDblClick={() => setIsEditing && setIsEditing(true)}
      />
      {isEditing && onChange && renderTextEditor()}
    </>
  );
}

function CollapsedMarker({
  columnWidth,
  numberOfColumns,
  x,
  y,
  granularity,
  width,
}: {
  columnWidth: number;
  numberOfColumns: number;
  x: number;
  y: number;
  granularity: string;
  width: number;
}) {
  const [isHover, setIsHover] = useState(false);

  const height = 7;
  const padding = 7;

  const maxNumberOfColumns = 15;
  const numberOfLines = Math.min(numberOfColumns, maxNumberOfColumns);

  const gaps = [15, 30, 50];
  const jumps = Math.round(maxNumberOfColumns / gaps.length);
  const gapByNumberOfLines = Array.from({ length: maxNumberOfColumns }, (_, i) => {
    const gapIndex = Math.floor(i / jumps);
    return gaps[gapIndex];
  });

  const columnsGap = gapByNumberOfLines[numberOfLines - 1];

  const totalLineWidth = width - padding * 2;

  // opacity between 0.3 and 1, depending on the number of days, more days more opacity
  const opacity = Math.min(0.3 + (numberOfLines / maxNumberOfColumns) * 0.7, 1);

  // scale down lines to fit
  const scale = totalLineWidth / (numberOfLines * (columnWidth + columnsGap) - columnsGap);
  const lineWidth = columnWidth * scale;
  const scaledGap = columnsGap * scale;

  const renderLines = () => (
    <Group x={x + padding}>
      {Array.from({ length: numberOfLines }).map((_, i) => (
        <Rect
          key={i}
          x={i * (lineWidth + scaledGap)}
          y={y}
          width={lineWidth}
          height={height}
          fill={"#61A9FF"}
          cornerRadius={2}
          opacity={opacity}
        />
      ))}
    </Group>
  );

  const renderTooltip = () => (
    <AlignedEditableText
      x={x + width / 2}
      y={y + 15}
      text={`${numberOfColumns} ${granularity}s`}
      isEditing={false}
      vAlign="top"
      fontSize={18}
      fontColor="white"
      fill="#113357"
      cornerRadius={4}
      paddingX={20}
    />
  );

  return (
    <Group onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <Rect x={x} y={y - 4} width={width} height={height + 8} />
      {renderLines()}
      {isHover && renderTooltip()}
    </Group>
  );
}

function ExpandEndDateButton({
  x,
  y,
  extraElements,
  onClick,
}: {
  x: number;
  y: number;
  extraElements: number;
  onClick: () => void;
}) {
  const [isHover, setIsHover] = useState(false);

  const renderTooltip = () => (
    <AlignedEditableText
      x={x + 25}
      y={y + 60}
      text={`${extraElements} items out of range`}
      isEditing={false}
      vAlign="top"
      fontSize={18}
      fontColor="white"
      fill="#113357"
      cornerRadius={4}
      paddingX={20}
    />
  );

  return (
    <>
      <Group x={x} y={y} onClick={onClick} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
        <Rect width={50} height={50} fill={labelsFill} cornerRadius={4} />
        <FlexBox gap={5} paddingX={12.5} paddingY={22.5}>
          {Array.from({ length: 3 }).map((_, i) => (
            <Rect key={i} width={5} height={5} fill="#113357" cornerRadius={5} />
          ))}
        </FlexBox>
      </Group>
      {isHover && renderTooltip()}
    </>
  );
}
