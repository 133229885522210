import { useRef, useMemo } from "react";

export default function useDoubleClick(callback: () => void, deps: any[] = []) {
  const refCallback = useRef<any>(callback)
  refCallback.current = callback;
  return useMemo(() => {
    let lastClickTime = 0;
    let numClicksSoFar = 0;
    return () => {
      const now = Date.now();
      if (now - lastClickTime > 250) {
        numClicksSoFar = 0;
      }
      lastClickTime = now;
      numClicksSoFar++;
      if (numClicksSoFar === 2) {
        numClicksSoFar = 0;
        refCallback.current();
      }
    }
  }, deps);
}