import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { cellPadding } from "../elements/table/table-utils";
import { handleTabInTextArea } from "../text-element";
import HtmlFrameOnCanvas from "./html-on-canvas";

export default function CellTextEditor({
  contentArea,
  background,
  value,
  placeholder,
  onChange,
  onFinish,
  cssTextArea,
  border,
  padding = cellPadding,
  verticalAlign = "top",
  finishHotKeys = ["Escape"],
}: {
  contentArea: { x: number; y: number; width: number; height: number };
  background: string;
  value?: string;
  placeholder: string;
  onChange: (value: string) => void;
  onFinish: (value: string) => void;
  cssTextArea?: CSSProperties;
  border?: string;
  padding?: number;
  verticalAlign?: "top" | "middle" | "bottom";
  finishHotKeys?: string[];
}) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textHeight, setTextHeight] = useState(0);
  const [needsCalcHeight, triggerNeedsCalcHeight] = useState(0);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0";
      const height = Math.min(textAreaRef.current.scrollHeight, contentArea.height);
      setTextHeight(height);
      textAreaRef.current.style.height = height + "px";
    }
  }, [value, needsCalcHeight]);

  function getVerticalAlignStyle() {
    if (verticalAlign === "middle") {
      return { top: `calc(50% - ${textHeight / 2}px)`, height: textHeight };
    } else if (verticalAlign === "bottom") {
      return { top: `calc(100% - ${textHeight}px)`, height: textHeight };
    }
    return { top: "0", height: "100%" };
  }

  function recalcHeight() {
    triggerNeedsCalcHeight((prev) => prev + 1);
  }

  return (
    <HtmlFrameOnCanvas
      box={contentArea}
      css={{
        cursor: "text",
        padding: padding + "px",
        border,
        background,
        position: "relative",
      }}
    >
      <textarea
        ref={textAreaRef}
        autoFocus
        onFocus={(e) => e.currentTarget.select()}
        style={{
          width: "100%",
          overflow: "auto",
          outline: "none",
          border: "none",
          background: "unset",
          resize: "none",
          position: "absolute",
          ...getVerticalAlignStyle(),
          ...cssTextArea,
        }}
        onBlur={(e) => {
          onFinish(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          handleTabInTextArea(e);
          if (finishHotKeys.includes(e.key)) {
            e.stopPropagation();
            onFinish(e.currentTarget.value);
          } else {
            recalcHeight();
          }
        }}
        onInput={(e) => {
          onChange(e.currentTarget.value);
        }}
        defaultValue={value}
        placeholder={placeholder}
      />
    </HtmlFrameOnCanvas>
  );
}
