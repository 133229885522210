import { Point } from "./schemas/canvas-element";
import consts from "shared/consts";
import { createElementId, getUnixTimestampUTC } from "shared/util/utils";
import { cardStackSchema } from "./schemas/card-stack";

export const PADDING_X = 14;
export const PADDING_Y_TOP = 14;
export const PADDING_Y_BOTTOM = 32;
export const PADDING_Y = PADDING_Y_TOP + PADDING_Y_BOTTOM;
export const GAP_X = 14;
export const GAP_Y = 14;
export const HEADER = 43;
export const CARD_WIDTH = 482;
export const CARD_HEIGHT = 250;//222;

export const DEFAULT_CARD_STACK_WIDTH = PADDING_X * 2 + CARD_WIDTH;
export const DEFAULT_CARD_STACK_HEIGHT = HEADER + PADDING_Y + CARD_HEIGHT * 2 + GAP_Y * 1;
export const DEFAULT_CARD_STACK_TITLE = '';

export function placeCardStack(point: Point,) {
  const id = createElementId();
  return {
    id,
    cardStack: cardStackSchema.parse({
      type: consts.CANVAS_ELEMENTS.CARD_STACK,
      id,
      x: point.x,
      y: point.y,
      width: DEFAULT_CARD_STACK_WIDTH,
      height: DEFAULT_CARD_STACK_HEIGHT,
      title: DEFAULT_CARD_STACK_TITLE,
      zIndexLastChangeTime: getUnixTimestampUTC(),
    }),
  };
}