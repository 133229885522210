import { format } from "date-fns";

export const TimelineConsts = {
  timelineY: 650,
  timelineStrokeWidth: 5,
  timelineColumnStrokeWidth: 3,
  // make the width minimum so that each column size will be at least 100px
  elementsLineConnectorHeight: 60,
};

export class TimelineUtils {
  startDate: number;
  endDate: number;
  granularity: string;

  constructor(startDate: number, endDate: number, granularity: string) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.granularity = granularity;
  }

  calculateItemDateByGranularity(date: number) {
    const granularityInMilliseconds = this.getGranularityInMilliseconds();
    const diff = this.endDate - this.startDate;
    const diffOfDate = date - this.startDate;
    const percent = (diffOfDate / diff) * 100;
    const dateInMilliseconds = (percent * diff) / 100;
    return this.startDate + Math.round(dateInMilliseconds / granularityInMilliseconds) * granularityInMilliseconds;
  }

  getGranularityInMilliseconds() {
    switch (this.granularity) {
      case "day":
        return 1000 * 60 * 60 * 24;
      case "week":
        return 1000 * 60 * 60 * 24 * 7;
      case "month":
        return 1000 * 60 * 60 * 24 * 30;
      default:
        return 1000 * 60 * 60 * 24;
    }
  }

  getWeekNumber(date: Date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const week = Math.ceil(((date.getTime() - firstDayOfYear.getTime()) / 86400000 + firstDayOfYear.getDay() + 1) / 7);
    return week;
  }

  getTitleByGranularity(date: number) {
    const isSameYear = new Date(this.startDate).getFullYear() === new Date(this.endDate).getFullYear();
    switch (this.granularity) {
      case "day":
        return format(date, isSameYear ? "dd/MM" : "dd/MM/yyyy");
      case "week":
        return `Week ${this.getWeekNumber(new Date(date))}`;
      case "month":
        return new Date(date).toLocaleString("default", { month: "long" });
      default:
        return new Date(date).toLocaleDateString();
    }
  }
}
