import Konva from "konva";
import BoundingBox from "../../../geometry/bounding-box";
import consts, { TypeCanvasElement } from "shared/consts";
import type {
  CanvasElement,
  Drawing,
  Frame,
  Shape,
  StickyNote,
  TaskCard,
  IntegrationItem,
  TypeTableElement,
  File as FileSchema,
} from "shared/datamodel/schemas";
import { parseStrokeWidth } from "shared/util/utils";
import Transform, { CanvasElementBox, Degrees, PointAndDirection } from "../../../utils/transform";
import { getTransformParams } from "frontend/utils/node-utils";
import AllShapes from "frontend/data/shapes/shapes-visuals";
import { getElementGraphicsProvider } from "elements/index";

type AnchorPoint = PointAndDirection & {
  onBoundingBox: boolean;
};

export type StandardAnchorPoints = {
  top: AnchorPoint;
  right: AnchorPoint;
  buttom: AnchorPoint;
  left: AnchorPoint;
};

export function getAnchorPoints(type: TypeCanvasElement, element: CanvasElement): StandardAnchorPoints | null {
  const provider = getElementGraphicsProvider(type);
  if (provider) {
    return provider.getConnectorAnchorPoints(element);
  }
  switch (type) {
    case "shape":
      return getShapeAnchorPoints(element as Shape);
    case "textBlock":
      return getTextAnchorPoints(element);
    case "drawing":
      return getDrawingAnchorPoints(element as Drawing);
    case "stickyNote":
      return getStickyNoteAnchorPoints(element as StickyNote);
    case "file":
      return getFileAnchorPoints(element as FileSchema);
    case "taskCard":
      return getTaskCardAnchorPoints(element as TaskCard);
    case "frame":
      return getFrameAnchorPoints(element as Frame);
    case "integrationItem":
      return getIntegrationItemAnchorPoints(element as IntegrationItem);
    case "table":
      return getTableAnchorPoints(element as TypeTableElement);
    // Cases that were originally mapped to null
    case "connector":
    case "comment":
    case "mindmap":
    case "mindmapOrgChart":
    case "cardStack":
    case "orgChartNode":
    case "orgChartRoot":
    case "liveIntegration":
    case "timeline":
    case "tableCell":
      return null;
    default:
      return null;
  }
}

export function getAnchorPointsFromNode(node: Konva.Node) {
  let anchors = getAnchorPoints(node.attrs.type, node.attrs.element)!;
  if (node.attrs.type == consts.CANVAS_ELEMENTS.FILE) {
    // this is a fix to a temporary bug we had that left bad data in some canvases
    const width = anchors.right.x - anchors.left.x;
    const height = anchors.buttom.y - anchors.top.y;
    if (width == 0 || height == 0) {
      anchors = getFileAnchorPointsFromNode(node);
    }
  }
  return anchors;
}

export function transformAnchorPointsInPlace(
  tr: CanvasElementBox | Transform,
  points: StandardAnchorPoints
): StandardAnchorPoints {
  if (!(tr instanceof Transform)) {
    tr = new Transform(tr.x, tr.y, tr.width, tr.height, tr.rotate);
  }
  tr.transformPointAndDirection(points.top, points.top);
  tr.transformPointAndDirection(points.buttom, points.buttom);
  tr.transformPointAndDirection(points.left, points.left);
  tr.transformPointAndDirection(points.right, points.right);
  return points;
}

function mkPoint(x: number, y: number, rotation: number, onOutline: boolean = true): AnchorPoint {
  return { x, y, rotation: rotation as Degrees, onBoundingBox: onOutline };
}

const standardRectAnchorPoints = () => ({
  top: mkPoint(0.5, 0, 270),
  right: mkPoint(1, 0.5, 0),
  buttom: mkPoint(0.5, 1, 90),
  left: mkPoint(0, 0.5, 180),
});

const shapeStandardAnchorPoints = (shapeType: string) => {
  switch (shapeType) {
    case consts.SHAPES.RECT:
    case consts.SHAPES.RECT_ROUNDED:
      return {
        top: mkPoint(0.5, 0, 270),
        right: mkPoint(1, 0.5, 0),
        buttom: mkPoint(0.5, 1, 90),
        left: mkPoint(0, 0.5, 180),
      };
    case consts.SHAPES.CIRCLE:
    case consts.SHAPES.DIAMOND:
      return {
        top: mkPoint(0, -1, 270),
        right: mkPoint(1, 0, 0),
        buttom: mkPoint(0, 1, 90),
        left: mkPoint(-1, 0, 180),
      };
    case consts.SHAPES.TRIANGLE:
      return {
        top: mkPoint(0, -1, 270),
        right: mkPoint(0.8660254038 / 2, -0.25, -30, false),
        buttom: mkPoint(0, 0.5, 90),
        left: mkPoint(-0.8660254038 / 2, -0.25, 210, false),
      };
    case consts.SHAPES.HEXAGON:
      return {
        top: mkPoint(0, -1, 270),
        right: mkPoint(0.8660254038, 0, 0),
        buttom: mkPoint(0, 1, 90),
        left: mkPoint(-0.8660254038, 0, 180),
      };
    default:
      return {
        top: mkPoint(0, -0.5, 270),
        right: mkPoint(0.5, 0, 0),
        buttom: mkPoint(0, 0.5, 90),
        left: mkPoint(-0.5, 0, 180),
      };
  }
};

// Note: not adding strokeWidth on purpose, since I have a branch that will remove the need for that
function getShapeAnchorPoints(element: Shape) {
  const transform = getTransformParams("shape", element);
  const defaults = shapeStandardAnchorPoints(element.type);
  let points = defaults;
  if (element.type == consts.CANVAS_ELEMENTS.SHAPE && element.subtype) {
    const anchors = (AllShapes as any)[element.subtype]?.anchors;
    if (anchors) {
      points = {
        top: anchors.top ?? defaults.top,
        left: anchors.left ?? defaults.left,
        right: anchors.right ?? defaults.right,
        buttom: anchors.buttom ?? defaults.buttom,
      };
    }
  }
  points = structuredClone(points); // don't mutate originals
  return transformAnchorPointsInPlace(transform, points);
}

function getTextAnchorPoints(element: any) {
  const transform = getTransformParams("textBlock", element);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

function getStickyNoteAnchorPoints(element: StickyNote) {
  const transform = getTransformParams("stickyNote", element);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

function getTableAnchorPoints(element: TypeTableElement) {
  const transform = getTransformParams("table", element);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

function getDrawingAnchorPoints(element: Drawing) {
  const transform = getTransformParams("drawing", element);
  // TODO: this is already calculated in getTransformParams, so we can optimize this
  // maybe cache on the element...
  const drawing = element as Drawing;
  const bbox = BoundingBox.fromCoords(drawing.points).addPadding(
    (drawing.scaleX * parseStrokeWidth(drawing.strokeWidth)) / 2
  );
  const points = {
    top: mkPoint((bbox.left + bbox.right) / 2, bbox.top, 270, true),
    right: mkPoint(bbox.right, (bbox.top + bbox.bottom) / 2, 0, true),
    buttom: mkPoint((bbox.left + bbox.right) / 2, bbox.bottom, 90, true),
    left: mkPoint(bbox.left, (bbox.top + bbox.bottom) / 2, 180, true),
  };
  return transformAnchorPointsInPlace(transform, points);
}

function getFileAnchorPoints(file: FileSchema) {
  const transform = getTransformParams("file", file);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

// This function calculates the anchor points just from the node, without the element.
// in some canvases we had a bug that element.width=0 and element.height=0, so we need to calculate the size from the node
function getFileAnchorPointsFromNode(node: Konva.Node) {
  const clientRect = node.getClientRect({ skipTransform: true });
  const points = {
    top: mkPoint(clientRect.x + clientRect.width / 2, clientRect.y, 270, true),
    right: mkPoint(clientRect.x + clientRect.width, clientRect.y + clientRect.height / 2, 0, true),
    buttom: mkPoint(clientRect.x + clientRect.width / 2, clientRect.y + clientRect.height, 90, true),
    left: mkPoint(clientRect.x, clientRect.y + clientRect.height / 2, 180, true),
  };
  let transform = new Transform(
    node.x(),
    node.y(),
    node.scaleX(),
    node.scaleY(),
    (node.attrs.element.rotate || 0) as Degrees
  );
  return transformAnchorPointsInPlace(transform, points);
}

function getTaskCardAnchorPoints(element: TaskCard) {
  const transform = getTransformParams("taskCard", element);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

function getFrameAnchorPoints(element: Frame) {
  const transform = getTransformParams("frame", element);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

function getIntegrationItemAnchorPoints(element: IntegrationItem) {
  const transform = getTransformParams("integrationItem", element);
  const points = standardRectAnchorPoints();
  let points2: any = transformAnchorPointsInPlace(transform, points);
  delete points2["buttom"];
  return points2;
}
