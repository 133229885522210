type Direction = "up" | "right" | "down" | "left";
type ChevronProps = {
  color?: string;
};

export const Chevron = ({ color = "#848199", direction = "down" }: ChevronProps & { direction?: Direction }) => {
  const rotation: Record<Direction, number> = {
    down: 90,
    left: 180,
    right: 0,
    up: 270,
  };

  return (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${rotation[direction]}deg)`,
      }}
    >
      <rect
        width="7.13508"
        height="1.25145"
        transform="matrix(0.700642 0.713513 -0.687756 0.725942 0.860352 0)"
        fill={color}
      />
      <rect
        width="7.19165"
        height="1.25053"
        transform="matrix(0.685953 -0.727646 -0.702412 -0.71177 0.87793 10.3672)"
        fill={color}
      />
    </svg>
  );
};

export const ChevronDown = ({ color }: ChevronProps) => <Chevron color={color} direction="down" />;
export const ChevronTop = ({ color }: ChevronProps) => <Chevron color={color} direction="up" />;
export const ChevronLeft = ({ color }: ChevronProps) => <Chevron color={color} direction="left" />;
export const ChevronRight = ({ color }: ChevronProps) => <Chevron color={color} direction="right" />;
