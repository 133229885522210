import { BoardContext } from "elements/index";
import GanttController from "../controller";
import { GanttElement } from "shared/datamodel/schemas";
import { createEmptyContext, createMockReflect } from "elements/utils";
import { placeGanttElement } from "shared/datamodel/gantt";

export default class GanttGhostController extends GanttController {
  constructor(
    id?: string,
    canvasElement?: GanttElement,
    context?: BoardContext,
    allElementsData: [string, any][] = []
  ) {
    const tasks = allElementsData.filter(([, element]) => element?.containerId === id);

    const startDate = new Date().getTime();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 14);
    const element = canvasElement ?? placeGanttElement({ x: 0, y: 0 }, startDate, endDate.getTime()).item;

    context = createEmptyContext({
      reflectOverrides: createMockReflect({
        subscribeFunc(_, callback) {
          callback(
            tasks.map(([id, element]) => ({
              id: id,
              task: element,
            }))
          );
        },
      }),
    });

    super("dummy", element, context);
  }

  canAddSplitColumnRight(splitId: string): boolean {
    return false;
  }

  canAddSplitRowBelow(splitId?: string): boolean {
    return false;
  }

  canAddNewTask(): boolean {
    return false;
  }
}
