import { ConnectorLine } from "./connector-line";
import { ConnectorEndpoint } from "./connector-utils";
import { computeConnectorDrawingData } from "./connector-drawing-utils";
import React, { forwardRef, Ref, useMemo } from "react";
import Konva from "konva";
import { useDeepEqualMemo } from "frontend/hooks/use-deep-memoize";

const SimpleConnector = forwardRef(
  (
    {
      id,
      p1,
      p2,
      lineType,
      element,
      curveStrength,
    }: {
      id: string;
      p1: ConnectorEndpoint;
      p2: ConnectorEndpoint;
      lineType: "line" | "curve" | "elbow";
      element: any;
      curveStrength?: number;
    },
    ref: Ref<Konva.Shape>
  ) => {
    const startBbox = { ...p1, width: 0, height: 0 };
    const endBbox = { ...p2, width: 0, height: 0 };
    const startRotation = p1.rotation ?? 0;
    const endRotation = p2.rotation ?? 0;

    const memoizedElement = useDeepEqualMemo({
      lineType,
      innerPoints: element.innerPoints,
      scaleX: element.scaleX,
      scaleY: element.scaleY,
      rotate: element.rotate,
      anchorsMode: element.anchorsMode,
      pointerStyles: element.pointerStyles,
    });

    const data = useMemo(
      () =>
        computeConnectorDrawingData(
          p1,
          p2,
          memoizedElement,
          startBbox,
          endBbox,
          startRotation,
          endRotation,
          curveStrength
        ),
      [p1, p2, memoizedElement, startBbox, endBbox, startRotation, endRotation, curveStrength]
    );
    return <ConnectorLine p1={p1} p2={p2} id={id} element={element} data={data} ref={ref} />;
  }
);

export default SimpleConnector;
