import React from "react";
import { Rect, Text } from "react-konva";

type EmptyStateTasksProps = {
  width: number;
  height: number;
};

const OFFSET = 50;

export const EmptyStateTasks = ({ height, width }: EmptyStateTasksProps) => {
  return (
    <>
      <Rect
        x={width / 2 - 200}
        y={height / 4 + OFFSET / 2}
        width={400}
        height={height / 2 - OFFSET}
        text=" + Double click to add a Task"
        align="center"
        verticalAlign="center"
        fontSize={40}
        fill="white"
        fontFamily="Poppins"
        fontVariant="300"
      />
      <Text
        x={0}
        y={height / 4 + OFFSET / 2}
        width={width}
        height={height / 2 - OFFSET}
        text="+ Double click to add a Task"
        align="center"
        verticalAlign="middle"
        fontSize={26}
        fill="#A9AAAB"
        fontFamily="Poppins"
        fontVariant="300"
      />
    </>
  );
};
