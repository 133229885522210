type Point = { x: number; y: number };
type Rect = { x: number; y: number; width: number; height: number };

export function isPoint(p?: any): p is Point {
  return typeof p?.x == "number" && typeof p?.y == "number";
}

export function invalid(p: Point) {
  return Number.isNaN(p.x) || Number.isNaN(p.y);
}

export function pAdd(a: Point, b: Point, out?: Point) {
  if (out == undefined) {
    return { x: a.x + b.x, y: a.y + b.y };
  }
  out.x = a.x + b.x;
  out.y = a.y + b.y;
  return out;
}

export function pSub(a: Point, b: Point, out?: Point) {
  if (out == undefined) {
    return { x: a.x - b.x, y: a.y - b.y };
  }
  out.x = a.x - b.x;
  out.y = a.y - b.y;
  return out;
}

export function pMul(a: Point, s: number, out?: Point) {
  if (out == undefined) {
    return { x: a.x * s, y: a.y * s };
  }
  out.x = a.x * s;
  out.y = a.y * s;
  return out;
}

export function lerp(a: Point, b: Point, t: number) {
  return { x: a.x * (1 - t) + b.x * t, y: a.y * (1 - t) + b.y * t };
}

export function lenSqr(a: Point, b?: Point) {
  if (b == undefined) {
    return a.x * a.x + a.y * a.y;
  }
  return Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2);
}

export function distance(a: Point, b?: Point) {
  return Math.sqrt(lenSqr(a, b));
}

export function length(a: Point) {
  return Math.sqrt(a.x * a.x + a.y * a.y);
}

export function manhattanDistane(a: Point, b?: Point) {
  if (b == undefined) {
    return Math.abs(a.x) + Math.abs(a.y);
  }
  return Math.abs(a.x - b.x) + Math.abs(a.y - b.y);
}

export function normalize(a: Point, out?: Point) {
  let len = length(a);
  if (len == 0) {
    console.warn("asked to normalized vector of length 0");
    len = 1;
  }
  if (out == undefined) {
    a.x /= len;
    a.y /= len;
    return a;
  }
  out.x = a.x / len;
  out.y = a.y / len;
  return out;
}

export function normalized(a: Point) {
  let len = length(a);
  if (len == 0) {
    console.warn("asked to normalized vector of length 0");
    len = 1;
  }
  return { x: a.x / len, y: a.y / len };
}

export function moveTowards(src: Point, dest: Point, length: number) {
  const dir = normalized({ x: dest.x - src.x, y: dest.y - src.y });
  return { x: src.x + dir.x * length, y: src.y + dir.y * length };
}

export function moveTowardsSide(src: Point, side: string, distance: number) {
  switch (side) {
    case "top":
      return { x: src.x, y: src.y - distance };
    case "buttom":
    case "bottom":
      return { x: src.x, y: src.y + distance };
    case "left":
      return { x: src.x - distance, y: src.y };
    case "right":
      return { x: src.x + distance, y: src.y };
    default:
      return src;
  }
}

export function vectorFromTo(a: Point, b: Point) {
  return { x: b.x - a.x, y: b.y - a.y };
}

export function mulByScalar(a: Point, scalar: number) {
  return { x: a.x * scalar, y: a.y * scalar };
}

export function rotate90(a: Point, out?: Point) {
  if (out == undefined) {
    return { x: -a.y, y: a.x };
  }
  out.x = -a.y;
  out.y = a.x;
  return out;
}

export function rotated90(a: Point) {
  return { x: -a.y, y: a.x };
}

type AngleRadians = number;
export function rotate(a: Point, angle: AngleRadians, out?: Point) {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  const x = a.x * cos - a.y * sin;
  const y = a.x * sin + a.y * cos;
  if (out == undefined) {
    return { x, y };
  }
  out.x = x;
  out.y = y;
  return out;
}

export function reverse(a: Point, out?: Point) {
  if (out == undefined) {
    return { x: -a.x, y: -a.y };
  }
  out.x = -a.x;
  out.y = -a.y;
  return out;
}

export function reversed(a: Point) {
  return { x: -a.x, y: -a.y };
}

export function movePoint(point: { x: number; y: number }, rotation: number, distance: number) {
  let x = point.x + distance * Math.cos((rotation * Math.PI) / 180);
  let y = point.y + distance * Math.sin((rotation * Math.PI) / 180);
  return { x, y };
}
