import type { BoardContext } from "elements/index";
import type { CanvasElement } from "shared/datamodel/schemas";
import { IObservableController, ObservableController } from "elements/base/observable";
import { Action } from "utils/undo-redo/undo-redo-stack";

/**
 * The controller of an element
 * it needs to implement `IObservableController` for the ability to notify observers (specifically react components) about changes
 */
export interface ElementController<T extends CanvasElement> extends IObservableController {
  /**
   * The id of the element
   */
  id: string;

  /**
   * The element itself
   */
  element: T;

  /**
   * The context of the board
   */
  context: BoardContext;

  /**
   * Update the element
   * @param element
   */
  updateElement(element: T): void;

  /**
   * Update the context
   * @param context
   */
  updateContext(context: BoardContext): void;

  /**
   * Start a sequence of actions for undo/redo
   */
  startActionSequence(): void;

  /**
   * End a sequence of actions for undo/redo
   */
  endActionSequence(): void;

  performAction(action: Action): void;

  patchElement<E extends CanvasElement>(id: string, mutate: (element: E) => void): void;

  destroy(): void;
}

export class BaseElementController<T extends CanvasElement>
  extends ObservableController
  implements ElementController<T>
{
  id: string;
  element: T;
  context: BoardContext;

  constructor(id: string, element: T, context: BoardContext) {
    super();
    this.id = id;
    this.element = element;
    this.context = context;
  }

  updateElement(element: T): void {
    this.element = element;
  }

  updateContext(context: BoardContext): void {
    this.context = context;
  }

  startActionSequence(id?: string): void {
    this.context.undoRedoStack.startSequence(id);
  }

  endActionSequence(id?: string): void {
    this.context.undoRedoStack.endSequence(id);
  }

  performAction(action: Action): void {
    this.context.undoRedoStack.addAction(action);
  }

  patchElement<E extends CanvasElement>(
    id: string,
    mutate: (element: E) => void,
    sequenceId?: string
  ): Promise<E | undefined> {
    return this.context.undoRedoStack.patchElement(id, mutate, sequenceId);
  }

  destroy(): void {}
}
