import { Group, Path } from "react-konva";
import { Point } from "shared/datamodel/schemas";
import * as PointUtils from "frontend/utils/point-utils";

const ArrowHeadNames = {
  NONE: "none",
  ARROW: "arrow",
  FILLED_LONG_ARROW: "filled-long-arrow",
  FILLED_WIDE_ARROW: "filled-wide-arrow",
  FILLED_ROUNDED_ARROW: "filled-rounded-arrow",
  LONG_ARROW: "long-arrow",
  WIDE_ARROW: "wide-arrow",
  ROUNDED_ARROW: "rounded-arrow",
} as const;

export type ArrowHeadType = typeof ArrowHeadNames[keyof typeof ArrowHeadNames];

type ArrowHeadPathSegment = { path: string; fill?: string | boolean };

export const ArrowHeadDrawingData: Record<
  ArrowHeadType,
  { segments: ArrowHeadPathSegment[]; width: number; height: number }
> = {
  none: { segments: [], width: 0, height: 0 },
  arrow: {
    segments: [
      { path: "M1 1L8.88725 8.94241C8.94952 9.00511 8.94917 9.10642 8.88646 9.16869L1 17" },
      { path: "M2.8 9 L8 9" },
    ],
    width: 12,
    height: 16,
  },
  "filled-long-arrow": {
    segments: [{ path: "M0.750001 1.10899L18.6062 7L0.75 12.891L0.750001 1.10899Z", fill: true }],
    width: 21.5,
    height: 12,
  },
  "filled-wide-arrow": {
    segments: [{ path: "M0.750002 1.71921L16.3966 10L0.750001 18.2808L0.750002 1.71921Z", fill: true }],
    width: 19.5,
    height: 18,
  },
  "filled-rounded-arrow": {
    segments: [
      {
        path: "M14.9884 10.5469L1.06652 20C1.06652 20 0.988452 20 1.00146 19.9388L3.34347 10.5928C3.34347 10.5928 3.34347 10.5775 3.34347 10.5622L1.00146 1.06321C1.00146 1.06321 1.02749 0.986726 1.06652 1.00202L14.9884 10.4551C14.9884 10.4551 15.0145 10.5316 14.9884 10.5469Z",
        fill: true,
      },
    ],
    width: 18,
    height: 19,
  },
  "long-arrow": {
    segments: [{ path: "M0.75 1.10899L18.6062 7L0.749999 12.891L0.75 1.10899Z", fill: "white" }],
    width: 21.5,
    height: 12,
  },
  "wide-arrow": {
    segments: [{ path: "M0.750002 1.71921L16.3966 10L0.750001 18.2808L0.750002 1.71921Z", fill: "white" }],
    width: 19.5,
    height: 18,
  },
  "rounded-arrow": {
    segments: [
      {
        path: "M14.9884 10.5469L1.06652 20C1.06652 20 0.988452 20 1.00146 19.9388L3.34347 10.5928C3.34347 10.5928 3.34347 10.5775 3.34347 10.5622L1.00146 1.06321C1.00146 1.06321 1.02749 0.986726 1.06652 1.00202L14.9884 10.4551C14.9884 10.4551 15.0145 10.5316 14.9884 10.5469Z",
        fill: "white",
      },
    ],
    width: 18,
    height: 19,
  },
};

export function getArrowheadIconRenderer(arrowHead: ArrowHeadType) {
  return ArrowHeadIcons[arrowHead];
}

const ArrowHeadIcons: Record<ArrowHeadType, () => JSX.Element> = {
  none: () => <></>,
  arrow: () => (
    <svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5 L20 5" stroke="currentColor" />
      <path d="M17 1L20.8873 4.91444C20.9495 4.97714 20.9492 5.07845 20.8865 5.14071L17 9" stroke="currentColor" />
    </svg>
  ),
  "long-arrow": () => (
    <svg width="23" height="9" viewBox="0 -1 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4 L13 4" stroke="currentColor" />
      <path d="M20.6062 4L13.25 6.4269L13.25 1.5731L20.6062 4Z" stroke="currentColor" />
    </svg>
  ),
  "filled-long-arrow": () => (
    <svg width="23" height="9" viewBox="0 -1 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4 L13 4" stroke="currentColor" />
      <path d="M20.6062 4L13.25 6.4269L13.25 1.5731L20.6062 4Z" stroke="currentColor" fill="currentColor" />
    </svg>
  ),
  "wide-arrow": () => (
    <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5 L15 5" stroke="currentColor" />
      <path d="M15.5 1.91055L21.3862 5L15.5 8.08945L15.5 1.91055Z" stroke="currentColor" />
    </svg>
  ),
  "filled-wide-arrow": () => (
    <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5 L15 5" stroke="currentColor" />
      <path d="M15.5 1.91055L21.3862 5L15.5 8.08945L15.5 1.91055Z" fill="currentColor" stroke="currentColor" />
    </svg>
  ),
  "rounded-arrow": () => (
    <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5 L17 5" stroke="currentColor" />
      <path
        d="M21.995 5.01975L16.0285 9C16.0285 9 15.9951 9 16.0006 8.97424L17.0043 5.03907C17.0043 5.03907 17.0043 5.03263 17.0043 5.02619L16.0006 1.02661C16.0006 1.02661 16.0118 0.994411 16.0285 1.00085L21.995 4.9811C21.995 4.9811 22.0062 5.01331 21.995 5.01975Z"
        stroke="currentColor"
      />
    </svg>
  ),
  "filled-rounded-arrow": () => (
    <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5 L17 5" stroke="currentColor" />
      <path
        d="M21.995 5.01975L16.0285 9C16.0285 9 15.9951 9 16.0006 8.97424L17.0043 5.03907C17.0043 5.03907 17.0043 5.03263 17.0043 5.02619L16.0006 1.02661C16.0006 1.02661 16.0118 0.994411 16.0285 1.00085L21.995 4.9811C21.995 4.9811 22.0062 5.01331 21.995 5.01975Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  ),
};

export function ArrowHead({
  arrowHead,
  position,
  comingFrom,
  strokeWidth,
  strokeColor,
}: {
  arrowHead: ArrowHeadType;
  position: Point;
  comingFrom: Point;
  strokeWidth: number;
  strokeColor: string;
}) {
  if (PointUtils.distance(comingFrom, position) < 1) return null;
  let direction = PointUtils.vectorFromTo(comingFrom, position);
  PointUtils.normalize(direction, direction);

  const { segments, width, height } = ArrowHeadDrawingData[arrowHead];
  const angle = (Math.atan2(direction.y, direction.x) * 180) / Math.PI;

  return (
    <Group x={position.x} y={position.y} rotation={angle} offsetX={width} offsetY={height / 2 + 1}>
      {segments.map(({ path, fill }, i) => (
        <Path
          key={i}
          data={path}
          fill={typeof fill === "string" ? fill : !!fill ? strokeColor : "transparent"}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          lineCap="round"
          lineJoin="round"
          name="connector-line-arrowhead"
        />
      ))}
    </Group>
  );
}
