import { useAtomValue } from "jotai";
import { Frame } from "shared/datamodel/schemas/frame";
import { editingElementIdAtom } from "state-atoms/stage-atoms";
import FrameTitleEditableTextInput from "./frame-title-editable-text-input";
import TitleTextInput from "./title-text-input";

export default function FrameTitleCanvasElement({
  id,
  element,
  onChangeElement,
}: {
  id: string;
  element: Frame;
  onChangeElement: (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;
}) {
  const editingElementId = useAtomValue(editingElementIdAtom);
  const isEditing = editingElementId === id;

  return isEditing ? 
    <FrameTitleEditableTextInput 
      id={id}
      element={element}
      onChangeElement={onChangeElement}
    />
   : (
    <TitleTextInput
      id={id}
      element={element}
    />
  );
}
