import { ReadTransaction, WriteTransaction } from "@workcanvas/reflect";
import { authenticatedPut } from "../util/utils";
import { z } from "zod";

const boardMetadataSchema = z.object({
  lastVisited: z.record(z.string(), z.number()).default({}), // userId => time
  lastChanged: z.number().optional(),
  snapshotImage: z.string().optional(),
});

export type BoardMetadata = z.infer<typeof boardMetadataSchema>;

async function getBoardMetadata(tx: ReadTransaction, boardId: string): Promise<BoardMetadata | null> {
  try {
    const board = (await tx.get(key(boardId))) || {};
    return boardMetadataSchema.parse(board);
  } catch {
    return null;
  }
}

async function updateVisitedForUser(
  tx: WriteTransaction,
  { boardId, userId }: { boardId: string; userId: string }
): Promise<void> {
  const board = await getBoardMetadata(tx, boardId);
  if (board) {
    const lastVisited = { ...board.lastVisited, [userId]: new Date().getTime() };
    const next = { ...board, lastVisited };
    await tx.put(key(boardId), next); //this write transaction should not be authenticated for viewers
  }
}

async function updateLastChangedForBoard(
  tx: WriteTransaction,
  { boardId, snapshotImage = null }: { boardId: string; snapshotImage: string | null }
) {
  const board = await getBoardMetadata(tx, boardId);
  if (board) {
    const next = { ...board, lastChanged: new Date().getTime() };
    if (snapshotImage) {
      next.snapshotImage = snapshotImage;
    }
    await authenticatedPut(tx, key(boardId), next);
  }
}

export async function getCustomColorsForUser(tx: ReadTransaction, userId: string): Promise<string[]> {
  const userColors = await tx.get(customColorsKey(userId));
  if (userColors && Array.isArray(userColors)) {
    return userColors;
  }
  return [];
}

export async function addCustomColorForUser(
  tx: WriteTransaction,
  { userId, color }: { color: string; userId: string }
): Promise<void> {
  const userColors = await getCustomColorsForUser(tx, userId);
  if (!userColors.includes(color)) {
    await authenticatedPut(tx, customColorsKey(userId), [...userColors, color]);
  }
}

function key(boardId: string) {
  return `board-${boardId}`;
}

function customColorsKey(userId: string) {
  return `custom_colors_${userId}`;
}

export type AM = typeof accountMutators;

export const accountMutators = {
  getBoardMetadata,
  updateVisitedForUser,
  updateLastChangedForBoard,
  getCustomColorsForUser,
  addCustomColorForUser,
  nop: async (_: WriteTransaction) => { },
};
