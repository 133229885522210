import consts from "shared/consts";
import { placeIntegrationElement } from "shared/datamodel/integration-item";
import { IntegrationItem } from "shared/datamodel/schemas";
import { IntegrationType } from "shared/integrations/integration";

export type IntegrationCardsMap = {
  [itemId: string]: { id: string; element: IntegrationItem; position: { x: number; y: number } };
};

export function createIntegrationCards(
  integrationId: string,
  itemIds: string[],
  positionFunc: (index: number) => { x: number; y: number }
): IntegrationCardsMap {
  return itemIds.reduce((acc: any, itemId: string, index: number) => {
    const position = positionFunc(index);
    const { item: cardElement, id: newId } = placeIntegrationElement(position, integrationId, IntegrationType.monday, {
      itemId,
    });
    const elementId = `${consts.CANVAS_ELEMENTS.INTEGRATION}-${newId}`;
    acc[itemId] = { id: elementId, element: cardElement, position };
    return acc;
  }, {} as IntegrationCardsMap);
}
