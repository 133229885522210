import { MondayIntegrationConfiguration } from "../datamodel/schemas/integration-item";
import { IntegrationType, MondayIntegrationType } from "./integration";

type Context = { integrationType: IntegrationType; config: any; data?: any };
type MondayColumn = { id: string; title: string; type: string; settings_str: string };
type MondayGroup = { id: string; title: string; color: string };
type MondayColumnValue = { id: string; type: string; value: string };
type MondayAsset = { id: string; public_url: string; file_extension: string };
type MondayTaskItem = {
  id: string;
  name: string;
  created_at: string;
  group: MondayGroup;
  column_values: MondayColumnValue[];
  assets: MondayAsset[];
  updates: any[];
  url: string;
  subitems: { id: string }[];
  parent_item?: {
    name: string;
    board: { name: string };
  };
};
type MondayOrgChartItem = {
  id: string;
  name: string;
  group: MondayGroup;
  column_values: MondayColumnValue[];
  self: string;
  parent: string;
  job_title: string;
  email: string;
  url: string;
};
type MondayItem = MondayTaskItem | MondayOrgChartItem;
type MondayData = { boardId: string; columns: MondayColumn[] };

// Monday => Canvas
export function mapItemsData(items: any[], context: Context) {
  return items.map((item) => mapItemData(item, context));
}

export function mapItemData(item: any, context: Context) {
  const { integrationType, config, data } = context;
  switch (integrationType) {
    case IntegrationType.monday:
      return mapMondayItemData(item, config, data);
  }
}

export function mapMondayItemDataForHook(
  item: any,
  changedColumnValue: { id: string; value: any; type: string; title: string },
  configuration: any
) {
  switch (configuration.type) {
    case MondayIntegrationType.tasks:
    case MondayIntegrationType.live:
      return item;
    case MondayIntegrationType.org_chart: {
      switch (changedColumnValue.id) {
        case configuration.columnMapping.selfId:
          item.self = changedColumnValue.value;
          break;
        case configuration.columnMapping.parentId:
          item.parent = changedColumnValue.value;
          break;
        case configuration.columnMapping.jobTitle:
          item.job_title = changedColumnValue.value;
          break;
        case configuration.columnMapping.email:
          item.email = changedColumnValue.value;
          break;
      }
      return item;
    }
  }
}

function mapMondayItemData(item: MondayItem, config: MondayIntegrationConfiguration, data?: MondayData) {
  switch (config.type) {
    case MondayIntegrationType.tasks:
    case MondayIntegrationType.live: {
      const { columns = [] } = data || {};
      const {
        id,
        name,
        created_at,
        group,
        column_values,
        assets: itemAssets,
        updates,
        url,
        parent_item,
        subitems = [],
      } = item as MondayTaskItem;
      // make map for more efficient lookup
      const columnValues = column_values.reduce((acc, cv) => {
        acc[cv.id] = cv;
        return acc;
      }, {} as { [key: string]: MondayColumnValue });
      const assets = itemAssets.reduce((acc, asset) => {
        acc[asset.id] = asset;
        return acc;
      }, {} as { [key: string]: MondayAsset });
      const props = columns
        .map((column) => {
          const { id, type, title } = column;
          const columnValue = columnValues[column.id];
          if (!columnValue) {
            return null;
          }
          try {
            const mappedValue = mapMondayColumnValue(columnValue, type, { column, assets });
            if (mappedValue) {
              return { id, value: mappedValue, type, title };
            }
          } catch (e) {
            console.error(`failed to map column value for column type ${type}`, e);
          }
          return null;
        })
        .filter((p) => p);
      return {
        id,
        name,
        createdAt: created_at,
        group,
        updates: updates.map((u) => ({
          id: u.id,
          userName: u.creator.name,
          userURL: u.creator.photo_thumb,
          createdAt: u.created_at,
          text: u.text_body,
        })),
        columnValues: props,
        url,
        parent_item,
        subitems_ids: subitems.map((si) => si.id),
      };
    }
    case MondayIntegrationType.org_chart: {
      const { columns = [] } = data || {};
      const { id, name, group, column_values, url } = item as MondayOrgChartItem;
      // make map for more efficient lookup
      const columnValues = column_values.reduce((acc, cv) => {
        acc[cv.id] = cv;
        return acc;
      }, {} as { [key: string]: MondayColumnValue });

      interface ColumnValue {
        id: string;
        value: any;
        type: string;
        title: string;
      }
      let self = config.columnMapping?.selfId === "name" ? name : undefined,
        parent: string | undefined,
        job_title: string | undefined,
        rest: Array<ColumnValue> = [];
      for (const column of columns) {
        const { id, type, title } = column;
        const columnValue = columnValues[column.id];
        if (columnValue) {
          const mappedValue = mapMondayColumnValue(columnValue, type, { column });
          if (mappedValue) {
            if (id == config.columnMapping?.selfId) {
              self = mappedValue;
            } else if (id == config.columnMapping?.parentId) {
              parent = mappedValue;
            } else if (id == config.columnMapping?.jobTitle) {
              job_title = mappedValue;
            } else {
              rest.push({ id, value: mappedValue, type, title });
            }
          }
        }
      }

      return {
        id,
        title: name,
        group: group && { id: group.id, title: group.title, color: group.color },
        self,
        parent,
        job_title,
        columnValues: rest,
        url,
      };
    }
  }
}

export function mapMondayColumnValue(
  value: any,
  type: string,
  data?: { column?: MondayColumn; assets?: { [id: string]: MondayAsset } }
) {
  if (!value) {
    return null;
  }
  switch (type) {
    case "status":
      return statusMapper(value.value, data?.column);
    case "date":
      return dateMapper(value.value);
    case "people":
      return value.text; // multiplePersonMapper(value.value);
    case "file":
      return filesMapper(value.value, data?.assets);
    default:
      // fallback to textual value;
      return value.text ?? value.value?.value ?? value.value ?? null;
  }
}

function statusMapper(value: any, column?: MondayColumn) {
  if (!value) {
    return null;
  }
  if (typeof value === "string") {
    value = JSON.parse(value);
  }
  if (value.text) {
    return value.text;
  }
  if (value.label) {
    return { text: value.label.text, color: value.label.style.color };
  }
  if (column && column.settings_str) {
    const { labels, labels_colors } = JSON.parse(column.settings_str);
    const label = labels[value.index];
    const colorObj = labels_colors[value.index];
    if (label) {
      return { text: label, color: colorObj.color ?? "#fff" };
    }
  }
  // fallback to index
  return { index: value.index };
}

function dateMapper(value: any) {
  if (!value) {
    return null;
  }
  if (typeof value === "string") {
    value = JSON.parse(value);
  }
  if (value.date) {
    return value.date;
  }
  return null;
}

function multiplePersonMapper(value: any) {
  if (!value) {
    return null;
  }
  if (typeof value === "string") {
    value = JSON.parse(value);
  }
  if (value.personsAndTeams) {
    return value.personsAndTeams.filter((p: any) => p.kind === "person").map((p: any) => p.id);
  }
  return null;
}

function filesMapper(value: any, assets?: { [id: string]: MondayAsset }) {
  if (!value) {
    return null;
  }
  if (typeof value === "string") {
    value = JSON.parse(value);
  }
  if (value.files && assets) {
    return value.files
      .map((file: any) => {
        if (!file.assetId) {
          return null;
        }
        const asset = assets[file.assetId.toString()];
        return {
          id: file.assetId.toString(),
          name: file.name,
          url: asset?.public_url ?? file.url,
          extension: asset?.file_extension,
          isImage: file.isImage === "true",
        };
      })
      .filter((p: any) => p); // filter nulls
  }
  return null;
}

// Canvas => Monday
export function reverseMapItemData(item: any, context: Context) {
  const { integrationType, config, data } = context;
  switch (integrationType) {
    case IntegrationType.monday:
      return mapItemDataToMonday(item, config, data);
  }
}

export function mapItemDataToMonday(item: any, config: MondayIntegrationConfiguration, data?: any) {
  const { columns = [] } = data || {};
  const props = config.columns.reduce((acc, { id, type }) => {
    const column = columns[id];
    const value = item.columnValues.find((cv: any) => cv.id === id);
    const mappedValue = mapItemDataToMondayColumnValue(value, type, column);
    if (mappedValue) {
      acc[id] = mappedValue;
    }
    return acc;
  }, {} as { [key: string]: any });
  return {
    name: item.title,
    ...props,
  };
}

function mapItemDataToMondayColumnValue(value: any, type: string, column?: MondayColumn) {
  switch (type) {
    case "status":
      return value; // TODO: reverseMapping(value, column);
    default:
      return value;
  }
}
