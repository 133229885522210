import { RefObject, useEffect, useRef } from "react";

declare global {
  interface CustomEventMap {
    'transform-start': CustomEvent<{nodes:any[]}>;
    'transform': CustomEvent;
    'transform-end': CustomEvent;
  }
}

declare global {
  interface WindowEventMap extends CustomEventMap {}
}

// Window Event based useAnyEvent interface
function useAnyEvent<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
  element?: undefined,
  options?: boolean | AddEventListenerOptions
): void;

// Element Event based useAnyEvent interface
function useAnyEvent<K extends keyof HTMLElementEventMap, T extends HTMLElement = HTMLDivElement>(
  eventName: K,
  handler: (event: HTMLElementEventMap[K]) => void,
  element: RefObject<T>,
  options?: boolean | AddEventListenerOptions
): void;

// Document Event based useAnyEvent interface
function useAnyEvent<K extends keyof DocumentEventMap>(
  eventName: K,
  handler: (event: DocumentEventMap[K]) => void,
  element: RefObject<Document>,
  options?: boolean | AddEventListenerOptions
): void;



// Implementation
function useAnyEvent<
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap,
  T extends HTMLElement | void = void
>(
  eventName: KW | KH | string,
  handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event) => void,
  element?: RefObject<T>,
  options?: boolean | AddEventListenerOptions
) {
  // Create a ref that stores handler
  const savedHandler = useRef(handler);
  savedHandler.current = handler;

  // options is either boolean, or an object with the following.
  // capture?: boolean;
  // once?: boolean;
  // passive?: boolean;
  // signal?: AbortSignal;

  // I dissect it and pass it to the useEffect hook
  let capture = undefined,
    once = undefined,
    passive = undefined,
    signal = undefined;
  if (typeof options === "object") {
    capture = options.capture;
    once = options.once;
    passive = options.passive;
    signal = options.signal;
  }

  useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener: typeof handler = (event) => savedHandler.current(event);

    targetElement.addEventListener(eventName, eventListener, options);

    return () => targetElement.removeEventListener(eventName, eventListener);
  }, [eventName, element, !!options, capture, once, passive, signal]);
}

export default useAnyEvent;
