import React, { forwardRef } from "react";

export type IconProps = React.PropsWithChildren<{
  color?: string;
  size?: number;
  mirrored?: boolean; // horizontal mirror
  flipped?: boolean; // vertical mirror
  alt?: string;
}> &
  Omit<React.SVGProps<SVGSVGElement>, 'ref'>;

export type RenderFunction = (color: string) => React.ReactNode | null;

interface IconBaseProps extends IconProps {
  renderPath: RenderFunction;
}

const IconBase = forwardRef<SVGSVGElement, IconBaseProps>((props, ref) => {
  let { alt, color, size, width, height, viewBox, mirrored, flipped, children, renderPath, ...restProps } = props;
  color ??= "currentColor";
  size ??= 20;
  width ??= size;
  height ??= size;
  mirrored ??= false;
  flipped ??= false;
  viewBox ??= "0 0 20 20";
  let scaleX = mirrored ? -1 : 1,
    xofs = mirrored ? -size : 0;
  let scaleY = flipped ? -1 : 1,
    yofs = flipped ? -size : 0;
  let transform = mirrored || flipped ? `scale(${scaleX} ${scaleY}) translate(${xofs} ${yofs})` : undefined;
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox={viewBox}
      preserveAspectRatio="xMidYMid meet"
      {...restProps}
    >
      <g transform={transform}>
        {!!alt && <title>{alt}</title>}
        {children}
        {renderPath(color)}
      </g>
    </svg>
  );
});

IconBase.displayName = "IconBase";

export default IconBase;