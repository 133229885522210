import { clamp } from "rambda";
import {
  CARD_HEIGHT,
  CARD_WIDTH,
  GAP_X,
  GAP_Y,
  HEADER,
  PADDING_X,
  PADDING_Y,
} from "shared/datamodel/card-stack";

export const ANIMATION_DURATION = 0.150;

export const EVT_ELEMENT_DROP = "event-element-drop";
export const EVT_ELEMENT_DRAG = "event-element-drag";
export const EVT_ELEMENT_DRAG_START = "event-element-drag-start";

declare global {
  interface CustomEventMap {
    [EVT_ELEMENT_DROP]: CustomEvent<{ x: number; y: number; ids: string[] }>;
    [EVT_ELEMENT_DRAG]: CustomEvent<{ mousePosition: { x: number; y: number }; ids: string[] }>;
    [EVT_ELEMENT_DRAG_START]: CustomEvent<{ mousePosition: { x: number; y: number }; ids: string[] }>;
  }
}

// Card stacks width and height note
// ---------------------------------
// Stack size is not continuous but limited to discrete levels, so cards can fit without clipping.
// When the user changes the size of the stack, we need to round it to the nearest step.
// The algorithm is simple:
// 1. compute actual size in pixels, by multiplying by scale
// 2. add 1 - this improves numerical stability
//    (without it, instead of getting n=3 I sometimes get n=2.99999999995)
// 3. compute number of cards that fit
// 4. round down and clamp between minimum and maximum
// 5. compute new size for the stack from the number in step 4

export interface CardGridLayoutProps {
  gap: number;
  padding: number;
  cardSize: number;
  min?: number;
  max?: number;
}

export const cardGridWidthParams = {
  gap: GAP_X,
  padding: PADDING_X * 2,
  cardSize: CARD_WIDTH,
  min: 1,
  max: 1,
};

export const cardGridHeightParams = {
  gap: GAP_Y,
  padding: PADDING_Y + HEADER,
  cardSize: CARD_HEIGHT,
  min: 1,
  max: 100000,
};

// Compute the maximum number of cards that can fit in a given size.
export function numberOfCardsInDimension(
  size: number,
  scale: number,
  { gap, padding, cardSize, min = 1, max = 1 }: CardGridLayoutProps
) {
  size *= scale;
  size++;
  let n = (size - padding + gap) / (cardSize + gap);
  return clamp(min, max, Math.floor(n));
}

// Compute the smallest size in pixels that can contain columns/rows cards.
export function sizeOfCardsLine(cardCount: number, { gap, padding, cardSize }: CardGridLayoutProps) {
  return padding + cardCount * cardSize + (cardCount - 1) * gap;
}

// Compute the maximum grid params that can fit in a given size.
export function computeColumnsAndRows(width: number, height: number, scaleX = 1, scaleY = 1) {
  const columns = numberOfCardsInDimension(width, scaleX, cardGridWidthParams);
  const rows = numberOfCardsInDimension(height, scaleY, cardGridHeightParams);
  return { columns, rows };
}

export function computePixelSizeFromGridSize(size: { columns: number; rows: number }) {
  let width = sizeOfCardsLine(size.columns, cardGridWidthParams);
  let height = sizeOfCardsLine(size.rows, cardGridHeightParams);
  return { width, height };
}

export function roundSizeDownToNearestStep(width: number, height: number, scaleX = 1, scaleY = 1) {
  const grid = computeColumnsAndRows(width, height, scaleX, scaleY);
  return computePixelSizeFromGridSize(grid);
}

export function posToColAndRow(x: number, y: number, offsetX: number, offsetY: number, columns: number, rows: number) {
  let col = Math.round((x - offsetX) / (CARD_WIDTH + GAP_X));
  let row = Math.round((y - offsetY) / (CARD_HEIGHT + GAP_Y));
  col = clamp(0, columns - 1, col);
  row = clamp(0, rows - 1, row);
  return { col, row };
}

export function cardPosition(index: number, columns: number) {
  let x = index % columns;
  let y = index / columns;
  x *= CARD_WIDTH + GAP_X;
  y *= CARD_HEIGHT + GAP_Y;
  return { x, y };
}
