import React, { CSSProperties } from "react";
import { Html } from "react-konva-utils";

export default function HtmlFrameOnCanvas({
  box,
  css,
  children,
}: {
  box: { x: number; y: number; width: number; height: number };
  children: React.ReactNode;
  css?: CSSProperties;
}) {
  return (
    <Html groupProps={{ x: box.x, y: box.y }}>
      <div style={{ width: box.width, height: box.height, ...css }}>{children}</div>
    </Html>
  );
}
