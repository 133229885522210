import consts, { TypeCanvasElement } from "shared/consts";

const elementsValues = Object.values(consts.CANVAS_ELEMENTS).reduce((acc: Record<string, false>, element: string) => {
  acc[`canvas-element-${element}`] = false;
  return acc;
}, {}) as Record<`canvas-element-${TypeCanvasElement}`, false>;

export const DefaultFeatureFlagsValues = {
  "test-flag": false,
  "test-variant": { temp: "temp" },
  ...elementsValues,
  "org-chart-walkthrough": false,
  "save-as-template-feature": false,
  "allow-table-creation": false,
  "per-seat-license": null,
  "restrict-free-canvas-elements": { elementsNumber: 10 },
  "cursor-visibility-control": false,
  "orgchart-filters-column-dropdown": false,
  "password-protected-canvas": false,
  "orgchart-department-enabled": false,
  "admin-configurable-recommended-home-templates": false,
  "no-trial-ab-test": {
    variant: "control",
  },
};

export type FeatureFlagsKeys = keyof typeof DefaultFeatureFlagsValues | `canvas-element-${TypeCanvasElement}`;

export type FeatureFlagValue<K extends FeatureFlagsKeys> = typeof DefaultFeatureFlagsValues[K];
