import React from "react";
import { Path } from "react-konva";

export const GanttOpenTaskCard = ({ id }: { id: string }) => {
  return (
    <>
      <Path id={id} data="M4 1H7.9C7.95523 1 8 1.04477 8 1.1V5" stroke="#323338" strokeWidth={1} />
      <Path id={id} data="M5 8L1.1 8C1.04477 8 1 7.95523 1 7.9L1 4" stroke="#323338" strokeWidth={1} />
    </>
  );
};
