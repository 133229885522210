import Konva from "konva";
import React, { useEffect, useRef } from "react";
import { Group, Rect, Text, Line, Circle } from "react-konva";

type MindmapOrgChartGhostElementProps = {
  x: number;
  y: number;
};

export const MindmapOrgChartGhostElement = ({ x, y }: MindmapOrgChartGhostElementProps) => {
  const boxWidth = 133;
  const boxHeight = 87;
  const boxPadding = -50;
  const whiteBoxHeight = 40;

  // Positions for the boxes and lines
  const rootX = 0;
  const rootY = 0;
  const childY = 165;

  const childrenPositions = [
    rootX - (boxWidth + boxPadding) * 2, // Far left
    rootX - (boxWidth + boxPadding), // Left
    rootX, // Center
    rootX + (boxWidth + boxPadding), // Right
    rootX + (boxWidth + boxPadding) * 2, // Far right
  ];

  const rootWidth = 133;
  const rootHeight = 105;
  const rootWhiteBoxHeight = 44;

  return (
    <Group x={x} y={y}>
      <Rect x={rootX} y={rootY} width={rootWidth} height={rootWhiteBoxHeight} fill="white" stroke="#DADCE0" />
      <Rect
        x={rootX}
        y={rootY + rootWhiteBoxHeight}
        width={rootWidth}
        height={rootHeight - rootWhiteBoxHeight}
        fill="#DADCE0"
        stroke="#DADCE0"
      />
      <DotsLoader x={rootX - boxWidth / 4 - 15} y={rootY - rootWhiteBoxHeight - 40} />

      <Group x={10} y={rootHeight / 2 - rootWhiteBoxHeight + rootHeight / 8}>
        <Rect x={rootX + 10} y={rootY + rootWhiteBoxHeight} width={90} height={6} fill="white" />
        <Rect x={rootX + 17.5} y={rootY + rootWhiteBoxHeight + 10} width={75} height={6} fill="white" />
      </Group>

      {/* Child nodes */}
      {childrenPositions.map((childX, index) => (
        <Group key={index} x={childX} y={childY}>
          <Rect
            x={childX}
            y={childY - whiteBoxHeight}
            width={boxWidth}
            height={whiteBoxHeight}
            fill="white"
            stroke="#DADCE0"
          />
          <Rect
            x={childX}
            y={childY}
            width={boxWidth}
            height={boxHeight - whiteBoxHeight}
            fill="#DADCE0"
            stroke="#DADCE0"
          />
          <DotsLoader x={childX - boxWidth / 4 - 15} y={childY - boxHeight - whiteBoxHeight} />

          {/* Connecting lines */}
          <Line
            points={[
              -childX + boxWidth / 2,
              -childY + boxHeight + 10,

              rootX + rootWidth / 2,
              childY - 60,

              childX + boxWidth / 2,
              childY - boxHeight,

              childX + boxWidth / 2,
              childY - boxHeight / 2 + 3,
            ]}
            stroke="#DADCE0"
            strokeWidth={2}
            bezier
          />
        </Group>
      ))}
      <Text
        x={rootX - boxWidth + boxPadding}
        y={rootY + childY + boxHeight * 3}
        text="Building your org chart..."
        fontFamily="Poppins"
        fontSize={20}
        fill="black"
        align="center"
        verticalAlign="middle"
        width={childrenPositions[childrenPositions.length - 1] - childrenPositions[0] + boxWidth}
      />
    </Group>
  );
};

const DotsLoader = ({ x, y }: MindmapOrgChartGhostElementProps) => {
  const dotRefs = [useRef<Konva.Circle>(null), useRef<Konva.Circle>(null), useRef<Konva.Circle>(null)]; // Refs for the three dots

  useEffect(() => {
    const totalDuration = 1000;
    const overlapDuration = totalDuration / 6;

    const anim = new Konva.Animation((frame) => {
      if (!frame) return;

      const time = frame.time % totalDuration;

      dotRefs.forEach((dotRef, index) => {
        if (dotRef.current) {
          // Calculate start time for each dot based on index, with some overlap
          const start = index * overlapDuration;
          const end = start + totalDuration / 2; // Each dot fades in and out over half the total cycle

          // Determine the current opacity based on time within the dot's cycle
          if (time >= start && time <= end) {
            const progress = (time - start) / (totalDuration / 2); // Progress in the current phase (0 to 1)
            const opacity = Math.sin(progress * Math.PI); // Smooth fade in/out with sine wave
            // dotRef.current.opacity(opacity);
            dotRef.current.y(110 - opacity * 5); // Move the dot slightly to the right based on index
          } else if (time > end) {
            dotRef.current.y(110); // Move the dot slightly to the right based on index
          }
        }
      });
    }, dotRefs[0]?.current?.getLayer());

    anim.start();

    return () => {
      anim.stop();
    }; // Stop animation on unmount
  }, []);

  return (
    <Group x={x} y={y}>
      <Circle ref={dotRefs[0]} x={100} radius={3.5} fill="#DADCE0" />
      <Circle ref={dotRefs[1]} x={115} radius={3.5} fill="#DADCE0" />
      <Circle ref={dotRefs[2]} x={130} radius={3.5} fill="#DADCE0" />
    </Group>
  );
};
