import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let LineThickness = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
            <path
            fillRule="evenodd"
            d="M18 3H2V4H18V3ZM2 8H18V10H2V8ZM2 14H18V17H2V14Z"
            fill={color}
            />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

LineThickness.displayName = "LineThickness";

export { LineThickness };
