import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let ShapeRoundedRect = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M2 5.87097C2 3.73309 3.73309 2 5.87097 2H14.129C16.2669 2 18 3.73309 18 5.87097V14.129C18 16.2669 16.2669 18 14.129 18H5.87097C3.73309 18 2 16.2669 2 14.129V5.87097ZM5.87097 3.54839C4.58824 3.54839 3.54839 4.58824 3.54839 5.87097V14.129C3.54839 15.4118 4.58824 16.4516 5.87097 16.4516H14.129C15.4118 16.4516 16.4516 15.4118 16.4516 14.129V5.87097C16.4516 4.58824 15.4118 3.54839 14.129 3.54839H5.87097Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

ShapeRoundedRect.displayName = "ShapeRoundedRect";

export { ShapeRoundedRect };
