import React from "react";

export const GanttAddGroupIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" fill="none" viewBox="0 0 20 14">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.202 10.301H14.9V8.7h-3.698V5H9.599v3.699H5.9V10.3h3.7V14h1.602z"
        clipRule="evenodd"
      ></path>
      <path stroke="#fff" strokeWidth="1.5" d="M20 .75H0"></path>
    </svg>
  );
};
