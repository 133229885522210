import FormInputField from "frontend/ui-components/form-fields/form-input-field";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import useOutsideRef from "frontend/utils/click-outside-handler";
import { useSetAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { CanvasElement } from "shared/datamodel/schemas/canvas-element";
import { editingElementLinkAtom } from "state-atoms/stage-atoms";
import style from "./edit-element-link-modal.module.css";
import { sanitizeUrl } from "shared/util/sanitize-url";

export default function EditElementLinkModal({
  element,
  onChangeLink,
}: {
  element: CanvasElement;
  onChangeLink: (element: CanvasElement, link: string) => void;
}) {
  const [url, setURL] = useState(element.link ?? "");
  const [isValidURL, setIsValidURL] = useState(true);

  const ref = useRef<any>(null);
  const setEditingElementLink = useSetAtom(editingElementLinkAtom);

  useOutsideRef(ref, dismiss);

  useEffect(() => {
    if (url !== "") {
      const sanitizedUrl = sanitizeUrl(url);
      setIsValidURL(!!sanitizedUrl);
    }
    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [url]);

  function handleKeydown(e: any) {
    if (e.key === "Enter") {
      submit();
    }
  }

  function dismiss() {
    setEditingElementLink("");
  }

  function submit() {
    const sanitizedUrl = sanitizeUrl(url);
    if (sanitizedUrl) {
      setIsValidURL(true);
      onChangeLink(element, sanitizedUrl);
    } else {
      setIsValidURL(false);
      onChangeLink(element, "");
    }
    dismiss();
  }

  function deleteLink() {
    onChangeLink(element, "");
    dismiss();
  }

  function renderCloseIcon() {
    return (
      <svg
        className={style.closeIcon}
        onClick={dismiss}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="#848199"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <div className={style.container} ref={ref} >
      {renderCloseIcon()}
      <span className={style.title}>Link element</span>
      <FormInputField id="link" type="url" placeholder="Enter a link" value={url} onChange={setURL} error={isValidURL ? undefined : 'Invalid link'} autoFocus />
      <div className={style.buttons}>
        <div data-testid="Save">
        <StyledButton title="Save" onClick={submit} enabled={isValidURL && url !== ""}/>
        </div>
        <div data-testid="Delete link">
        <StyledButton title="Delete link" style={ButtonStyle.outline} onClick={deleteLink} />
        </div>

      </div>
    </div>
  );
}
