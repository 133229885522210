import { useRef } from "react";
import style from "./monday-status-picker.module.css";
import { useOnClickOutside } from "usehooks-ts";
import { getStatuses } from "../../../utils/monday-utils";

export default function MondayStatusPicker({
  onSelect,
  column,
  onDismiss,
}: {
  onSelect: (id: string) => void;
  column: any;
  onDismiss: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, onDismiss);

  function renderStatuses() {
    const statuses = getStatuses(column);
    return statuses.map(({ id, title, color }) => (
      <div key={id} className={style.status} style={{ backgroundColor: color }} onClick={() => onSelect(id)}>
        {title}
      </div>
    ));
  }

  return (
    <div ref={ref} className={style.container}>
      {renderStatuses()}
    </div>
  );
}
