

///////////////////////////////////////////////////////////////////////////////
// Type of functions
///////////////////////////////////////////////////////////////////////////////


type Mapper<T, U> = (t: T) => U;

type SimpleReducer<T, U = T> = (acc: U, cur: T) => U;
type ArrayReducer<T, U = T> = (acc: U, cur: T, curIndex: number, arr: T[]) => U;

type Predicate<T> = (item: T) => boolean;
type Compare<T> = (a: T, b: T) => number;


export const makeComparator = <T>(getKey: (t: T) => number) => (a: T, b: T) => getKey(a) - getKey(b);

export const sortBy =
  <T>(cmp: Compare<T>) =>
    (arr: T[]) =>
      arr.slice().sort(cmp);

function mapSet<T, U>(fn: Mapper<T, U>, set: Set<T>) {
  let result = new Set();
  for (const item of set) {
    result.add(fn(item));
  }
  return result;
}

function isIterable<T>(obj: any): obj is Iterable<T> {
  return obj !== null && typeof obj === "object" && Symbol.iterator in obj;
}

function* mapIterable<T, U>(fn: Mapper<T, U>, src: Iterable<T>) {
  for (const item of src) {
    yield fn(item);
  }
}

//=============================================================================
// https://medium.com/codex/currying-in-typescript-ca5226c85b85
//=============================================================================

type PartialParameters<FN extends (...args: any[]) => any> = PartialTuple<Parameters<FN>>;

type PartialTuple<TUPLE extends any[], EXTRACTED extends any[] = []> =
  // If the tuple provided has at least one required value
  TUPLE extends [infer NEXT_PARAM, ...infer REMAINING] ?
  // recurse back in to this type with one less item
  // in the original tuple, and the latest extracted value
  // added to the extracted list as optional
  PartialTuple<REMAINING, [...EXTRACTED, NEXT_PARAM?]> :
  // else if there are no more values,
  // return an empty tuple so that too is a valid option
  [...EXTRACTED, ...TUPLE];

type RemainingParameters<PROVIDED extends any[], EXPECTED extends any[]> =
  // if the expected array has any required items...
  EXPECTED extends [infer E1, ...infer EX] ?
  // if the provided array has at least one required item
  PROVIDED extends [infer P1, ...infer PX] ?
  // if the type is correct, recurse with one item less
  //in each array type
  P1 extends E1 ? RemainingParameters<PX, EX> :
  // else return this as invalid
  never :
  // else the remaining args is unchanged
  EXPECTED :
  // else there are no more arguments
  [];

type CurriedFunctionOrReturnValue<PROVIDED extends any[], FN extends (...args: any[]) => any> =
  RemainingParameters<PROVIDED, Parameters<FN>> extends [any, ...any[]] ?
  CurriedFunction<PROVIDED, FN> :
  ReturnType<FN>;

type CurriedFunction<PROVIDED extends any[], FN extends (...args: any[]) => any> =
  <NEW_ARGS extends PartialTuple<RemainingParameters<PROVIDED, Parameters<FN>>>> (...args: NEW_ARGS) =>
    CurriedFunctionOrReturnValue<[...PROVIDED, ...NEW_ARGS], FN>

export function curry<FN extends (...args: any[]) => any, STARTING_ARGS extends PartialParameters<FN>>(targetFn: FN, ...existingArgs: STARTING_ARGS):
  CurriedFunction<STARTING_ARGS, FN> {
  return function (...args) {
    const totalArgs = [...existingArgs, ...args]
    if (totalArgs.length >= targetFn.length) {
      return targetFn(...totalArgs)
    }
    return curry(targetFn, ...totalArgs as PartialParameters<FN>)
  }
}
//=============================================================================

// Defining pipe in a type-safe way
// taken from https://dev.to/ecyrbe/how-to-use-advanced-typescript-to-define-a-pipe-function-381h

type AnyFunc = (...arg: any) => any;

type PipeArgs<F extends AnyFunc[], Acc extends AnyFunc[] = []> = F extends [
  (...args: infer A) => infer B
]
  ? [...Acc, (...args: A) => B]
  : F extends [(...args: infer A) => any, ...infer Tail]
  ? Tail extends [(arg: infer B) => any, ...any[]]
  ? PipeArgs<Tail, [...Acc, (...args: A) => B]>
  : Acc
  : Acc;

type LastFnReturnType<F extends Array<AnyFunc>, Else = never> = F extends [
  ...any[],
  (...arg: any) => infer R
] ? R : Else;

export function pipe<FirstFn extends AnyFunc, F extends AnyFunc[]>(
  arg: Parameters<FirstFn>[0],
  firstFn: FirstFn,
  ...fns: PipeArgs<F> extends F ? F : PipeArgs<F>
): LastFnReturnType<F, ReturnType<FirstFn>> {
  return (fns as AnyFunc[]).reduce((acc, fn) => fn(acc), firstFn(arg));
}

//=============================================================================

export function map<T, U>(fn: Mapper<T, U>, src: Array<T>): Array<U>;
export function map<T, U>(fn: Mapper<T, U>, src: Iterable<T>): Iterable<U>;
export function map<T, U>(fn: Mapper<T, U>, src: Set<T>): Set<U>;
export function map<T, U>(fn: Mapper<T, U>, src: Record<string, T>): Record<string, U>;
export function map<T, U>(fn: Mapper<T, U>, src: unknown) {
  if (Array.isArray(src)) {
    return src.map(fn);
  }
  if (src instanceof Set) {
    return mapSet(fn, src);
  }
  if (isIterable(src)) {
    return { [Symbol.iterator]: mapIterable<T, U>(fn, src as Iterable<T>) };
  } else {
    const object = src as Record<string, any>;
    return Object.keys(object).reduce(function (result, key) {
      result[key] = fn(object[key])
      return result;
    }, {} as Record<string, U>);
  }
}

//=============================================================================

export const zip = (arr1: any[], arr2: any[]) => arr1.map((v, i) => ([v, arr2[i]]));

export const unique = <T,>(arr: T[]) => [...new Set(arr)];

//=============================================================================

export const reducerArray =
  <T, U = T>(fn: ArrayReducer<T, U>, init: U) =>
    (arr: Array<T>) =>
      arr.reduce(fn, init);

export const reducerIterable = <T, U>(fn: SimpleReducer<T, U>, init: U) => (coll: Iterable<T>) => {
  let acc = init;
  let iter = coll[Symbol.iterator]();
  let current = iter.next();
  if (acc == undefined) {
    if (current.done)
      return undefined;
    acc = current.value as any as U;
  }
  while (true) {
    current = iter.next();
    if (current.done) break;
    acc = fn(acc, current.value);
  }
  return acc;
}

export function reducer<T, U>(fn: SimpleReducer<T, U> | ArrayReducer<T, U>, init: U) {
  return function (arr: Iterable<T> | Array<T>) {
    if (Array.isArray(arr)) {
      return reducerArray(fn, init);
    } else {
      return reducerIterable(fn as SimpleReducer<T, U>, init);
    }
  }
}

export const curryParams = (fn: Function, partial: any) => {
  return (arg: any) => {
    return fn.call(this, Object.assign({}, partial, arg));
  };
};

export const slicer = (a?: number, b?: number) => <T>(x: T[]) => Array.prototype.slice.call(x, a, b)
export const take = (a: number) => <T>(arr: T[]) => Array.prototype.slice.call(arr, 0, a)
export const mapper = <T, U>(fn: Mapper<T, U>) => (collection: any) => map(fn, collection);
export const filterFn = <T,>(fn: Predicate<T>) => (collection: T[]) => collection.filter(fn);
export const scan = <T, U>(fn: (acc: U, cur: T) => U, init: U, arr: T[]) =>
  arr.reduce((acc, cur) => {
    acc.push(fn(acc[acc.length - 1], cur));
    return acc;
  }, [init]);


export const off = (value: number, mask: number) => (value & (~mask));
export const on = (value: number, mask: number) => (value | mask);
export const isOn = (value: number, mask: number) => (value & mask) == mask;

export const unary = <T, U>(fn: (a: T) => U) => ((arg: T) => fn(arg));
export const all = <T>(pred: Predicate<T>) => (arr: T[]) => arr.every(pred);
export const any = <T>(pred: Predicate<T>) => (arr: T[]) => arr.some(pred);
export const not = <T>(pred: Predicate<T>) => (x: T) => !pred(x);
export const and = <T>(...fns: Predicate<T>[]) => (x: T) => fns.every(fn => fn(x));
export const or = <T>(...fns: Predicate<T>[]) => (x: T) => fns.some(fn => fn(x));

export const prop = (x: string | symbol) => (y: Record<string | symbol, any>) => y[x];
export const propExists = (x: string | symbol) => (y: Record<string | symbol, any>) => x in y;

export const noop = () => { };

export const ensureArray = <T>(x: T | T[] | IterableIterator<T>) => Array.isArray(x) ? x : isIterable(x) ? [...x] : [x];


class Range {
  constructor(public min: number, public max: number, public step = 1) { }

  public *[Symbol.iterator]() {
    for (let i = this.min; i < this.max; i += this.step)
      yield i;
  }
  public map<T>(fn: (a: number) => T) {
    let arr: T[] = [];
    for (let i = this.min; i < this.max; i += this.step)
      arr.push(fn(i));
    return arr;
  }
  public flatMap<T>(fn: (a: number) => T[]) {
    let arr: T[] = [];
    for (let i = this.min; i < this.max; i += this.step)
      arr.push(...fn(i));
    return arr;
  }
  public toArray() {
    return this.map(x => x);
  }
}

export const range = (min: number, max: number, step = 1) => (new Range(min, max, step));


export function minIndexBy<T>(fn: (a: T) => number, arr: Iterable<T>): [T, number];
export function minIndexBy<T>(fn: (a: T) => number): (arr: Iterable<T>) => [T, number];
export function minIndexBy<T>(fn: (a: T) => number, arr?: Iterable<T>) {
  if (arr == undefined) {
    return (arr: Iterable<T>) => minIndexBy(fn, arr);
  }
  let index = 0, bestIndex = 0, bestValue = Infinity, bestItem: T;
  for (const item of arr) {
    let cur = fn(item);
    if (bestValue == Infinity || cur < bestValue) {
      bestValue = cur;
      bestItem = item;
      bestIndex = index;
    }
    index++;
  }
  return [bestItem!, bestIndex];
}

export function minItemBy<T>(fn: (a: T) => number, obj: Record<string, T>): [number, string] {
  let minValue = Infinity;
  let bestKey: string = '';
  for (const key in obj) {
    const value = fn(obj[key]);
    if (value < minValue) {
      minValue = value;
      bestKey = key;
    }
  }
  return [minValue, bestKey];
}

export function isBetween(low: number, high: number, n?: number | null) {
  if (arguments.length == 2) {
    return (n: number) => isBetween(low, high, n);
  }
  return (n != null && low <= n && n <= high);
}

export const partition = <T>(pred: Predicate<T>) => <V extends T>(arr: V[]) => {
  const result: [V[], V[]] = [[], []];
  arr.forEach(x => result[pred(x) ? 0 : 1].push(x));
  return result; // [passed,failed]
}

export function initArray<T>(length: number, init: (n: number) => T): T[] {
  return Array.from({ length }, (v, k) => init(k));
}

type GetEventHandlers<T extends keyof JSX.IntrinsicElements> = Extract<keyof JSX.IntrinsicElements[T], `on${string}`>;
export type EventFor<
  TElement extends keyof JSX.IntrinsicElements,
  THandler extends GetEventHandlers<TElement>
  > = JSX.IntrinsicElements[TElement][THandler] extends ((e: infer TEvent) => any) | undefined ? TEvent : never;
// Example for using the above type: const onkeydown = (e: EventFor<'input', 'onKeyDown'>) => { ... }


export function deepEqual(x: any, y: any): boolean {
  //@ts-ignore
  return x && y && typeof x === "object" && typeof y === "object"
    ? Object.keys(x).length === Object.keys(y).length &&
    //@ts-ignore
    Object.keys(x).reduce((isEqual, key) => isEqual && deepEqual(x[key], y[key]), true)
    : x === y;
}