import { useRef, useMemo, useLayoutEffect } from "react";
import { Shape } from "react-konva";

export default function PolygonElement({
  id,
  sides,
  radius,
  scaleX = 1,
  scaleY = 1,
  fill,
  strokeEnabled,
  stroke,
  strokeWidth = 0,
  dash,
  dashEnabled,
  lineCap,
}: {
  id?: string;
  sides: number;
  radius: number;
  scaleX?: number;
  scaleY?: number;
  fill: string;
  strokeEnabled: boolean;
  stroke?: string;
  strokeWidth?: number;
  dash?: number[];
  dashEnabled?: boolean;
  lineCap?: "butt" | "round" | "square";
}) {
  const ref = useRef<any>(null);

  const [points, selfRect] = useMemo(() => {
    const result = [];
    const radiusX = radius * scaleX;
    const radiusY = radius * scaleY;
    let minX = Number.MAX_VALUE,
      minY = Number.MAX_VALUE,
      maxX = Number.MIN_VALUE,
      maxY = Number.MIN_VALUE;
    for (var n = 0; n < sides; n++) {
      const point = {
        x: radiusX * Math.sin((n * 2 * Math.PI) / sides),
        y: -1 * radiusY * Math.cos((n * 2 * Math.PI) / sides),
      };
      result.push(point);
      minX = Math.min(minX, point.x);
      maxX = Math.max(maxX, point.x);
      minY = Math.min(minY, point.y);
      maxY = Math.max(maxY, point.y);
    }
    const selfRect = {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
    return [result, selfRect];
  }, [sides, radius, scaleX, scaleY]);

  useLayoutEffect(() => {
    if (ref && ref.current) {
      ref.current.getSelfRect = () => selfRect;
    }
  }, [ref.current, selfRect]);

  return (
    <Shape
      id={id}
      fill={fill}
      stroke={stroke}
      strokeEnabled={strokeEnabled}
      strokeWidth={strokeWidth}
      ref={ref}
      scaleX={1 / scaleX} // already in the drawing
      scaleY={1 / scaleY}
      dashEnabled={dashEnabled}
      dash={dash}
      lineCap={lineCap}
      sceneFunc={(context, shape) => {
        context.beginPath();
        context.moveTo(points[0].x, points[0].y);
        for (var n = 1; n < points.length; n++) {
          context.lineTo(points[n].x, points[n].y);
        }
        context.closePath();
        context.fillStrokeShape(shape);
      }}
    />
  );
}
