
export const SelectionRectStroke = "#00A1FF";
export const SelectionRectFill = "#00A1FF1A";

export const SelectedElementOutline = "#00A1FF";
export const SelectedElementOutlineWidth = 1;

export const ShapeSocketColor = "#1973FF";

export const Fonts = ["Poppins", "Open Sans", "Ubuntu", "Oswald", "Lora", "Playfair Display", "DM Mono", "Caveat"];
