import { format, isSameDay, isSameMonth, isSaturday, isSunday } from "date-fns";
import * as locales from "date-fns/locale";
import React, { useEffect, useState } from "react";
import lightStyles from "./light-date-picker-range.module.css";
import darkStyles from "./dark-date-picker-range.module.css";
import cn from "classnames";
import { ChevronLeft, ChevronRight } from "frontend/icons/chevron";
import { DPDay, useDatePicker } from "@rehookify/datepicker";

function getLocale() {
  const locale = navigator.language || "en-US";
  const normalizedLocale = locale.split("-").join("");

  if (locales.hasOwnProperty(normalizedLocale)) {
    return (locales as any)[normalizedLocale];
  }
  return locales.enUS;
}

export function DatePickerRange({
  fromDate,
  toDate,
  onSelected,
  theme = "light",
  showActions = "none",
  onClear,
  onApply,
}: {
  fromDate?: Date;
  toDate?: Date;
  onSelected?: (fromDate: Date, toDate?: Date) => void;
  theme?: "light" | "dark";
  showActions?: "clear" | "apply" | "both" | "none";
  onClear?: () => void;
  onApply?: (fromDate?: Date, toDate?: Date) => void;
}) {
  const styles = theme === "light" ? lightStyles : darkStyles;

  const [selectedDates, onDatesChange] = useState<Date[]>([fromDate ?? new Date(), toDate ?? new Date()]);
  const [offsetDate, onOffsetChange] = useState<Date>(new Date());

  const {
    data: { weekDays, calendars, months },
    propGetters: { dayButton, addOffset, subtractOffset },
  } = useDatePicker({
    selectedDates,
    onDatesChange,
    offsetDate,
    onOffsetChange,
    dates: { toggle: true, mode: "range" },
  });
  const locale = getLocale();
  const { days } = calendars[0];

  const showClear = showActions === "clear" || showActions === "both";
  const showApply = showActions === "apply" || showActions === "both";
  const [start, end] = selectedDates;

  const curerntMonth = months.find((m) => m.active)?.$date || new Date();

  useEffect(() => {
    onSelected?.(selectedDates[0], selectedDates[1]);
  }, [selectedDates]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button
          {...subtractOffset({
            months: 1,
          })}
        >
          <ChevronLeft color={theme === "light" ? "#1973FF" : "#599AFF"} />
        </button>
        {format(curerntMonth, "LLLL yyyy", { locale })}
        <button
          {...addOffset({
            months: 1,
          })}
        >
          <ChevronRight color={theme === "light" ? "#1973FF" : "#599AFF"} />
        </button>
      </div>

      <div className={styles.hr} />

      <div className={styles.calendar}>
        {weekDays.map((name, index) => (
          <div key={index} className={styles.dayName}>
            {name}
          </div>
        ))}
        {days.map((day: DPDay) => (
          <button
            key={day.$date.toDateString()}
            className={cn(styles.day, {
              [styles.isSunday]: isSunday(day.$date),
              [styles.isSaturday]: isSaturday(day.$date),

              [styles.isOutOfCurrentMonth]: !isSameMonth(day.$date, curerntMonth),
              [styles.inRange]: start && end && day.$date > start && day.$date < end,
              [styles.selected]: (start && isSameDay(day.$date, start)) || (end && isSameDay(day.$date, end)),
              [styles.rangeStart]: start && isSameDay(day.$date, start),
              [styles.rangeEnd]: end && isSameDay(day.$date, end),
              [styles.startAndEndDate]: start && isSameDay(day.$date, start) && end && isSameDay(day.$date, end),
            })}
            data-date={day.$date.toISOString()}
            {...dayButton(day)}
          >
            {format(day.$date, "d", { locale })}
          </button>
        ))}
      </div>
      <div className={styles.calendarActions}>
        {showClear && (
          <button
            className={styles.calendarAction}
            onClick={() => {
              onDatesChange([]);
              onClear?.();
            }}
          >
            Clear
          </button>
        )}
        {showApply && (
          <button
            className={styles.calendarAction}
            onClick={() => {
              onApply?.(start, end);
            }}
          >
            Apply
          </button>
        )}
      </div>
    </div>
  );
}
