import DatePicker from "frontend/ui-components/date-picker";
import useOutsideRef from "frontend/utils/click-outside-handler";
import style from "./monday-date-picker.module.css";
import { useRef } from "react";
import { format } from "date-fns";

export default function MondayDatePicker({
  onSelect,
  onDismiss,
  selected,
}: {
  onSelect: (date: string) => void;
  onDismiss: () => void;
  selected?: Date | undefined;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useOutsideRef(ref, onDismiss);

  return (
    <div className={style.container} ref={ref}>
      <DatePicker onSelected={(date) => onSelect(format(date, "yyyy-MM-dd"))} selected={selected} />
    </div>
  );
}
