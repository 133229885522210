const defaultText = "Unset";
const defaultColor = "#797e93";

export function getStatuses(column: any) {
  const settings = column.settings_str ? JSON.parse(column.settings_str) : {};
  const positions: { [labelId: string]: number } = settings.labels_positions_v2;
  let orderedStatusIndexes: [string, any][] = [];
  if (positions) {
    orderedStatusIndexes = Object.entries(positions).sort(([, index1], [, index2]) => index1 - index2);
  } else {
    orderedStatusIndexes = Object.entries(settings.labels);
  }
  const statuses = orderedStatusIndexes.map(([labelId]) => {
    const { color = defaultColor } = settings.labels_colors[labelId] ?? {};
    return {
      id: labelId,
      title: settings.labels[labelId] ?? defaultText,
      color,
    };
  });
  return statuses;
}

export function getSelectedStatus(columnValues: any, column: any) {
  let status = columnValues?.find((columnValue: any) => columnValue.id === column?.id)?.value;
  if (!status?.text || !status?.color) {
    status = { text: defaultText, color: defaultColor };
  }
  return status;
}
