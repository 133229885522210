import { MindmapNodeDirection, MindmapNodeElement, MindmapNodeOrientation } from "shared/datamodel/schemas/mindmap";
import { getTransformParams } from "./node-utils";
import consts from "shared/consts";
import Transform, { CanvasElementBox, Degrees, PointAndDirection } from "./transform";
import { CursorType } from "frontend/canvas-designer-new/cursor-type";
import { FlextreeNode, flextree } from "d3-flextree";

type AnchorPoints = {
  left?: PointAndDirection;
  right?: PointAndDirection;
  top?: PointAndDirection;
  bottom?: PointAndDirection;
};

function makePoint(x: number, y: number, rotation: number): PointAndDirection {
  return { x, y, rotation: rotation as Degrees };
}

function getAvailablePoints(element: MindmapNodeElement, orientation?: MindmapNodeOrientation): AnchorPoints {
  const allPoints = {
    left: makePoint(0, 0.5, 180),
    right: makePoint(1, 0.5, 0),
    top: makePoint(0.5, 0, 270),
    bottom: makePoint(0.5, 1, 90),
  };
  if (element.parentId) {
    if (element.direction === MindmapNodeDirection.TRAILING && orientation === MindmapNodeOrientation.HORIZONTAL) {
      return {
        bottom: allPoints.bottom,
      };
    } else if (element.direction === MindmapNodeDirection.TRAILING && orientation === MindmapNodeOrientation.VERTICAL) {
      return {
        right: allPoints.right,
      };
    } else if (
      element.direction === MindmapNodeDirection.LEADING &&
      orientation === MindmapNodeOrientation.HORIZONTAL
    ) {
      return {
        top: allPoints.top,
      };
    } else if (element.direction === MindmapNodeDirection.LEADING && orientation === MindmapNodeOrientation.VERTICAL) {
      return {
        left: allPoints.left,
      };
    }
  } else if (orientation === MindmapNodeOrientation.HORIZONTAL) {
    return {
      top: allPoints.top,
      bottom: allPoints.bottom,
    };
  } else if (orientation === MindmapNodeOrientation.VERTICAL) {
    return {
      left: allPoints.left,
      right: allPoints.right,
    };
  }
  return allPoints;
}

function transformAnchorPointsInPlace(tr: CanvasElementBox | Transform, points: AnchorPoints): AnchorPoints {
  if (!(tr instanceof Transform)) {
    tr = new Transform(tr.x, tr.y, tr.width, tr.height, tr.rotate);
  }
  points.top && tr.transformPointAndDirection(points.top, points.top);
  points.bottom && tr.transformPointAndDirection(points.bottom, points.bottom);
  points.left && tr.transformPointAndDirection(points.left, points.left);
  points.right && tr.transformPointAndDirection(points.right, points.right);
  return points;
}

export function getAnchorPoints(element: MindmapNodeElement, orientation?: MindmapNodeOrientation): AnchorPoints {
  const transform = getTransformParams(consts.CANVAS_ELEMENTS.MINDMAP, element);
  const points = getAvailablePoints(element, orientation);
  return transformAnchorPointsInPlace(transform, points);
}

export type MindmapNodeTree = MindmapNodeElement & {
  id: string;
  children: MindmapNodeTree[];
  descendantsCount?: number;
};

export function getNodesWithPositions(
  root: MindmapNodeTree,
  nodesSizes: { [id: string]: { width: number; height: number } }
): MindmapNodeTree {
  const layout = flextree<MindmapNodeTree>({
    // if collapsed, this will make sure that the children are not calculated
    children: (data) => (data.collapsed ? [] : data.children),
    nodeSize: (node) => {
      let { id, parentId } = node.data;
      let { width = 0, height = 0 } = nodesSizes[id] ?? {};
      if (root.orientation === MindmapNodeOrientation.VERTICAL) {
        if (!parentId) {
          width /= 2;
        }
        return [height, width + 100];
      }
      return [width, height + 100];
    },
  });
  const leadingChildren = root.children.filter((child) => child.direction === MindmapNodeDirection.LEADING);
  const trailingChildren = root.children.filter((child) => child.direction === MindmapNodeDirection.TRAILING);
  const leadingTree = layout.hierarchy({ ...root, children: leadingChildren });
  layout(leadingTree);
  const trailingTree = layout.hierarchy({ ...root, children: trailingChildren });
  layout(trailingTree);
  const rootNode = convertTreeToNodes(trailingTree, root.orientation, nodesSizes, false);
  const leadingNode = convertTreeToNodes(leadingTree, root.orientation, nodesSizes, true);
  return {
    ...rootNode,
    children: [...rootNode.children, ...leadingNode.children],
  };
}

export function convertTreeToNodes(
  tree: FlextreeNode<MindmapNodeTree>,
  orientation: MindmapNodeOrientation,
  nodesSizes: { [id: string]: { width: number; height: number } },
  isLeading: boolean,
  offset = 0
): MindmapNodeTree {
  let {
    x,
    y,
    data: { id, parentId },
  } = tree;
  let { width = 0, height = 0 } = nodesSizes[id] ?? {};
  if (orientation === MindmapNodeOrientation.VERTICAL) {
    if (isLeading) {
      [x, y] = [-y, x];
      x -= width;
      y -= height / 2;
    } else {
      [x, y] = [y, x];
      y -= height / 2;
      if (!parentId) {
        x -= width / 2;
      }
    }
  } else {
    x -= width / 2;
    if (isLeading) {
      y = -y - height - offset;
    } else {
      y -= height / 2;
    }
  }

  // offset to align center, on vertical orientation, the root node is not centered
  const newOffset = offset || (isLeading && !parentId ? -height : 0);

  return {
    ...tree.data,
    orientation, // this is the orientation of the root node
    x,
    y,
    width,
    height,
    children:
      // even if the node have no children (because it's probably collapsed), we'll still keep the children array
      tree.children?.map((child) => convertTreeToNodes(child, orientation, nodesSizes, isLeading, newOffset)) ??
      tree.data.children,
  };
}

export function buildNodesTree({
  rootId,
  root,
  childrenMap,
  allNodes,
}: {
  rootId: string;
  root: MindmapNodeElement;
  childrenMap: { [parentId: string]: string[] };
  allNodes: { [id: string]: MindmapNodeElement };
}): MindmapNodeTree {
  const children = childrenMap[rootId] || [];
  const childNodes = children.map((id) => buildNodesTree({ rootId: id, root: allNodes[id], childrenMap, allNodes }));
  childNodes.sort((a, b) => a.sortIndex - b.sortIndex);
  return {
    ...root,
    id: rootId,
    children: childNodes,
    descendantsCount: childNodes.reduce((acc, child) => acc + (child.descendantsCount ?? 0) + 1, 0),
  };
}

export function calculateNodeConnectorAnchor(node: MindmapNodeTree, orientation: MindmapNodeOrientation) {
  const isLeading = node.direction === MindmapNodeDirection.LEADING;
  if (orientation === MindmapNodeOrientation.VERTICAL) {
    if (isLeading) {
      return { x: node.x + node.width, y: node.y + node.height / 2 };
    }
    return { x: node.x, y: node.y + node.height / 2 };
  }
  if (isLeading) {
    return { x: node.x + node.width / 2, y: node.y + node.height };
  }
  return { x: node.x + node.width / 2, y: node.y };
}

export function calculateParentConnectorAnchor(
  node: MindmapNodeTree,
  orientation: MindmapNodeOrientation,
  isLeading: boolean,
  padding: number = 0
) {
  if (orientation === MindmapNodeOrientation.VERTICAL) {
    if (isLeading) {
      return { x: node.x - padding - 1, y: node.y + node.height / 2 };
    }
    return { x: node.x + node.width + padding + 1, y: node.y + node.height / 2 };
  }
  if (isLeading) {
    return { x: node.x + node.width / 2, y: node.y - padding - 1 };
  }
  return { x: node.x + node.width / 2, y: node.y + node.height + padding + 1 };
}

export function getNodeDescendants(node: MindmapNodeTree): MindmapNodeTree[] {
  return node.children.reduce((acc, child) => [...acc, child, ...getNodeDescendants(child)], [] as MindmapNodeTree[]);
}

export function findNode(root: MindmapNodeTree, id: string): MindmapNodeTree | undefined {
  if (root.id === id) {
    return root;
  }
  for (let child of root.children) {
    const node = findNode(child, id);
    if (node) {
      return node;
    }
  }
}