import { CanvasElement } from "shared/datamodel/schemas";
import pointAt from "./pointAt";
import type { TypeCanvasElement } from "shared/consts";
import { Radians, toDegrees } from "frontend/utils/transform";

const normalAt = (type: TypeCanvasElement, element: CanvasElement) => {
  const p = pointAt(type, element);
  return (t: number) => {
    const p1 = p(t - 0.01), p2 = p(t + 0.01);
    let x = p2.x - p1.x, y = p2.y - p1.y; // [x,y] is tangent vector
    // normal is tangent rotated 90 => [y, -x]
    return toDegrees(Math.atan2(-x, y) as Radians);
  }
}

/*
examples for how to compute normals directly for some shapes:

// assuming uv is a point for an ideal rect (from 0,0 to 1,1), returns the normal vector at that point
function normal_rect(uv: Point) {
  if (abs(uv.x) < 0.001) {
    return { x: -1, y: 0 }
  } else if (abs(uv.x - 1) < 0.001) {
    return { x: 1, y: 0 }
  } else if (abs(uv.y) < 0.001) {
    return { x: 0, y: -1 }
  } else {
    return { x: 0, y: 1 }
  }
}

function normalForRegularPolygon(sides: number, uv: Point) {
  let a = atan2(uv.y, uv.x);
  // our regular polygon routine puts vertex 0 at pi/2 angle, and it's much more convient to work in that
  // reference frame. So I'm offseting 'a' by pi/2, and returning it at the end.
  a += HALF_PI;
  const b = sides * a / TWO_PI;
  const c = (Math.floor(b) + Math.ceil(b)) / 2;
  const d = c * TWO_PI / sides - HALF_PI;
  return { x: Math.cos(d), y: Math.sin(d) };
}

*/

export default normalAt;