import { useAtomValue } from "jotai";
import React from "react";
import { Group, Image, Rect, Text } from "react-konva";
import { useImage } from "react-konva-utils";
import { isExportingAtom } from "state-atoms";

type SomeImageProps = {
  src: string;
  size?: { width: number; height: number };
  cornerRadius?: number;
  clipPosition?: "center-middle" | "top-left" | "aspect-fit";
  showLoader?: boolean;
};
const SomeImage = React.forwardRef(
  ({ src, size, cornerRadius, clipPosition = "center-middle", showLoader = true }: SomeImageProps, ref: any) => {
    const [img, state] = useImage(src);
    const imgSize = size ?? (img ? { width: img.width, height: img.height } : { width: 0, height: 0 });
    const isExportingCanvas = useAtomValue(isExportingAtom);
    const shouldRenderImage = !isExportingCanvas || !src.match(/http(s)?:\/\//);

    function getImageCrop(image: HTMLImageElement, size: { width: number; height: number }) {
      const ratio = image.width / image.height;
      const newRatio = size.width / size.height;

      if (clipPosition === "aspect-fit") {
        if (ratio < 1) {
          const height = size.height;
          const width = height * ratio;
          return { x: (size.width - width) / 2, width, height };
        } else {
          const width = size.width;
          const height = width / ratio;
          return { y: (size.height - height) / 2, width, height };
        }
      }

      let newWidth;
      let newHeight;
      if (ratio < newRatio) {
        newWidth = image.width;
        newHeight = image.width / newRatio;
      } else {
        newWidth = image.height * newRatio;
        newHeight = image.height;
      }

      let x = 0,
        y = 0;
      switch (clipPosition) {
        case "center-middle":
          x = (image.width - newWidth) / 2;
          y = (image.height - newHeight) / 2;
          break;
        case "top-left":
          x = 0;
          y = 0;
          break;
      }

      return { cropX: x, cropY: y, cropWidth: newWidth, cropHeight: newHeight };
    }

    return (
      <Group
        ref={ref}
        clipFunc={(ctx: CanvasRenderingContext2D) => {
          if (cornerRadius) {
            const w = imgSize.width,
              h = imgSize.height,
              r = Math.min(cornerRadius, w / 2, h / 2);

            if ((ctx as any).roundRect) {
              (ctx as any).roundRect(0, 0, imgSize.width, imgSize.height, r);
            } else {
              ctx.beginPath();
              ctx.moveTo(r, 0);
              ctx.arcTo(w, 0, w, h, r);
              ctx.arcTo(w, h, 0, h, r);
              ctx.arcTo(0, h, 0, 0, r);
              ctx.arcTo(0, 0, w, 0, r);
              ctx.closePath();
            }
          } else {
            ctx.rect(0, 0, imgSize.width, imgSize.height);
          }
        }}
      >
        {shouldRenderImage && img ? (
          <>
            <Rect {...imgSize} />
            <Image image={img} {...imgSize} {...getImageCrop(img, imgSize)} />
          </>
        ) : (
          <Rect {...imgSize} fill="#EBEDF3" />
        )}
        {showLoader &&
          state === "loading" &&
          src.match(/http(s)?:\/\//) && ( // show loader only if external file
            <Text
              text={"Loading Image..."}
              fontSize={20}
              fill="#848199"
              x={imgSize.width / 2 - 70}
              y={imgSize.height / 2 - 10}
            />
          )}
      </Group>
    );
  }
);

export default SomeImage;
