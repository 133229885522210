import React, { CSSProperties, useEffect } from "react";
import { IRect } from "frontend/utils/math-utils";
import { Text } from "react-konva";
import { Html } from "react-konva-utils";
import { TextFieldHeight } from "./orgchart-element";
import Konva from "konva";
import { useSetAtom } from "jotai";
import { canvasTooltipAtom } from "state-atoms/stage-atoms";

export type OrgChartNodeTextStyles = "h1" | "h2" | "default";

const DEFAULT_TEXT_COLOR = "#113255";

export const OrgChartNodeTextStyles: Record<OrgChartNodeTextStyles, any> = {
  h1: {
    fontFamily: "Poppins",
    align: "center",
    verticalAlign: "middle",
    fontSize: 12,
    fontStyle: 700,
    wrap: "none",
    ellipsis: true,
    perfectDrawEnabled: false,
  },
  h2: {
    fontFamily: "Poppins",
    align: "center",
    verticalAlign: "middle",
    fontSize: 11,
    fontStyle: 400,
    wrap: "none",
    ellipsis: true,
    perfectDrawEnabled: false,
  },
  default: {
    fontFamily: "Poppins",
    align: "center",
    verticalAlign: "middle",
    fontSize: 10,
    fontStyle: 400,
    wrap: "none",
    ellipsis: true,
    perfectDrawEnabled: false,
  },
};

const CommonTextStyleForHtmlInput: CSSProperties = {
  outline: "none",
  border: "none",
  margin: 0,
  padding: 0,
  width: "100%",
  height: "100%",
  verticalAlign: "middle",
  background: "unset",
  textAlign: "center",
};

export const OrgChartNodeTextStylesHtml: Record<OrgChartNodeTextStyles, CSSProperties> = {
  h1: {
    ...CommonTextStyleForHtmlInput,
    fontSize: "12px",
    fontWeight: 700,
  },
  h2: {
    ...CommonTextStyleForHtmlInput,
    fontSize: "11px",
    fontWeight: 400,
  },
  default: {
    ...CommonTextStyleForHtmlInput,
    textAlign: "center",
    fontSize: "10px",
    fontWeight: 400,
    color: DEFAULT_TEXT_COLOR,
  },
};

export function OrgchartSimpleInputField({
  initialValue,
  listening,
  isEditing,
  setIsEditing,
  rect,
  fieldTitle,
  textStyleId = "default",
  textColor,
  onFinish,
}: {
  initialValue: string;
  listening: boolean;
  isEditing: boolean;
  setIsEditing: (state: boolean) => void;
  rect: IRect;
  fieldTitle: string;
  onFinish: (initial: string, final: string) => void;
  textStyleId?: OrgChartNodeTextStyles;
  textColor: string;
}) {
  let yOffset = 0;
  switch (textStyleId) {
    case "h1":
      yOffset = 2.5;
      break;
    default:
      yOffset = 1;
      break;
  }
  let fill: string, opacity: number;
  if (textStyleId != "default") {
    fill = textColor;
    opacity = !!initialValue ? 1 : 0.5;
  } else {
    fill = !!initialValue ? DEFAULT_TEXT_COLOR : "#848199";
    opacity = 1;
  }

  return isEditing ? (
    <Html groupProps={{ x: rect.x, y: rect.y - yOffset }}>
      <div
        style={{
          display: "grid",
          width: rect.width,
          height: rect.height,
          fontFamily: "Poppins",
        }}
      >
        <input
          style={{ color: textColor, ...OrgChartNodeTextStylesHtml[textStyleId] }}
          ref={(e) => e?.focus()}
          defaultValue={initialValue}
          onFocus={(e) => e.currentTarget.select()}
          onBlur={(e) => {
            setIsEditing(false);
            onFinish(initialValue, e.currentTarget.value.trim());
          }}
          onKeyUp={(e) => {
            if (e.key == "Enter") {
              setIsEditing(false);
              onFinish(initialValue, e.currentTarget.value.trim());
            }
            if (e.key == "Escape") {
              setIsEditing(false);
            }
          }}
        />
      </div>
    </Html>
  ) : (
    <Text
      x={rect.x}
      y={rect.y}
      width={rect.width}
      height={rect.height}
      fill={fill}
      opacity={opacity}
      {...OrgChartNodeTextStyles[textStyleId]}
      text={initialValue || fieldTitle}
      isFixedHeight={true}
      onClick={() => setIsEditing(true)}
      listening={listening}
      // tooltip={(e: any) => (e.target.text() != e.target._partialText ? e.target.text() : null)}
      // onDblClick={() => setIsEditing && setIsEditing(true)}
    />
  );
}

export function OrgChartViewOnlyTextField({
  initialValue,
  rect,
  fieldTitle,
  textStyleId = "default",
  textColor,
}: {
  initialValue: string;
  rect: IRect;
  fieldTitle: string;
  textStyleId?: OrgChartNodeTextStyles;
  textColor: string;
}) {
  let fill: string, opacity: number;
  if (textStyleId != "default") {
    fill = textColor;
    opacity = !!initialValue ? 1 : 0.5;
  } else {
    fill = !!initialValue ? DEFAULT_TEXT_COLOR : "#848199";
    opacity = 1;
  }
  return (
    <Text
      x={rect.x}
      y={rect.y}
      width={rect.width}
      height={rect.height}
      fill={fill}
      opacity={opacity}
      {...OrgChartNodeTextStyles[textStyleId]}
      text={initialValue || fieldTitle}
      isFixedHeight={true}
      listening={false}
      // tooltip={(e: any) => (e.target.text() != e.target._partialText ? e.target.text() : null)}
    />
  );
}

function isString(s: unknown): s is string {
  return typeof s == "string";
}

function TextLineWithTooltip(props: Konva.TextConfig & { tooltip: string | ((e: any) => string) }) {
  const setTooltip = useSetAtom(canvasTooltipAtom);
  let { tooltip, ...rest } = props;
  let onMouseEnter = (e: any) => {
    const msg = isString(tooltip) ? tooltip : tooltip(e);
    if (msg) {
      setTooltip({ label: msg, relativeTo: e.target });
    }
  };
  let onMouseLeave = () => setTooltip(null);
  useEffect(() => onMouseLeave, []);
  return <Text {...rest} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} listening={true} />;
}
