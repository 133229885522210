import { TransformHooks } from "frontend/hooks/use-transform-hooks";
import Modal from "frontend/modal/modal";
import EditElementLinkModal from "frontend/modals/edit-element-link-modal";
import { Point } from "frontend/utils/math-utils";
import { ContainerConfig } from "konva/types/Container";
import React, { useMemo } from "react";
import { Group } from "react-konva";
import { Html } from "react-konva-utils";
import consts, { TypeCanvasElement } from "shared/consts";
import { CanvasElement } from "shared/datamodel/schemas";
import { CanvasElementLinkBadge } from "../canvas-element-link-badge";
import { linkBadgePosition } from "./canvas-elements-utils";
import { isElementConnectable, isElementLinkBadgeSupporting } from "elements/bridge";
import { CanvasElementFlags } from "frontend/types";

export const votableElement: CanvasElementFlags = {
  shape: true,
  connector: null,
  textBlock: true,
  drawing: true,
  stickyNote: true,
  file: true,
  taskCard: null,
  frame: null,
  comment: null,
  mindmap: true,
  mindmapOrgChart: true,
  integrationItem: true,
  cardStack: false,
  orgChartNode: false,
  orgChartRoot: false,
  liveIntegration: false,
  timeline: false,
  table: false,
  tableCell: false,
  gantt: false,
};

type BaseCanvasElementProps = React.PropsWithChildren<
  {
    id: string;
    type: TypeCanvasElement;
    element: CanvasElement;
    x: number;
    y: number;
    onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
    isSelectable: boolean;
    isEditingLink: boolean;
    isDraggable?: boolean;
    mutation?: any;
    changeElement: (
      props: any,
      undoConfig: { shouldAdd: boolean; previousProps?: any },
      updateSubMenuData?: any, //take the last change and make it the default when selecting this element again from the menu (e.g. make the default color the last color selected)
      elementId?: string
    ) => void;
  } & Omit<ContainerConfig, "x" | "y" | "scaleX" | "scaleY" | "id" | "name">
>;

export default function BaseCanvasElement(props: BaseCanvasElementProps) {
  const {
    id,
    type,
    element,
    x,
    y,
    onResize,
    isEditingLink,
    changeElement,
    mutation: _mutation,
    children,
    isDraggable = true,
    ...otherProps
  } = props;
  const linkBadgePos = linkBadgePosition(element, type);
  const mutation1 = useMemo(() => new TransformHooks(id, onResize), [id]);
  const mutation = _mutation ?? mutation1;
  return (
    <>
      <Group
        id={id}
        name={id}
        type={type}
        element={element}
        x={x}
        y={y}
        scaleX={element.scaleX}
        scaleY={element.scaleY}
        rotation={(element as any).rotate ?? 0}
        isCanvasElement={true}
        isConnectable={isElementConnectable(type)}
        isConnector={type == "connector"}
        isDraggable={isDraggable}
        isFrame={type == "frame"}
        isTaskConvertible={
          !!(element as any).text &&
          (type === consts.CANVAS_ELEMENTS.TEXT_BLOCK ||
            type === consts.CANVAS_ELEMENTS.SHAPE ||
            type === consts.CANVAS_ELEMENTS.STICKY_NOTE)
        }
        attachedConnectors={element.attachedConnectors}
        {...mutation.getCallbacks()}
        {...otherProps}
      >
        {children}
        {isElementLinkBadgeSupporting(type) && !!element.link && (
          <CanvasElementLinkBadge
            url={element.link}
            scaleX={element.scaleX ?? 1}
            scaleY={element.scaleY ?? 1}
            x={linkBadgePos.x}
            y={linkBadgePos.y}
          />
        )}
      </Group>
      {isEditingLink && (
        <Html>
          <Modal dimBackground={true}>
            <EditElementLinkModal
              element={element}
              onChangeLink={(element, newLink) => {
                changeElement(
                  { link: newLink },
                  { shouldAdd: true, previousProps: { link: element.link } },
                  undefined,
                  id
                );
              }}
            />
          </Modal>
        </Html>
      )}
    </>
  );
}
