import { SyncService } from "frontend/services/syncService";
import { useEffect, useMemo, useState } from "react";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { Point } from "shared/datamodel/schemas";
import { TransformHooksCardStack } from "./card-stack-element";
import BaseCanvasElement from "../base-canvas-element";
import { Group, Rect } from "react-konva";
import { CardStackFrame, CardStackMoreUndisplayed, ShadowCard } from "./card-stack-subelements";
import { useCurrentCanvasValue } from "frontend/canvas-designer-new/canvas-context";
import { useFilteredItemIds } from "frontend/hooks/use-board-integrations";
import {
  EVT_ELEMENT_DRAG,
  EVT_ELEMENT_DRAG_START,
  EVT_ELEMENT_DROP,
  cardGridHeightParams,
  cardGridWidthParams,
  cardPosition,
  computeColumnsAndRows,
  posToColAndRow,
  sizeOfCardsLine,
} from "./card-stack-utils";
import { MondayItemElement } from "../integration-element";
import {
  DEFAULT_CARD_STACK_HEIGHT,
  DEFAULT_CARD_STACK_WIDTH,
  HEADER,
  PADDING_X,
  PADDING_Y,
  PADDING_Y_BOTTOM,
} from "shared/datamodel/card-stack";
import { LiveIntegrationElement } from "shared/datamodel/schemas/live-integration";
import { ITraits } from "frontend/canvas-designer-new/elements-toolbar/elements-toolbar-types";
import { noop } from "frontend/utils/fn-utils";
import { IntegrationItem } from "shared/datamodel/schemas/integration-item";
import { IntegrationType } from "shared/integrations/integration";
import { placeIntegrationElement } from "shared/datamodel/integration-item";
import consts from "shared/consts";
import { useEvent } from "react-use";
import { useAtomValue } from "jotai";
import { utilsAtom } from "state-atoms";
import { isPointInRect } from "frontend/utils/math-utils";
import { clamp } from "rambda";
import { getElementTypeForId } from "../canvas-elements-utils";
import { IntegrationCardsMap, createIntegrationCards } from "frontend/utils/monday-integration-utils";

export default function LiveIntegrationCardStack({
  syncService,
  id,
  element,
  changeElement,
  onChangeElement,
  isEditing,
  onStopEditing,
  onResize,
}: {
  id: string;
  syncService?: SyncService<RW>;
  element: LiveIntegrationElement;
  changeElement: any;
  onChangeElement: (
    id: string,
    props: Map<string, any>,
    previousProps: Map<string, any>,
    addUndo: boolean,
    updateSubMenuData?: any
  ) => void;
  isEditing: boolean;
  onStopEditing: () => void;
  onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
}) {
  const mutation = useMemo(() => new TransformHooksCardStack(id, onResize), [id, onResize]);
  const [{ documentId, pusherChannel }] = useCurrentCanvasValue();
  const canvasUtils = useAtomValue(utilsAtom(documentId));

  const { filters, integrationId, items_order = [] } = element;

  const { itemIds } = useFilteredItemIds(filters, integrationId, documentId, pusherChannel);

  const positions = itemIds.reduce((acc: any, itemId: string, index: number) => {
    const order = items_order.indexOf(itemId);
    if (order === -1) {
      acc[itemId] = index;
    } else {
      // this will start positioning items from the end of the items_order
      // making sure items that are not in the items_order will be positioned first
      acc[itemId] = itemIds.length + order;
    }
    return acc;
  }, {});
  itemIds.sort((a, b) => positions[a] - positions[b]);

  const [cardElements, setCardElements] = useState<IntegrationCardsMap>({});
  const [draggingItemId, setDraggingItemId] = useState<string | null>(null);
  const [oldDraggingIndex, setOldDraggingIndex] = useState<number>(0); // the index of the dragged item before the drag started
  const [createdItemIds, setCreatedItemIds] = useState<string[] | null>(null);

  const cardLayoutStartX = element.x + PADDING_X;
  const cardLayoutStartY = element.y + HEADER + PADDING_Y;

  const { columns, rows } = computeColumnsAndRows(
    element.width ?? DEFAULT_CARD_STACK_WIDTH,
    element.height ?? DEFAULT_CARD_STACK_HEIGHT,
    element.scaleX,
    element.scaleY
  );
  let width = sizeOfCardsLine(columns, cardGridWidthParams);
  let height = sizeOfCardsLine(rows, cardGridHeightParams);

  const stringItemIds = itemIds.join(",");
  const stringItemsOrder = items_order.join(",");

  useEffect(() => {
    if (stringItemIds !== stringItemsOrder) {
      // initialize the items_order
      changeElement({ items_order: itemIds }, { shouldAdd: false });
    }

    if (itemIds.length > 0 && items_order.length > 0) {
      calculateCardElements();
    }
  }, [stringItemsOrder, stringItemIds]);

  useEvent(EVT_ELEMENT_DRAG_START, (ev) => {
    const draggingIds = ev.detail.ids.filter(
      (id: string) => getElementTypeForId(id) === consts.CANVAS_ELEMENTS.INTEGRATION
    );
    const draggingItems = Object.entries(cardElements).filter(([, { id }]) => draggingIds.includes(id));
    if (!draggingItems.length) {
      // nothing to drag
      return;
    }
    if (draggingItems.length === 1) {
      const id = draggingItems[0][0];
      const currentIndex = itemIds.indexOf(id);
      setOldDraggingIndex(currentIndex);
      setDraggingItemId(id);
    }
    // create new items in the canvas
    const createdIds: string[] = [];
    draggingItems.forEach(([itemId, { element, id }]) => {
      const { x, y } = element;
      const strippedId = id.split("-")[1];
      const createdId = canvasUtils?.addIntegrationItem(
        { x: x + cardLayoutStartX, y: y + cardLayoutStartY },
        element.integrationId,
        element.integrationType,
        itemId,
        strippedId
      );
      if (createdId) {
        createdIds.push(createdId);
      }
    });
    // after dragging, we need to recalculate the card elements, mainly to create new ids for the dragged cards
    calculateCardElements();

    setCreatedItemIds(createdIds);
  });

  useEvent(EVT_ELEMENT_DRAG, (ev) => {
    if (!draggingItemId) {
      return;
    }

    const dropArea = {
      x: cardLayoutStartX,
      y: cardLayoutStartY,
      width: width,
      height: height,
    };
    const isMouseAboveMe = isPointInRect(ev.detail.mousePosition, dropArea);
    if (!isMouseAboveMe) {
      return;
    }

    let { x, y } = ev.detail.mousePosition;
    let { col, row } = posToColAndRow(x, y, cardLayoutStartX, cardLayoutStartY, columns, rows);
    let numRealCardsDisplayed = Math.min(columns * rows, itemIds.length);
    // the index for that location is:
    const insertIndex = clamp(0, numRealCardsDisplayed, row * columns + col);
    const newItemsOrder = [...items_order];
    const oldIndex = newItemsOrder.indexOf(draggingItemId);
    if (oldIndex !== insertIndex) {
      newItemsOrder.splice(oldIndex, 1);
      newItemsOrder.splice(insertIndex, 0, draggingItemId);
      changeElement({ items_order: newItemsOrder }, { shouldAdd: false });
    }
  });

  useEvent(EVT_ELEMENT_DROP, (ev) => {
    const dropArea = {
      x: cardLayoutStartX,
      y: cardLayoutStartY,
      width: width,
      height: height,
    };
    const isMouseAboveMe = isPointInRect(ev.detail, dropArea);

    const newItemsOrder = [...items_order];

    if (isMouseAboveMe && draggingItemId && createdItemIds) {
      canvasUtils?.onDeleteElements(createdItemIds);
      const oldIndex = newItemsOrder.indexOf(draggingItemId);
      newItemsOrder.splice(oldIndex, 1);
      newItemsOrder.splice(oldDraggingIndex, 0, draggingItemId);
      // make sure undo will bring back the original order
      changeElement({ items_order }, { shouldAdd: true, previousProps: { items_order: newItemsOrder } });
    }

    setOldDraggingIndex(0);
    setCreatedItemIds(null);
    setDraggingItemId(null);
  });

  let numItems = itemIds.length;
  let numCardsDisplayed = Math.min(numItems, columns * rows);
  let numOfUndisplayedCards = numItems - numCardsDisplayed;

  const displayedItemIds = itemIds.slice(0, numCardsDisplayed);
  const cards =
    integrationId &&
    displayedItemIds.map((itemId: string) => {
      const { element, id, position } = cardElements[itemId] ?? {};
      if (!id || !element || !position) {
        return null;
      }
      if (draggingItemId === itemId) {
        return <ShadowCard key={itemId} x={position.x} y={position.y} />;
      }
      return (
        <BaseCanvasElement
          key={id}
          id={id}
          type={"integrationItem"}
          x={position.x}
          y={position.y}
          element={element}
          onResize={noop}
          isSelectable={true}
          isEditingLink={false}
          isConnectable={false}
          changeElement={changeElement}
          isDirty={true} // meaning it's not yet in reflect
        >
          <MondayItemElement
            id={id}
            integrationId={integrationId}
            itemId={itemId}
            syncService={syncService}
            onChangeValue={reloadItems}
          />
        </BaseCanvasElement>
      );
    });

  function calculateCardElements() {
    if (!integrationId) {
      return;
    }
    const cards = createIntegrationCards(integrationId, itemIds, (index: number) => {
      const { x, y } = cardPosition(index, columns);
      return { x, y };
    });
    setCardElements(cards);
  }

  function expandHeightForAllCards() {
    let newHeight = sizeOfCardsLine(itemIds.length, cardGridHeightParams);
    const { scaleY = 1 } = element;
    let newScale = newHeight / (element.height ?? DEFAULT_CARD_STACK_HEIGHT);
    let props = new Map([["scaleY", newScale]]);
    let prevProps = new Map([["scaleY", scaleY]]);
    onChangeElement(id, props, prevProps, true);
  }

  function reloadItems() {
    // TODO: this is a hack to force a reload of the items
  }

  return (
    <>
      <BaseCanvasElement
        id={id}
        type={"liveIntegration"}
        x={element.x}
        y={element.y}
        element={element}
        onResize={onResize}
        mutation={mutation}
        isSelectable={true}
        isEditingLink={false}
        changeElement={changeElement}
      >
        <Rect width={element.width} height={element.height} fill={"transparent"} />
      </BaseCanvasElement>

      <CardStackFrame
        x={element.x}
        y={element.y}
        width={width}
        height={height}
        title={element.title}
        isEditing={isEditing}
        onStopEditing={onStopEditing}
        isLive={!!integrationId}
        integrationIcon="monday"
        onChangeTitle={function (newValue: string): void {
          changeElement({ title: newValue }, { shouldAdd: false });
        }}
      />

      <Group x={cardLayoutStartX} y={cardLayoutStartY}>
        {cards}
      </Group>

      {numOfUndisplayedCards > 0 && (
        <CardStackMoreUndisplayed
          x={element.x}
          y={element.y + height - PADDING_Y_BOTTOM}
          width={width}
          height={PADDING_Y_BOTTOM}
          count={numOfUndisplayedCards}
          onClick={expandHeightForAllCards}
        />
      )}
    </>
  );
}

export function liveIntegrationTraits(element: LiveIntegrationElement): ITraits {
  return {
    liveIntegration: {
      integrationId: element.integrationId,
      filters: element.filters ?? [],
    },
  };
}
