import { Circle, Group } from "react-konva";

export function LiveIconSVG({ color, size }: { color: string; size: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="7" fill={color} fillOpacity="0.35" />
      <circle cx="7" cy="7" r="4" fill={color} />
    </svg>
  );
}

export function LiveIconKonva(props: { color: string; size: number; x?: number; y?: number }) {
  const { color, size, ...otherProps } = props;
  return (
    <Group {...otherProps}>
      <Circle radius={size / 2} fill={color} opacity={0.35} />
      <Circle radius={size / 4} fill={color} />
    </Group>
  );
}
