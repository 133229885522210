import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let ShapeCircle = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M10 16.5C13.5899 16.5 16.5 13.5899 16.5 10C16.5 6.41015 13.5899 3.5 10 3.5C6.41015 3.5 3.5 6.41015 3.5 10C3.5 13.5899 6.41015 16.5 10 16.5ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

ShapeCircle.displayName = "ShapeCircle";

export { ShapeCircle };
