import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let Underline = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
            <path
            fillRule="evenodd"
            d="M6.1413 9.27669V2H4.75V9.27669C4.75 12.3762 7.13781 14.8889 10.0833 14.8889C13.0289 14.8889 15.4167 12.3762 15.4167 9.27669V2H14.0254V9.27669C14.0254 11.5676 12.2605 13.4248 10.0833 13.4248C7.90621 13.4248 6.1413 11.5676 6.1413 9.27669ZM17 16.6667H3V18H17V16.6667Z"
            fill={color}
            />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

Underline.displayName = "Underline";

export { Underline };
