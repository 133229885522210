import { CSSProperties } from "react";

export type RelativePlacement = "top" | "bottom" | "left" | "right";

export type Alignment = "start" | "middle" | "end";

export const isAnchorEntirelyInViewport = (anchor: DOMRectReadOnly) =>
  anchor.top >= 0 && anchor.left >= 0 && anchor.bottom <= window.innerHeight && anchor.right <= window.innerWidth;

export const isAnchorPartiallyInViewport = (anchor: DOMRectReadOnly) =>
  anchor.bottom >= 0 && anchor.right >= 0 && anchor.top <= window.innerHeight && anchor.left <= window.innerWidth;

function computePositions(
  anchor: DOMRectReadOnly,
  width: number,
  height: number,
  margin: number,
  alignment: Alignment,
): Record<RelativePlacement, { top: number; left: number }> {
  switch (alignment) {
    case "start":
      return {
        top: { top: anchor.top - height - margin, left: anchor.x - width / 2},
        bottom: { top: anchor.bottom + margin, left: anchor.x - width / 2},
        left: { top: anchor.top - height / 2, left: anchor.left - width - margin },
        right: { top: anchor.top - height / 2, left: anchor.right + margin },
      };
    case "end":
      return {
        top: { top: anchor.top - height - margin, left: anchor.x + anchor.width - width / 2},
        bottom: { top: anchor.bottom + margin, left: anchor.x + anchor.x + anchor.width - width / 2 },
        left: { top: anchor.top + anchor.height - height / 2, left: anchor.left - width - margin },
        right: { top: anchor.top + anchor.height - height / 2, left: anchor.right + margin },
      };
    default:
      return {
        top: { top: anchor.top - height - margin, left: anchor.x + anchor.width / 2 - width / 2 },
        bottom: { top: anchor.bottom + margin, left: anchor.x + anchor.width / 2 - width / 2 },
        left: { top: (anchor.top + anchor.bottom) / 2 - height / 2, left: anchor.left - width - margin },
        right: { top: (anchor.top + anchor.bottom) / 2 - height / 2, left: anchor.right + margin },
      };
  }
}

export function insetRect(rect: DOMRectReadOnly | undefined, inset: number): DOMRect {
  if (!rect) {
    return new DOMRectReadOnly(0, 0, window.innerWidth, window.innerHeight);
  }

  return DOMRect.fromRect({
    x: rect.x + inset,
    y: rect.y + inset,
    width: rect.width - inset * 2,
    height: rect.height - inset * 2,
  });
}

function inverse(side: RelativePlacement) {
  switch (side) {
    case "top":
      return "bottom";
    case "bottom":
      return "top";
    case "left":
      return "right";
    case "right":
      return "left";
    default:
      let unhandled: never = side;
      throw Error();
  }
}

export type DOMPlacement = {
  top: number;
  left: number;
  side: RelativePlacement;
  shift: number;
};

export function calculatePlacement(
  anchor: DOMRect,
  width: number,
  height: number,
  margin: number,
  side: RelativePlacement,
  boundary?: DOMRectReadOnly,
  alignment?: Alignment,
): DOMPlacement {

  alignment ??= "middle"
  if (!boundary) {
    return { ...computePositions(anchor, width, height, margin, alignment)[side], shift: 0, side};
  }

  const positions = computePositions(anchor, width, height, margin, alignment);
  if (side == "top" || side == "bottom") {
    // flip on y-axis to stay in boundaries
    let requestedPos = positions[side];
    let deviation = Math.max(
      Math.max(boundary.top - requestedPos.top, 0),
      Math.max(requestedPos.top + height - boundary.bottom, 0)
    );
    let otherPos = positions[inverse(side)];
    let otherDeviation = Math.max(
      Math.max(boundary.top - otherPos.top, 0),
      Math.max(otherPos.top + height - boundary.bottom, 0)
    );
    let pos;
    if (deviation <= otherDeviation) pos = requestedPos;
    else {
      pos = otherPos;
      side = inverse(side);
    }

    // shift in y to stay in boundary bounds
    pos.top = Math.max(pos.top, boundary.top);
    if (pos.top + height > boundary.bottom) pos.top = boundary.bottom - height;

    // shift on x-axis if needed
    const dx = Math.max(boundary.left - pos.left, 0) + Math.min(boundary.right - (pos.left + width), 0);
    return {
      top: pos.top,
      left: pos.left + dx,
      shift: dx,
      side,
    };
  } else {
    // flip on x-axis if needed
    let requestedPos = positions[side];
    let deviation = Math.max(
      Math.max(boundary.left - requestedPos.left, 0),
      Math.max(requestedPos.left + width - boundary.bottom, 0)
    );
    let otherPos = positions[inverse(side)];
    let otherDeviation = Math.max(
      Math.max(boundary.left - otherPos.left, 0),
      Math.max(otherPos.left + width - boundary.bottom, 0)
    );
    let pos;
    if (deviation <= otherDeviation) pos = requestedPos;
    else {
      pos = otherPos;
      side = inverse(side);
    }

    // shift in x to stay in boundary bounds
    pos.left = Math.max(pos.left, boundary.left);
    if (pos.left + width > boundary.right) pos.left = boundary.right - width;

    // shift on y-axis if needed
    const dy = Math.max(boundary.top - pos.top, 0) + Math.min(boundary.bottom - (pos.top + height), 0);
    return {
      top: pos.top + dy,
      left: pos.left,
      shift: dy,
      side,
    };
  }
}

// it's best if arrow is positioned above center of element, now there's an offset
export function calculateArrowPlacement(tooltipSide: RelativePlacement, arrowSize: number, shift: number = 0) {
  let top, left, right, bottom;
  let transform;
  switch (tooltipSide) {
    case "top":
      bottom = 0;
      left = `max(min(calc( 50% - ${shift}px ), calc( 100% - ${arrowSize}px )), ${arrowSize}px)`;
      transform = "translate(-50%, 50%) rotate(45deg)";
      break;
    case "bottom":
      top = 0;
      left = `min(max(calc( 50% - ${shift}px ), ${arrowSize}px), calc( 100% - ${arrowSize}px ))`;
      transform = "translate(-50%, -50%) rotate(45deg)";
      break;
    case "left":
      top = `min(max(calc( 50% - ${shift}px ), ${arrowSize}px), calc( 100% - ${arrowSize}px ))`;
      right = 0;
      transform = "translate(-50%, -50%) rotate(45deg)";
      break;
    case "right":
      top = `min(max(calc( 50% - ${shift}px ), ${arrowSize}px), calc( 100% - ${arrowSize}px ))`;
      left = 0;
      transform = "translate(-50%, -50%) rotate(45deg)";
      break;
    default:
      const _unhandled: never = tooltipSide;
  }
  return {
    position: "absolute",
    top,
    left,
    right,
    bottom,
    transform,
    width: arrowSize,
    height: arrowSize,
    backgroundColor: "inherit",
  } as CSSProperties;
}

export function getBoundingRect(boundary?: DOMRectReadOnly | string) {
  if (!boundary) {
    return new DOMRectReadOnly(0, 0, window.innerWidth, window.innerHeight);
  }
  if (typeof boundary == "string") {
    return document.getElementById(boundary)?.getBoundingClientRect();
  }
  return boundary;
}
