import { Group, Rect } from "react-konva";

export default function RoundedCornersContainer({
  x,
  y,
  radius,
  background,
  children,
}: React.PropsWithChildren<{
  x?: number;
  y?: number;
  radius: number;
  background?: string;
}>) {
  return (
    <Group
      x={x}
      y={y}
      clipFunc={(ctx: any) => {
        ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
      }}
    >
      {background && <Rect width={radius * 2} height={radius * 2} fill={background} />}
      {children}
    </Group>
  );
}
