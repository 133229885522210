import consts, { TypeCanvasElement } from "shared/consts";
import type { BoardContext } from "../index";
import useCanvasElement from "elements/hooks/use-canvas-element";
import React from "react";
import BaseElementComponent from "elements/base/base-element-component";
import { Point } from "shared/datamodel/schemas";

export default function ElementComponent({
  id,
  type,
  context,
  elementData = null,
  allElementsData = [],
  onResize,
}: {
  id: string;
  type: TypeCanvasElement;
  context: BoardContext;
  elementData?: any;
  allElementsData?: [string, any][];
  onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
}) {
  const {
    element = elementData,
    controller,
    provider,
  } = useCanvasElement(id, type, context, elementData, allElementsData);

  if (!element || !controller || !provider) {
    return null;
  }

  const Component = provider.getElementRenderer();

  return (
    <BaseElementComponent
      id={id}
      key={id}
      type={type}
      element={element}
      controller={controller}
      isDraggable={true}
      isSelectable={!context.hiddenElementIds.includes(id)}
      isConnectable={provider.isConnectorsEnabled()}
      onResize={onResize}
    >
      <Component id={id} controller={controller} />
    </BaseElementComponent>
  );
}
