import StyledButton from "frontend/ui-components/styled-button";
import style from "./error-page.module.css";
import { useEffect } from "react";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import * as Sentry from "@sentry/nextjs";
import Modal from "frontend/modal/modal";
import { PortalID } from "frontend/modal/usePortal";
import { getPathPrefix } from '../utils/getPathPrefix';

export default function ErrorPage({ error, onRetry , category}: { error?: Error; onRetry?: () => void , category?: string}) {
  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.PAGE_VIEWS, `error-page${category ? "-" + category : ""}`, error?.message);
    if (error) {
      Sentry.captureException(error, { tags: { type: category ? category : "general" } });
    }
  }, []);

  function retry() {
    if (onRetry) {
      onRetry();
    } else {
      window.location.reload();
    }
  }

  return (
    <>
      <div className={style.container}>
        <img src={getPathPrefix("/images/oops.png")}/>
        <span className={style.title}>Oops! It seems something went wrong</span>
        <div className={style.subtitle}>
          <p>Let’s give this another try, shall we?</p>
        </div>
        <StyledButton title={"Retry"} customStyle={{ width: "104px", height: "36px" }} onClick={retry} />
      </div>
      <p className={style.footer}>
        Need assistance? Contact us: <a href="mailto:canvas-support@monday.com"> canvas-support@monday.com</a>
      </p>
    </>
  );
}

export function ModalErrorPage({ error, onRetry }: { error?: Error; onRetry?: () => void }) {
  return (
    <Modal portalID={PortalID.ErrorPage}>
      <div style={{ background: "white", width: "100%" }}>
        <ErrorPage error={error} onRetry={onRetry} />
      </div>
    </Modal>
  );
}
