const makeTextAreaWithFixedPadding = (padding: number, w: number, h: number) => {
  return (scaleX: number, scaleY: number) => {
    let x = padding / scaleX;
    let y = padding / scaleY;
    return [x, y, w - 2 * x, h - 2 * y];
  }
}

const data = {
  "rect": {
    "outline": "M 0 0 H 200 V 200 H 0 Z",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 0 H 200 V 200 H 0 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": makeTextAreaWithFixedPadding(20, 200, 200),
    "icon": "rect"
  },
  "rounded-rect": {
    "outline": "M 0 15 C 0 5 5 0 15 0 C 71.7 0 128.3 0 185 0 C 195 0 200 5 200 15 C 200 71.7 200 128.3 200 185 C 200 195 195 200 185 200 C 128.3 200 71.7 200 15 200 C 5 200 0 195 0 185 C 0 128.3 0 71.7 0 15",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "polygon",
        "cornerRadius": 20,
        "fill": "fill",
        "stroke": "stroke",
        "points": "0 0 200 0 200 200 0 200"
      }
    ],
    "textarea": makeTextAreaWithFixedPadding(20, 200, 200),
    "icon": "rounded-rect"
  },
  "double-rounded-rect": {
    "outline": "M 0 15 C 0 5 5 0 15 0 C 71.7 0 128.3 0 185 0 C 195 0 200 5 200 15 C 200 71.7 200 128.3 200 185 C 200 195 195 200 185 200 C 128.3 200 71.7 200 15 200 C 5 200 0 195 0 185 C 0 128.3 0 71.7 0 15",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "polygon",
        "cornerRadius": 20,
        "points": "0 0 200 0 200 200 0 200",
        "fill": "fill",
        "stroke": "stroke",
      },
      {
        "type": "polygon",
        "cornerRadius": 20,
        "points": (sx: number, sy: number) => {
          let x = 20 / sx;
          let y = 20 / sy;
          return `${x} ${y} ${200 - x} ${y} ${200 - x} ${200 - y} ${x} ${200 - y}`;
        },
        "fill": "fill",
        "stroke": "stroke",
      }
    ],
    "textarea": makeTextAreaWithFixedPadding(25, 200, 200),
    "icon": "double-outline-rounded-rect"
  },
  "circle": {
    "outline": "M 100 200 C 45 200 0 155 0 100 C 0 45 45 0 100 0 C 155 0 200 45 200 100 C 200 155 155 200 100 200",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M100 200 C 45 200 0 155 0 100 C 0 45 45 0 100 0 C 155 0 200 45 200 100 C 200 155 155 200 100 200",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      30,
      30,
      140,
      140
    ],
    "icon": "circle"
  },
  "diamond": {
    "outline": "M 100 0 C 133.3 33.3 166.7 66.7 200 100 C 166.7 133.3 133.3 166.7 100 200 C 66.7 166.7 33.3 133.3 0 100 C 33.3 66.7 66.7 33.3 100 0",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "textarea": [
      50,
      50,
      100,
      100
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 100 0 L 200 100 L 100 200 L 0 100 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "icon": "diamond"
  },
  "hexagon": {
    "outline": "M 0 0 C 31.1 16.7 62.2 33.3 93.3 50 C 93.3 83.3 93.3 116.7 93.3 150 C 62.2 166.7 31.1 183.3 0 200 C -31.1 183.3 -62.2 166.7 -93.3 150 C -93.3 116.7 -93.3 83.3 -93.3 50 C -62.2 33.3 -31.1 16.7 0 0",
    "viewbox": [
      -93.3,
      0,
      186.6,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 0 L 93.3 50 L 93.3 150 L 0 200 L -93.3 150 L -93.3 50 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "icon": "hexagon",
    "textarea": [
      -93,
      50,
      185,
      100
    ]
  },
  "triangle": {
    "outline": "M 0 -100 C 28.9 -50 57.7 0 86.6 50 C 28.9 50 -28.9 50 -86.6 50 C -57.7 0 -28.9 -50 0 -100",
    "viewbox": [
      -86.6,
      -100,
      173.2,
      150
    ],
    "textarea": [
      -43.3,
      -20,
      86.6,
      70
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 -100 L 86.6 50 L -86.6 50 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "icon": "triangle",
    "anchors": {
      "left": {
        "x": -0.25,
        "y": 0,
        "rotation": 210,
        "onBoundingBox": false
      },
      "right": {
        "x": 0.25,
        "y": 0,
        "rotation": -30,
        "onBoundingBox": false
      }
    }
  },
  "datastore": {
    "outline": "M 0 25 C 0 -8.3 200 -8.3 200 25 C 200 75 200 125 200 175 C 200 208.3 0 208.3 0 175 C 0 125 0 75 0 25",
    "textarea": [
      10,
      80,
      180,
      100
    ],
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 25 C 0 -8.33 200 -8.33 200 25 L 200 175 C 200 208.33 0 208.33 0 175 Z",
        "fill": "fill",
        "stroke": "stroke"
      },
      {
        "type": "path",
        "d": "M 0 25 C 0 50 200 50 200 25 M 0 37.5 C 0 62.5 200 62.5 200 37.5 M 0 50 C 0 75 200 75 200 50",
        "stroke": "stroke"
      }
    ],
    "icon": "datastore"
  },
  "flat-annotation-right": {
    "segments": [
      {
        "type": "path",
        "d": "M 50 0 L 25 0 L 25 200 L 50 200 M 0 100 L 25 100",
        "stroke": "stroke"
      }
    ],
    "viewbox": [
      0,
      0,
      50,
      200
    ],
    "outline": "M 25 100 C 16.7 100 8.3 100 0 100 M 50 200 C 41.7 200 33.3 200 25 200 C 25 133.3 25 66.7 25 0 C 33.3 0 41.7 0 50 0",
    "icon": "flat-annotation-right"
  },
  "flat-annotation-left": {
    "segments": [
      {
        "type": "path",
        "d": "M 0 200 L 25 200 L 25 0 L -0 0 M 50 100 L 25 100",
        "stroke": "stroke"
      }
    ],
    "viewbox": [
      0,
      0,
      50,
      200
    ],
    "outline": "M 25 100 C 33.3 100 41.7 100 50 100 M 0 0 C 8.3 0 16.7 0 25 0 C 25 66.7 25 133.3 25 200 C 16.7 200 8.3 200 0 200",
    "icon": "flat-annotation-left"
  },
  "wide-sideways-hexagon": {
    "viewbox": [
      0,
      0,
      200,
      135
    ],
    "segments": [
      {
        "type": "path",
        "d": "M34.27 1H167.73L201 67.67L167.73 134.33H34.27L1 67.67L34.27 1Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "outline": "M 34.3 1 C 78.8 1 123.2 1 167.7 1 C 178.8 23.2 189.9 45.4 201 67.7 C 189.9 89.9 178.8 112.1 167.7 134.3 C 123.2 134.3 78.8 134.3 34.3 134.3 C 23.2 112.1 12.1 89.9 1 67.7 C 12.1 45.4 23.2 23.2 34.3 1 C 34.3 1 34.3 1 34.3 1",
    "textarea": [
      35,
      10,
      130,
      123
    ],
    "icon": "wide-sideways-hexagon"
  },
  "parallelogram": {
    "outline": "M 0 120 C 20 80 40 40 60 0 C 120 0 180 0 240 0 C 220 40 200 80 180 120 C 120 120 60 120 0 120",
    "viewbox": [
      0,
      0,
      240,
      120
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 120 L 60 0 L 240 0 L 180 120 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      60,
      0,
      120,
      120
    ],
    "icon": "parallelogram",
    "anchors": {
      "left": {
        "x": -0.375,
        "y": 0,
        "rotation": 180,
        "onBoundingBox": false
      },
      "right": {
        "x": 0.375,
        "y": 0,
        "rotation": 0,
        "onBoundingBox": false
      },
      "top": {
        "x": 0.125,
        "y": -0.5,
        "rotation": 270,
        "onBoundingBox": false
      },
      "buttom": {
        "x": -0.125,
        "y": 0.5,
        "rotation": 90,
        "onBoundingBox": false
      }
    }
  },
  "pentagon": {
    "outline": "M 38.1 180 C 25.4 142 12.7 104 0 66 C 33.3 44 66.7 22 100 0 C 133.3 22 166.7 44 200 66 C 187.3 104 174.6 142 161.9 180 C 120.6 180 79.4 180 38.1 180",
    "viewbox": [
      0,
      0,
      200,
      180
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 38.14 180 L 0 66 L 100 0 L 200 66 L 161.86 180 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      40,
      40,
      120,
      140
    ],
    "icon": "pentagon",
    "anchors": {
      "left": {
        "x": -0.5,
        "y": -0.133,
        "rotation": 180,
        "onBoundingBox": true
      },
      "right": {
        "x": 0.5,
        "y": -0.133,
        "rotation": 0,
        "onBoundingBox": true
      }
    }
  },
  "octagon": {
    "outline": "M 50 0 C 83.3 0 116.7 0 150 0 C 166.7 16.7 183.3 33.3 200 50 C 200 83.3 200 116.7 200 150 C 183.3 166.7 166.7 183.3 150 200 C 116.7 200 83.3 200 50 200 C 33.3 183.3 16.7 166.7 0 150 C 0 116.7 0 83.3 0 50 C 16.7 33.3 33.3 16.7 50 0",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 50 0 L 150 0 L 200 50 L 200 150 L 150 200 L 50 200 L 0 150 L 0 50 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      25,
      25,
      150,
      150
    ],
    "icon": "octagon"
  },
  "trapezoid": {
    "outline": "M 0 100 C 10 66.7 20 33.3 30 0 C 76.7 0 123.3 0 170 0 C 180 33.3 190 66.7 200 100 C 133.3 100 66.7 100 0 100",
    "viewbox": [
      0,
      0,
      200,
      100
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 100 L 30 0 L 170 0 L 200 100 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      30,
      0,
      140,
      100
    ],
    "icon": "trapezoid",
    "anchors": {
      "left": {
        "x": -0.425,
        "y": 0,
        "rotation": 180,
        "onBoundingBox": false
      },
      "right": {
        "x": 0.425,
        "y": 0,
        "rotation": 0,
        "onBoundingBox": false
      }
    }
  },
  "fat-arrow-right": {
    "outline": "M 140 0 L 192 40 L 140 80 V 52 H 0 V 26 H 140 Z",
    "viewbox": [
      0,
      0,
      192,
      80
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 140 0 L 192 40 L 140 80 V 52 H 0 V 26 H 140 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "icon": "fat-arrow-right",
    "textarea": [
      10,
      26,
      120,
      26
    ]
  },
  "fat-arrow-left": {
    "outline": "M 52 80 L 0 40 L 52 0 L 52 26 H 192 V 52 H 52 Z",
    "viewbox": [
      0,
      0,
      192,
      80
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 52 80 L 0 40 L 52 0 L 52 26 H 192 V 52 H 52 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "icon": "fat-arrow-left",
    "textarea": [
      60,
      26,
      120,
      26
    ]
  },
  "fat-arrow-both": {
    "outline": "M 0 40 L 52 0 V 26 H 140 V 0 L 192 40 L 140 80 V 52 H 52 V 80 Z",
    "viewbox": [
      0,
      0,
      192,
      80
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 40 L 52 0 V 26 H 140 V 0 L 192 40 L 140 80 V 52 H 52 V 80 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "icon": "fat-arrow-both",
    "textarea": [
      45,
      26,
      100,
      26
    ]
  },
  "cloud-callout": {
    "outline": "M 22.1 63.2 C 15.5 65.6 8.1 63.5 3.8 57.9 C -0.4 52.3 -0.4 44.7 3.8 39.1 C 8.1 33.5 15.5 31.4 22.1 33.8 C 22.1 24 28.2 15.2 37.5 11.8 C 46.8 8.3 57.3 10.9 63.9 18.2 C 68.2 7.7 78.5 0.9 89.8 1 C 101.2 1 111.5 7.8 115.7 18.2 C 122.5 12.2 132.1 10.5 140.6 13.8 C 149 17 154.8 24.8 155.5 33.8 C 162.1 31.4 169.5 33.5 173.8 39.1 C 178 44.6 178 52.3 173.8 57.8 C 169.5 63.4 162.1 65.5 155.5 63.1 C 154.7 72.6 148.3 80.7 139.2 83.6 C 130.1 86.6 120.1 83.9 113.7 76.8 C 108.6 85.1 99.5 90.1 89.8 90.1 C 80.1 90.1 71 85.1 65.9 76.8 C 61.1 86.3 47.4 91 35.3 87.2 C 23.2 83.4 17.3 72.7 22.1 63.2 C 22.1 63.2 22.1 63.2 22.1 63.2",
    "viewbox": [
      0,
      0,
      178,
      109
    ],
    "segments": [
      {
        "type": "path",
        "d": "M22.1 63.2C15.5 65.6 8.1 63.5 3.8 57.9C-0.4001 52.3371 -0.4001 44.6629 3.8 39.1C8.0879 33.5264 15.4971 31.3805 22.1 33.8C22.1 24 28.2 15.2 37.5 11.8C46.8 8.3 57.3 10.9 63.9 18.2C68.2095 7.7145 78.4638 0.9047 89.8 1C101.2 1 111.5 7.8 115.7 18.2C122.5 12.2 132.1 10.5 140.6 13.8C149 17 154.8 24.8 155.5 33.8C162.1 31.4 169.5 33.5 173.8 39.1C178 44.6 178 52.3 173.8 57.8C169.5121 63.3736 162.1029 65.5195 155.5 63.1C154.7 72.6 148.3 80.7 139.2 83.6C130.1 86.6 120.1 83.9 113.7 76.8C108.5796 85.0788 99.5343 90.1124 89.8 90.1C80.1 90.1 71 85.1 65.9 76.8C61.1 86.3 47.4 91 35.3 87.2C23.2 83.4 17.3 72.7 22.1 63.2C22.1 63.2 22.1 63.2 22.1 63.2",
        "fill": "fill",
        "stroke": "stroke"
      },
      {
        "type": "path",
        "d": "M12 94.4 C 12 97.8 20.4 100 27 98 C 30 97.4 32 96 32 94.4 C 32 91 23.6 88.9 17 90.6 C 13.9 91.4 12 92.8 12 94.4 M 4 105 C 4 107.3 9.3 108.7 13.5 107.6 C 15.5 107.0 16.7 106.1 16.7 105 C 16.7 102.8 11.4 101.4 7.2 102.5 C 5.2 103 4 104 4 105 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      30,
      20,
      120,
      55
    ],
    "icon": "cloud-callout"
  },
  "cloud": {
    "outline": "M 50 33.3 C 50 33.3 50 33.3 50 33.3 C 60 6.7 100 6.7 125 26.7 C 160 13.3 200 40 175 66.7 C 200 80 200 106.7 160 106.7 C 140 133.3 80 133.3 62 106.7 C 36 120 0 88 32 73.3 C 0 66.7 10 33.3 50 33.3",
    "viewbox": [
      12,
      12,
      180,
      115
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 50 33.33 C 10 33.33 0 66.67 32 73.33 C 0 88 36 120 62 106.66 C 80 133.33 140 133.33 160 106.66 C 200 106.66 200 80 175 66.67 C 200 40 160 13.33 125 26.67 C 100 6.67 60 6.67 50 33.33 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      45,
      37,
      115,
      67
    ],
    "icon": "cloud"
  },
  "subroutine": {
    "outline": "M 9.6 0 C 69.9 0 130.1 0 190.4 0 C 190.4 0 200 0 200 9.6 C 200 43.2 200 76.8 200 110.4 C 200 110.4 200 120 190.4 120 C 130.1 120 69.9 120 9.6 120 C 9.6 120 0 120 0 110.4 C 0 76.8 0 43.2 0 9.6 C 0 9.6 0 0 9.6 0",
    "viewbox": [
      0,
      0,
      200,
      120
    ],
    "segments": [
      {
        "type": "polygon",
        "cornerRadius": 20,
        "points": "0 0 200 0 200 120 0 120",
        "fill": "fill",
        "stroke": "stroke"
      },
      {
        "type": "path",
        "d": (sx: number, sy: number) => {
          let x = 20 / sx;
          return `M ${x} 0 L ${x} 120 M ${200 - x} 0 L ${200 - x} 120`
        },
        "stroke": "stroke"
      }
    ],
    "textarea": (sx: number, sy: number) => {
      let margin = 20 / sx;
      return [margin, 0, 200 - margin * 2, 120];
    },
    "icon": "subroutine"
  },
  "square-callout": {
    "outline": "M 0 0 C 66.7 0 133.3 0 200 0 C 200 34.4 200 68.9 200 103.3 C 173.3 103.3 146.7 103.3 120 103.3 C 113.3 113.3 106.7 123.3 100 133.3 C 100 123.3 100 113.3 100 103.3 C 66.7 103.3 33.3 103.3 0 103.3 C 0 68.9 0 34.4 0 0",
    "viewbox": [
      0,
      0,
      200,
      135
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 0 L 200 0 L 200 103.33 L 120 103.33 L 100 133.33 L 100 103.33 L 0 103.33 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      5,
      5,
      190,
      93
    ],
    "icon": "square-callout"
  },
  "double-outline-circle": {
    "outline": "M 0 100 C 0 64.3 19.1 31.3 50 13.4 C 116.7 -25.1 200 23 200 100 C 200 135.7 180.9 168.7 150 186.6 C 83.3 225.1 0 177 0 100",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M0 100C0 64.3 19.1 31.3 50 13.4C116.7 -25.1 200 23 200 100C200 135.7 180.9 168.7 150 186.6C83.3 225.1 0 177 0 100",
        "fill": "fill",
        "stroke": "stroke"
      },
      {
        "type": "path",
        "d": (scaleX: number, scaleY: number) => {
          const dR = 20; // difference in radius in pixels
          const rx = (200 - (dR/scaleX) * 2)/2; // radius x = width/2
          const ry = (200 - (dR/scaleY) * 2)/2; // radius y = height/2
          return `M ${dR / scaleX} 100
          A ${rx} ${ry} 0 1 0 ${200 - dR / scaleX} 100
          A ${rx} ${ry} 0 1 0 ${dR / scaleX} 100
          `;
        },
        "stroke": "stroke"
      }
    ],
    "textarea": [
      37,
      37,
      126,
      126
    ],
    "icon": "double-circle"
  },
  "manual-input": {
    "outline": "M 0 200 C 0 151 0 102 0 53 C 66.7 35.3 133.3 17.7 200 0 C 200 66.7 200 133.3 200 200 C 133.3 200 66.7 200 0 200",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 200 L 0 53 L 200 0 L 200 200 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      10,
      60,
      180,
      130
    ],
    "icon": "manual-input-large"
  },
  "pentagram": {
    "outline": "M 0 69.7 C 25.5 69.7 51.1 69.7 76.6 69.7 C 84.4 46.5 92.2 23.2 100 0 C 107.8 23.2 115.6 46.5 123.4 69.7 C 148.9 69.7 174.5 69.7 200 69.7 C 179.6 85.2 159.3 100.8 138.9 116.3 C 147 140.9 155.1 165.4 163.2 190 C 142.1 174.8 121.1 159.6 100 144.4 C 78.9 159.6 57.9 174.8 36.8 190 C 44.9 165.4 52.9 140.9 61 116.3 C 40.7 100.8 20.3 85.2 0 69.7",
    "viewbox": [
      0,
      0,
      200,
      190
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 69.7 L 76.6 69.7 L 100 0 L 123.4 69.7 L 200 69.7 L 138.9 116.3 L 163.2 190 L 100 144.4 L 36.8 190 L 61 116.3 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      65,
      75,
      70,
      65
    ],
    "icon": "pentagram",
    "anchors": {
      "left": {
        "x": -0.5,
        "y": -0.1331578947,
        "rotation": 180,
        "onBoundingBox": false
      },
      "right": {
        "x": 0.50,
        "y": -0.1331578947,
        "rotation": 0,
        "onBoundingBox": false
      },
      "buttom": {
        "x": 0,
        "y": 0.2605263158,
        "rotation": 90,
        "onBoundingBox": false
      }
    }
  },
  "multidocument": {
    "outline": "M 31.3 0.6 C 26.4 0.6 22.5 4.9 22.5 10 C 22.5 11.5 22.5 13.1 22.5 14.6 C 74.5 14.6 126.6 14.6 178.6 14.6 C 183.5 14.6 187.4 18.9 187.4 24 C 187.4 47.9 187.4 71.7 187.4 95.6 C 191.1 96.6 194.7 97.9 198.4 99.3 C 198.4 69.5 198.4 39.8 198.4 10 C 198.4 4.9 194.4 0.6 189.6 0.6 C 136.8 0.6 84.1 0.6 31.3 0.6 M 20.4 14.6 C 15.5 14.6 11.6 18.8 11.6 24 C 11.6 25.5 11.6 27.1 11.6 28.6 C 63.6 28.6 115.7 28.6 167.7 28.6 C 172.6 28.6 176.5 32.8 176.5 38 C 176.5 61.9 176.5 85.7 176.5 109.6 C 180.2 110.6 183.8 111.9 187.5 113.3 C 187.5 83.5 187.5 53.8 187.5 24 C 187.5 18.8 183.5 14.6 178.7 14.6 C 125.9 14.6 73.2 14.6 20.4 14.6 M 167.7 28.6 C 172.6 28.6 176.5 32.8 176.5 38 C 176.5 67.8 176.5 97.5 176.5 127.3 C 147.5 115.8 117.9 115.8 88.6 127.3 C 57.1 139.7 30 144.3 0.6 127.3 C 0.6 99.1 0.6 70.9 0.6 42.7 C 0.6 41.1 0.6 39.6 0.6 38 C 0.6 32.8 4.6 28.6 9.4 28.6 C 62.2 28.6 114.9 28.6 167.7 28.6",
    "viewbox": [
      0,
      0,
      200,
      140
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 31.3 0.6 C 26.4 0.6 22.5 4.9 22.5 10 V 14.6 H 178.6 C 183.5 14.6 187.4 18.9 187.4 24 V 95.6 C 191.1 96.6 194.7 97.9 198.4 99.3 V 10 C 198.4 4.9 194.4 0.6 189.6 0.6 Z M 20.4 14.6 C 15.5 14.6 11.6 18.8 11.6 24 V 28.6 H 167.7 C 172.6 28.6 176.5 32.8 176.5 38 V 109.6 C 180.2 110.6 183.8 111.9 187.5 113.3 V 24 C 187.5 18.8 183.5 14.6 178.7 14.6 Z M 167.7 28.6 C 172.6 28.6 176.5 32.8 176.5 38 V 127.3 C 147.5 115.8 117.9 115.8 88.6 127.3 C 57.1 139.7 30 144.3 0.6 127.3 V 42.7 V 38 C 0.6 32.8 4.6 28.6 9.4 28.6 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      5,
      30,
      165,
      85
    ],
    "icon": "multidocument"
  },
  "barrel": {
    "outline": "M 1 167.7 C 1 123.2 1 78.8 1 34.3 C 1 15.9 45.8 1 101 1 C 156.2 1 201 15.9 201 34.3 C 201 78.8 201 123.2 201 167.7 C 201 186.1 156.2 201 101 201 C 45.8 201 1 186.1 1 167.7 C 1 167.7 1 167.7 1 167.7",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 1 167.7 L 1 34.3 C 1 15.9 45.8 1 101 1 C 156.2 1 201 15.9 201 34.3 L 201 167.7 C 201 186.1 156.2 201 101 201 C 45.8 201 1 186.1 1 167.7 Z",
        "fill": "fill",
        "stroke": "stroke"
      },
      {
        "type": "path",
        "d": "M 1 34.3 C 1 52.7 45.8 67.7 101 67.7 C 156.2 67.7 201 52.7 201 34.3",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      10,
      70,
      180,
      105
    ],
    "icon": "barrel"
  },
  "sideways-barrel": {
    "outline": "M 19.4 1 C 73.8 1 128.2 1 182.6 1 C 192.8 1 201 27.9 201 61 C 201 94.1 192.8 121 182.6 121 C 128.2 121 73.8 121 19.4 121 C 9.2 121 1 94.1 1 61 C 1 27.9 9.2 1 19.4 1 C 19.4 1 19.4 1 19.4 1",
    "viewbox": [
      0,
      0,
      202,
      120
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 19.4 1 L 182.6 1 C 192.8 1 201 27.9 201 61 C 201 94.1 192.8 121 182.6 121 L 19.4 121 C 9.2 121 1 94.1 1 61 C 1 27.9 9.2 1 19.4 1 Z",
        "fill": "fill",
        "stroke": "stroke"
      },
      {
        "type": "path",
        "d": "M 182.6 1 C 172.5 1 164.3 27.9 164.3 61 C 164.3 94.1 172.5 121 182.6 121",
        "stroke": "stroke"
      },
    ],
    "textarea": [
      15,
      10,
      145,
      100
    ],
    "icon": "sideways-barrel"
  },
  "left-brace": {
    "outline": "M 33.3 0 C 30.5 0 27.8 0 25 0 C 19.4 0 16.7 3.3 16.7 10 C 16.7 36.7 16.7 63.3 16.7 90 C 16.7 96.7 13.9 100 4.2 100 C 15.3 100 16.7 103.3 16.7 110 C 16.7 136.7 16.7 163.3 16.7 190 C 16.7 196.7 19.4 200 25 200 C 27.8 200 30.5 200 33.3 200",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 33.3 0 H 25 C 19.4 0 16.7 3.3 16.7 10 V 90 C 16.7 96.7 13.9 100 4.2 100 C 15.3 100 16.7 103.3 16.7 110 V 190 C 16.7 196.7 19.4 200 25 200 H 33.3",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      25,
      10,
      140,
      175
    ],
    "icon": ""
  },
  "right-brace": {
    "outline": "M 161 0 C 163.7 0 166.3 0 169 0 C 175 0 177 3 177 10 C 177 36.7 177 63.3 177 90 C 177 97 179 100 190 100 C 180 100 177 103 177 110 C 177 136.7 177 163.3 177 190 C 177 197 175 200 169 200 C 166.3 200 163.7 200 161 200",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 161 200 L 169 200 C 175 200 177 197 177 190 L 177 110 C 177 103 180 100 190 100 C 179 100 177 97 177 90 L 177 10 C 177 3 175 0 169 0 L 161 0",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      25,
      10,
      140,
      175
    ],
    "icon": ""
  },
  "cross": {
    "outline": "M 0 32 C 10.7 32 21.3 32 32 32 C 32 21.3 32 10.7 32 0 C 37.3 0 42.7 0 48 0 C 48 10.7 48 21.3 48 32 C 58.7 32 69.3 32 80 32 C 80 37.3 80 42.7 80 48 C 69.3 48 58.7 48 48 48 C 48 58.7 48 69.3 48 80 C 42.7 80 37.3 80 32 80 C 32 69.3 32 58.7 32 48 C 21.3 48 10.7 48 0 48 C 0 42.7 0 37.3 0 32",
    "viewbox": [
      0,
      0,
      80,
      80
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 32 L 32 32 L 32 0 L 48 0 L 48 32 L 80 32 L 80 48 L 48 48 L 48 80 L 32 80 L 32 48 L 0 48 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "icon": "cross"
  },
  "oval": {
    "outline": "M 50 0 C 83.3 0 116.7 0 150 0 C 150 0 200 0 200 50 C 200 50 200 100 150 100 C 116.7 100 83.3 100 50 100 C 50 100 0 100 0 50 C 0 50 0 0 50 0",
    "viewbox": [
      0,
      0,
      200,
      100
    ],
    "segments": [
      {
        "type": "path",
        "d": (sx: number, sy: number) => {
          const w = 200, h = 100;
          let r = (sy < 2 * sx) ? (100*sy) / 2 : (200*sx) / 2;
          const rx = r / sx, ry = r / sy;
          return `M ${rx} 0, H ${w - rx},
          A ${rx} ${ry} 0 0 1 ${w} ${ry},
          V ${(h - ry)},
          A ${rx} ${ry} 0 0 1 ${w - rx} ${h},
          H ${rx},
          A ${rx} ${ry} 0 0 1 0 ${h - ry},
          V ${ry},
          A ${rx} ${ry} 0 0 1 ${rx} 0`;
        },
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": (sx: number, sy: number) => {
      // This code calculates a box contained inside the oval shape, so the corners
      // are at the middle of the arcs of the oval.
      // - The box is calculated so that the width is 200 and the height is 100.
      // - the radii are the same as the ones used to draw the oval.
      let r = (sy < 2 * sx) ? (100 * sy) / 2 : (200 * sx) / 2;
      const rx = r / sx, ry = r / sy;
      let xx = rx - rx / 1.4142135624; // 1.4142135624 = sqrt(2)
      let yy = ry - ry / 1.4142135624;
      xx *= 1.1; // add a bit more space...
      yy *= 1.1;
      return [xx, yy, 200 - xx * 2, 100 - yy * 2];
    },
    "icon": "oval"
  },
  "internal-storage": {
    "outline": "M 16 1 C 72 1 128 1 184 1 C 184 1 200 1 200 17 C 200 73 200 129 200 185 C 200 185 200 201 184 201 C 128 201 72 201 16 201 C 16 201 0 201 0 185 C 0 129 0 73 0 17 C 0 17 0 1 16 1",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "polygon",
        "cornerRadius": 16,
        "points": "0 0 200 0 200 200 0 200",
        "fill": "fill",
        "stroke": "stroke"
      },
      {
        "type": "path",
        "d": (sx: number, sy: number) => {
          let rx = 16 / sx;
          let ry = 16 / sy;
          return `M 0 ${ry} L 200 ${ry} M ${rx} 0 L ${rx} 200`;
        },
        "stroke": "stroke"
      }
    ],
    "textarea": (sx: number, sy: number) => {
      let rx = 22 / sx;
      let ry = 22 / sy;
      return [rx, ry, 200 - rx * 1.5, 200 - ry * 1.5];
    },
    "icon": "flowchart-internal-storage"
  },
  "flipped-trapezoid": {
    "outline": "M 200 0 C 184.7 40 169.3 80 154 120 C 118 120 82 120 46 120 C 30.7 80 15.3 40 0 0 C 66.7 0 133.3 0 200 0",
    "viewbox": [
      0,
      0,
      200,
      120
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 200 0 L 154 120 L 46 120 L 0 0 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      48,
      0,
      104,
      120
    ],
    "icon": "flipped-trapezoid",
    "anchors": {
      "left": {
        "x": -0.385,
        "y": 0,
        "rotation": 180,
        "onBoundingBox": false
      },
      "right": {
        "x": 0.385,
        "y": 0,
        "rotation": 0,
        "onBoundingBox": false
      }
    }
  },
  "delay": {
    "outline": "M 0 0 C 30 0 60 0 90 0 C 150 0 180 30 180 90 C 180 150 150 180 90 180 C 60 180 30 180 0 180 C 0 120 0 60 0 0",
    "viewbox": [
      0,
      0,
      180,
      180
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 0 H 90 C 150 0 180 30 180 90 C 180 150 150 180 90 180 H 0 V 0",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      0,
      10,
      140,
      160
    ],
    "icon": "flowchart_delay"
  },
  "rounded-triangle-up": {
    "outline": "M 84.4 5.6 C 88.3 -0.8 97.7 -0.8 101.6 5.6 C 129 51.4 156.5 97.1 183.9 142.9 C 187.9 149.5 183.1 158 175.3 158 C 120.4 158 65.6 158 10.7 158 C 2.9 158 -1.9 149.5 2.1 142.9 C 29.5 97.1 57 51.4 84.4 5.6 C 84.4 5.6 84.4 5.6 84.4 5.6",
    "viewbox": [
      0,
      0,
      186,
      161
    ],
    "segments": [
      {
        "type": "polygon",
        "cornerRadius": 10,
        "fill": "fill",
        "stroke": "stroke",
        "points": "0 161 186 161 93 0"
      }
    ],
    "textarea": [
      45,
      80,
      100,
      80
    ],
    "icon": "rounded-triangle-up",
    "anchors": {
      "left": {
        "x": -0.29,
        "y": 0,
        "rotation": 210,
        "onBoundingBox": false
      },
      "right": {
        "x": 0.29,
        "y": 0,
        "rotation": -30,
        "onBoundingBox": false
      }
    }
  },
  "rounded-triangle-down": {
    "outline": "M 101.6 152.7 C 97.7 159.2 88.3 159.2 84.5 152.7 C 57 107 29.6 61.2 2.1 15.5 C -1.9 8.8 2.9 0.4 10.7 0.4 C 65.6 0.4 120.5 0.4 175.4 0.4 C 183.2 0.4 188 8.9 184 15.5 C 156.5 61.2 129.1 107 101.6 152.7",
    "viewbox": [
      0,
      0,
      186,
      161
    ],
    "segments": [
      {
        "type": "polygon",
        "cornerRadius": 10,
        "fill": "fill",
        "stroke": "stroke",
        "points": "0 0 186 0 93 161"
      }
    ],
    "textarea": [
      45,
      0,
      100,
      80
    ],
    "icon": "rounded-triangle-down",
    "anchors": {
      "left": {
        "x": -0.29,
        "y": 0,
        "rotation": 150,
        "onBoundingBox": false
      },
      "right": {
        "x": 0.29,
        "y": 0,
        "rotation": 30,
        "onBoundingBox": false
      }
    }
  },
  "hourglass": {
    "outline": "M 200 0 C 166.7 33.3 133.3 66.7 100 100 C 133.3 133.3 166.7 166.7 200 200 C 133.3 200 66.7 200 0 200 C 33.3 166.7 66.7 133.3 100 100 C 66.7 66.7 33.3 33.3 0 0 C 66.7 0 133.3 0 200 0",
    "viewbox": [
      0,
      0,
      200,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 200 0L 100 100L 200 200H 0L 100 100L 0 0Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "icon": "hourglass",
    "anchors": {
      "left": {
        "x": "0",
        "y": "0",
        "rotation": 180,
        "onBoundingBox": true
      },
      "right": {
        "x": "0",
        "y": "0",
        "rotation": 0,
        "onBoundingBox": true
      }
    }
  },
  "flowchart-display": {
    "outline": "M 1 55 C 15.9 27.8 41.9 8.1 72.5 1 C 96.9 1 121.3 1 145.7 1 C 167.2 10.7 181 31.8 181 55 C 181 78.2 167.2 99.3 145.7 109 C 121.3 109 96.9 109 72.5 109 C 41.9 101.9 15.9 82.2 1 55 C 1 55 1 55 1 55",
    "viewbox": [
      0,
      0,
      180,
      110
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 1 55 C 15.9 27.8 41.9 8.1 72.5 1 L 145.7 1 C 167.2 10.7 181 31.8 181 55 C 181 78.2 167.2 99.3 145.7 109 L 72.5 109 C 41.9 101.9 15.9 82.2 1 55 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      40,
      16,
      120,
      75
    ],
    "icon": "flowchart-display"
  },
  "flowchart-offpage-reference": {
    "outline": "M 0 0 C 60 0 120 0 180 0 C 180 37.5 180 75 180 112.5 C 150 135 120 157.5 90 180 C 60 157.5 30 135 0 112.5 C 0 75 0 37.5 0 0",
    "viewbox": [
      0,
      0,
      180,
      180
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 0 L 180 0 L 180 112.5 L 90 180 L 0 112.5 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      10,
      0,
      160,
      120
    ],
    "icon": "flowchart-off-page-reference"
  },
  "document": {
    "outline": "M 195 0 C 197.8 0 200 2.2 200 5 C 200 38.3 200 71.7 200 105 C 166.7 87 133.3 87 100 105 C 66.7 123 33.3 123 0 105 C 0 71.7 0 38.3 0 5 C 0 2.2 2.2 0 5 0 C 68.3 0 131.7 0 195 0",
    "viewbox": [
      0,
      0,
      200,
      120
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 195 0 C 197.8 0 200 2.2 200 5 V 105 C 166.7 87 133.3 87 100 105 C 66.7 123 33.3 123 0 105 V 5 C 0 2.2 2.2 0 5 0 H 195",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      0,
      0,
      200,
      90
    ],
    "icon": "document"
  },
  "cross-in-circle": {
    "outline": "M 0 80 C 0 51.4 15.2 25 40 10.7 C 93.3 -20.1 160 18.4 160 80 C 160 108.6 144.8 135 120 149.3 C 66.7 180.1 0 141.6 0 80",
    "viewbox": [
      0,
      0,
      160,
      160
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 80 C 0 51.4 15.2 25 40 10.7 C 93.3 -20.1 160 18.4 160 80 C 160 108.6 144.8 135 120 149.3 C 66.7 180.1 0 141.6 0 80",
        "fill": "fill",
        "stroke": "stroke"
      },
      {
        "type": "path",
        "d": "M 0 80 L 160 80M 80 0 L 80 160",
        "stroke": "stroke"
      }
    ],
    "icon": "cross-in-circle"
  },
  "x-in-circle": {
    "outline": "M 0 80 C 0 51.4 15.2 25 40 10.7 C 93.3 -20.1 160 18.4 160 80 C 160 108.6 144.8 135 120 149.3 C 66.7 180.1 0 141.6 0 80",
    "viewbox": [
      0,
      0,
      160,
      160
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 80 C 0 51.4 15.2 25 40 10.7 C 93.3 -20.1 160 18.4 160 80 C 160 108.6 144.8 135 120 149.3 C 66.7 180.1 0 141.6 0 80",
        "fill": "fill",
        "stroke": "stroke"
      },
      {
        "type": "path",
        "d": "M 23.5 137 L 137 23.5 M 23.5 23.5L 137 137",
        "stroke": "stroke"
      }
    ],
    "icon": "x-in-circle"
  },
  "hline-in-diamond": {
    "outline": "M 80 0 C 106.7 26.7 133.3 53.3 160 80 C 133.3 106.7 106.7 133.3 80 160 C 53.3 133.3 26.7 106.7 0 80 C 26.7 53.3 53.3 26.7 80 0",
    "viewbox": [
      0,
      0,
      160,
      160
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 80 0 L 160 80 L 80 160 L 0 80 Z",
        "fill": "fill",
        "stroke": "stroke"
      },
      {
        "type": "path",
        "d": "M 0 80 L 160 80",
        "stroke": "stroke"
      }
    ],
    "icon": "hline-in-diamond"
  },
  "clipped-trapezoid": {
    "outline": "M 40 0 C 66.7 0 93.3 0 120 0 C 133.3 10.7 146.7 21.3 160 32 C 160 64 160 96 160 128 C 106.7 128 53.3 128 0 128 C 0 96 0 64 0 32 C 13.3 21.3 26.7 10.7 40 0",
    "viewbox": [
      0,
      0,
      160,
      128
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 40 0 L 120 0 L 160 32 L 160 128 L 0 128 L 0 32 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      20,
      20,
      120,
      105
    ],
    "icon": "clipped-trapezoid"
  },
  "sideways-hexagon": {
    "outline": "M 0 77.1 C 15 51.4 30 25.7 45 0 C 75 0 105 0 135 0 C 150 25.7 165 51.4 180 77.1 C 165 102.9 150 128.6 135 154.3 C 105 154.3 75 154.3 45 154.3 C 30 128.6 15 102.9 0 77.1",
    "viewbox": [
      0,
      0,
      180,
      155
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 77.14 L 45 0 L 135 0 L 180 77.14 L 135 154.29 L 45 154.29 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      40,
      12,
      100,
      130
    ],
    "icon": "sideways-hexagon"
  },
  "rounded-parallelogram": {
    "outline": "M 77 121 C 55 121 33 121 11 121 C 3.7 121 1.3 117.6 3.9 110.7 C 16.6 77.6 29.4 44.4 42.1 11.3 C 44.7 4.4 49.7 1 57 1 C 101 1 145 1 189 1 C 196.3 1 198.7 4.4 196.1 11.3 C 183.4 44.4 170.6 77.6 157.9 110.7 C 155.3 117.6 150.3 121 143 121 C 121 121 99 121 77 121",
    "viewbox": [
      0,
      0,
      198,
      121
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 77 121 L 11 121 Q 0 121 3.9 110.7 L 42.1 11.3 Q 46 1 57 1 L 189 1 Q 200 1 196.1 11.3 L 157.9 110.7 Q 154 121 143 121 Z",
        "fill": "fill",
        "stroke": "stroke"
      }
    ],
    "textarea": [
      47,
      7,
      105,
      105
    ],
    "icon": "rounded-parallelogram",
    "anchors": {
      "left": {
        "x": -0.38383838,
        "y": 0,
        "rotation": 180,
        "onBoundingBox": false
      },
      "right": {
        "x": 0.393939393939394,
        "y": 0,
        "rotation": 0,
        "onBoundingBox": false
      },
      "top": {
        "x": 0.125,
        "y": -0.5,
        "rotation": 270,
        "onBoundingBox": false
      },
      "buttom": {
        "x": -0.125,
        "y": 0.5,
        "rotation": 90,
        "onBoundingBox": false
      }
    }
  },
  "note": {
    "viewbox": [
      0,
      0,
      135,
      200
    ],
    "segments": [
      {
        "type": "path",
        "d": "M 0 0 H 87.3 L 135 46 V 200 H 0 Z",
        "stroke": "stroke",
        "fill": "fill"
      },
      {
        "type": "path",
        "d": "M 87.34 0 L 87.34 46 L 135 46",
        "stroke": "stroke"
      }
    ],
    "outline": "M 0 0 H 87.3 L 135 46 V 200 H 0 Z",
    "textarea": [
      7,
      52,
      120,
      140
    ],
    "icon": "note"
  }
}

export default data;