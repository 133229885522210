import { useEffect, useState, useRef, useCallback, CSSProperties } from "react";
import React from "react";
import { Html } from "react-konva-utils";
import { FontProperties, TextAlignment } from "shared/datamodel/schemas/textEnabled";
import { konvaTextDecoration } from "shared/util/utils";
import consts from "shared/consts";
import { handleTabInTextArea } from "./canvas-designer-new/text-element";

export function EditableTextInput({
  id,
  x,
  y,
  width,
  height,
  paddingX = 0,
  paddingY = 0,
  scaleX = 1,
  scaleY = 1,
  isWide = false,
  value,
  placeholder,
  isFixedHeight,
  fontSize,
  font,
  fill,
  onStopEditing,
  onChange,
  onTextHeightChange,
  align = "left",
  verticalAlign = "top",
  fontProps = FontProperties.None,
  showBorder = false,
}: {
  id: string;
  x?: number;
  y?: number;
  width: number;
  height: number;
  paddingX?: number;
  paddingY?: number;
  scaleX?: number;
  scaleY?: number;
  isWide?: boolean;
  value: string;
  placeholder: string;
  isFixedHeight: boolean;
  fontSize: number;
  font: string;
  fill: string;
  onStopEditing?: () => void;
  onChange: (value: string) => void;
  onTextHeightChange?: (height: number) => void;
  align?: TextAlignment;
  verticalAlign?: "top" | "bottom" | "middle";
  fontProps?: FontProperties;
  showBorder?: boolean;
}) {

  const isMiddle = verticalAlign === "middle";
  function getStyle(width: number, height: number, isMiddle: boolean) {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    const baseStyle = {
      // borderRadius: "10px",
      border: showBorder ? "2px solid var(--blue)" : "none",
      width: `${width}px`,
      padding: "0px",
      margin: "0px",
      background: "none",
      outline: "none",
      color: fill,
      fontSize: `${fontSize}px`,
      fontFamily: font,
      lineHeight: consts.LINE_HEIGHT,
      overflow: "hidden",
      pointerEvents: "all",
      cursor: "text",
      textAlign: align,
      verticalAlign: verticalAlign,
      fontWeight: getFontWeight(),
      fontStyle: fontProps & FontProperties.Italic ? "italic" : "normal",
      textDecoration: konvaTextDecoration(fontProps),
    } as CSSProperties;
    if (isFirefox) {
      return baseStyle;
    }
    return {
      ...baseStyle,
      // marginTop: "-4px",
    };
  }

  function getFontWeight() {
    if (fontProps & FontProperties.Bold) {
      return "bold";
    }
    if (fontProps & FontProperties.Light) {
      return "300";
    }
    return "normal";
  }

  function getDivStyle(height: number, isMiddle: boolean, isFixedHeight: boolean) {
    if (isFixedHeight) {
      if (isMiddle) {
        return {
          opacity: 1,
          height: `${height}px`,
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
          alignItems: "flex-start",
          overflow: "hidden",
          pointerEvents: "none",
        } as CSSProperties;
      }
      return {
        opacity: 1,
        height: `${height}px`,
        overflow: "hidden",
        pointerEvents: "none",
      } as CSSProperties;
    }

    return {
      opacity: 1,
      overflow: "hidden",
      pointerEvents: "none",
    };
  }

  const [areaHeight, setAreaHeight] = useState(height); // divide by scaleY fix de-scaling
  const [text, setText] = useState(value);
  const ref = useRef<HTMLTextAreaElement>(null);
  const inputStyle = getStyle(width / scaleX - 2 * paddingX, areaHeight, isMiddle);
  const divStyle = getDivStyle(height / scaleY - 2 * paddingY, isMiddle, isFixedHeight);

  function recalcHeight(node: HTMLTextAreaElement): number {
    node.style.height = "0";
    node.style.fontSize = fontSize + "px";
    let newHeight = node.scrollHeight;
    node.style.height = `${newHeight}px`;
    return newHeight;
  }

  useEffect(() => {
    if (ref.current) {
      const node = ref.current;
      let newHeight = recalcHeight(node);
      setAreaHeight(newHeight);
      let h = newHeight * scaleY;
      onTextHeightChange && onTextHeightChange(h);
    }
  }, [fontSize, isFixedHeight, fontProps, scaleY, height, width, isWide, font]);

  const elRef = useCallback((node: HTMLTextAreaElement) => {
    if (ref.current) {
      // cleanup previous callbacks
    }
    // initialize the new element here
    if (node != null) {
      node.select();
      node.focus();
      recalcHeight(node);
    }
    //save reference for later (if needed)
    (ref as React.MutableRefObject<HTMLTextAreaElement>).current = node;
  }, []);

  function handleKeyDown(e: React.KeyboardEvent) {
    switch (e.key) {
      case "Tab":
        handleTabInTextArea(e);
        break;
      case "Escape":
        if (onStopEditing) {
          e.preventDefault();
          onStopEditing();
        }
        break;
    }
  }

  return (
    <Html
      groupProps={{ x: (x || 0) + paddingX * scaleX, y: (y || 0) + paddingY * scaleY, scaleX, scaleY }}
      divProps={{ style: divStyle }}
    >
      <div className="spacer" />
      <textarea
        ref={elRef}
        value={text}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          const node = e.target;
          const text = e.target.value;
          let newHeight = recalcHeight(node);
          setAreaHeight(newHeight);
          setText(text);
          onChange(text);
          let h = newHeight * scaleY;
          onTextHeightChange && onTextHeightChange(h);
        }}
        style={{ ...inputStyle, resize: "none" }}
      />
      <div className="spacer" />
    </Html>
  );
}
