import { Line } from "react-konva";
import { Drawing } from "shared/datamodel/schemas/drawing";
import { parseStrokeWidth } from "shared/util/utils";
import { ITraits } from "../elements-toolbar/elements-toolbar-types";

export default function DrawingCanvasElement({ element }: { element: Drawing }) {
  const { points, stroke, strokeWidth } = element;
  return (
    <Line
      points={points}
      stroke={stroke}
      strokeWidth={parseStrokeWidth(strokeWidth)}
      perfectDrawEnabled={false}
      shadowForStrokeEnabled={false}
      tension={0.4}
      lineCap="round"
    />
  );
}



export function drawingTraits(element: Drawing): ITraits {
  return {
    lineColor: element.stroke,
    drawingLineWidth: element.strokeWidth,
  };
}
