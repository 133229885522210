import React, { useEffect, useState } from "react";

export function useHover(ref: React.RefObject<Element>) {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    function handler(e: Event) {
      setHover(e.type == "mouseenter");
    }
    if (ref.current) {
      ref.current.addEventListener("mouseenter", handler);
      ref.current.addEventListener("mouseleave", handler);
      return () => {
        if (ref.current) {
          ref.current.removeEventListener("mouseenter", handler);
          ref.current.removeEventListener("mouseleave", handler);
        }
      };
    }
  }, [ref.current]);

  return hover;
}
