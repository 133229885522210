import Hoverable from "frontend/ui-components/hoverable";
import FlexBox from "frontend/ui-components/konva-box";
import SomeImage from "frontend/ui-components/some-image";
import { useState } from "react";
import { Group, Circle } from "react-konva";
import { CanvasArrow } from "../utility-elements";

export default function MondayTaskPreviewAssetsCarousel({ assets, size }: { assets: any[]; size: number }) {
  const [hovered, setHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (!assets || assets.length === 0) {
    return null;
  }

  const padding = 12;

  const index = Math.min(Math.max(currentImageIndex, 0), assets.length - 1);

  const currentAsset = assets[index];

  if (!currentAsset) {
    return null;
  }

  const renderNextArrow = hovered && index < assets.length - 1;
  const renderPrevArrow = hovered && index > 0;


  return (
    <FlexBox padding={padding}>
      <Group>
        <Hoverable hoverFill="#EFEFEF" autoSize={true} hoverChange={(hover: boolean) => setHovered(hover)} />
        <SomeImage
          src={currentAsset.url}
          size={{ width: size - padding * 2, height: size - padding * 2 }}
          clipPosition="aspect-fit"
        />
        {renderNextArrow && (
          <Group x={size - 46} y={size / 2 - 20} onClick={() => setCurrentImageIndex((index) => index + 1)}>
            <Hoverable autoSize={true} cursor="pointer" />
            <Circle radius={14} fill="white" />
            <CanvasArrow x={4} pointerLength={5} pointerWidth={5} stroke="black" />
          </Group>
        )}
        {renderPrevArrow && (
          <Group x={23} y={size / 2 - 20} onClick={() => setCurrentImageIndex((index) => index - 1)}>
            <Hoverable autoSize={true} cursor="pointer" />
            <Circle radius={14} fill="white" />
            <CanvasArrow x={-3} pointerLength={5} pointerWidth={5} stroke="black" rotation={180} />
          </Group>
        )}
      </Group>
    </FlexBox>
  );
}
