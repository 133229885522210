import consts, { TypeCanvasElement } from "shared/consts";
import { getElementGraphicsProvider, getElementProvider } from "elements/index";
import type { TransformerConfig } from "elements/base/types";

export function isElementConnectable(type: TypeCanvasElement): boolean {
  const provider = getElementProvider(type);
  if (provider && provider.isConnectorsEnabled) {
    return provider.isConnectorsEnabled();
  }
  switch (type) {
    case consts.CANVAS_ELEMENTS.SHAPE:
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
    case consts.CANVAS_ELEMENTS.DRAWING:
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
    case consts.CANVAS_ELEMENTS.FILE:
    case consts.CANVAS_ELEMENTS.TASK_CARD:
    case consts.CANVAS_ELEMENTS.FRAME:
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.TABLE:
      return true;
    case consts.CANVAS_ELEMENTS.CONNECTOR:
    case consts.CANVAS_ELEMENTS.COMMENT:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
    case consts.CANVAS_ELEMENTS.ORG_CHART:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
    case consts.CANVAS_ELEMENTS.TIMELINE:
      return false;
    default:
      console.warn(`Unknown element type: ${type}`);
      return false;
  }
}

export function isElementLinkBadgeSupporting(type: TypeCanvasElement): boolean | null {
  const provider = getElementProvider(type);
  if (provider) {
    return provider.isLinkBadgeEnabled();
  }
  switch (type) {
    case consts.CANVAS_ELEMENTS.SHAPE:
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
    case consts.CANVAS_ELEMENTS.DRAWING:
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
    case consts.CANVAS_ELEMENTS.FILE:
    case consts.CANVAS_ELEMENTS.TASK_CARD:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
      return true;
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
    case consts.CANVAS_ELEMENTS.ORG_CHART:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
    case consts.CANVAS_ELEMENTS.TIMELINE:
    case consts.CANVAS_ELEMENTS.TABLE:
      return false;
    case consts.CANVAS_ELEMENTS.CONNECTOR:
    case consts.CANVAS_ELEMENTS.FRAME:
    case consts.CANVAS_ELEMENTS.COMMENT:
      return null;
    default:
      console.warn(`Unknown element type: ${type}`);
      return false;
  }
}

export function isElementVotable(type: TypeCanvasElement): boolean | null {
  const provider = getElementProvider(type);
  if (provider) {
    return provider.isVotingEnabled();
  }
  switch (type) {
    case consts.CANVAS_ELEMENTS.SHAPE:
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
    case consts.CANVAS_ELEMENTS.DRAWING:
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
    case consts.CANVAS_ELEMENTS.FILE:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.TABLE:
      return true;
    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
    case consts.CANVAS_ELEMENTS.ORG_CHART:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
    case consts.CANVAS_ELEMENTS.TIMELINE:
      return false;
    case consts.CANVAS_ELEMENTS.CONNECTOR:
    case consts.CANVAS_ELEMENTS.TASK_CARD:
    case consts.CANVAS_ELEMENTS.FRAME:
    case consts.CANVAS_ELEMENTS.COMMENT:
      return null;
    default:
      console.warn(`Unknown element type: ${type}`);
      return false;
  }
}

export function textEnabledForElementType(type: TypeCanvasElement) {
  const provider = getElementProvider(type);
  if (provider) {
    return provider.isTextEnabled();
  }
  switch (type) {
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
    case consts.CANVAS_ELEMENTS.SHAPE:
      return true;
    case consts.CANVAS_ELEMENTS.FRAME:
    case consts.CANVAS_ELEMENTS.CONNECTOR:
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
    case consts.CANVAS_ELEMENTS.DRAWING:
    case consts.CANVAS_ELEMENTS.FILE:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.TIMELINE:
    case consts.CANVAS_ELEMENTS.TABLE:
      return false;
    default:
      return false;
  }
}

export function getSpecialTransformerConfig(type: TypeCanvasElement): Partial<TransformerConfig> {
  const provider = getElementGraphicsProvider(type);
  if (provider) {
    return provider.getTransformerConfig();
  }
  switch (type) {
    case consts.CANVAS_ELEMENTS.SHAPE:
    case consts.CANVAS_ELEMENTS.CONNECTOR:
    case consts.CANVAS_ELEMENTS.DRAWING:
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
    case consts.CANVAS_ELEMENTS.FILE:
    case consts.CANVAS_ELEMENTS.TASK_CARD:
    case consts.CANVAS_ELEMENTS.FRAME:
    case consts.CANVAS_ELEMENTS.COMMENT:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.ORG_CHART:
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
    case consts.CANVAS_ELEMENTS.TABLE:
      return {};
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
      return {
        showSnapGuidelines: true,
        snapToGuidelines: false,
      };
    case consts.CANVAS_ELEMENTS.TIMELINE:
      return {
        showSnapGuidelines: false,
        snapToGuidelines: false,
      };
    default:
      return {};
  }
}
