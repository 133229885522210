/**
 * Interface representing an observer function.
 */
interface Observer {
  (): void;
}

/**
 * Interface representing an observable entity.
 */
interface Observable {
  /**
   * Subscribes an observer to the observable.
   * @param observer - The observer function to be added.
   */
  subscribe(observer: Observer): void;

  /**
   * Unsubscribes an observer from the observable.
   * @param observer - The observer function to be removed.
   */
  unsubscribe(observer: Observer): void;

  /**
   * Notifies all subscribed observers.
   */
  notify(): void;
}

/**
 * Interface extending Observable for controllers.
 */
export interface IObservableController extends Observable {}

/**
 * Class implementing the ObservableController interface.
 */
export class ObservableController implements IObservableController {
  private observers: Set<Observer> = new Set();

  /**
   * Subscribes an observer to the observable.
   * @param observer - The observer function to be added.
   */
  subscribe(observer: Observer) {
    this.observers.add(observer);
  }

  /**
   * Unsubscribes an observer from the observable.
   * @param observer - The observer function to be removed.
   */
  unsubscribe(observer: Observer) {
    this.observers.delete(observer);
  }

  /**
   * Notifies all subscribed observers.
   */
  notify() {
    this.observers.forEach((observer) => observer());
  }
}
