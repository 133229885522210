import React, { useEffect, useRef } from "react";
import { Text } from "react-konva";
import { FontProperties } from "shared/datamodel/schemas/textEnabled";
import { fontPropertiesToString, konvaTextDecoration } from "shared/util/utils";
import consts from "shared/consts";

export function ResizableText({
  x,
  y,
  text,
  placeholder,
  fontSize,
  fill,
  width,
  height,
  paddingX = 0,
  paddingY = 0,
  scaleX = 1,
  scaleY = 1,
  align = "left",
  verticalAlign = "top",
  fontProps = FontProperties.None,
  onHeightChange = null,
  font
}: {
  x?: number;
  y?: number;
  text: string;
  placeholder: string;
  fontSize: number;
  fill: string;
  width: number;
  height?: number;
  paddingX?: number;
  paddingY?: number;
  scaleX?: number;
  scaleY?: number;
  align?: string;
  verticalAlign?: string;
  fontProps?: FontProperties;
    onHeightChange?: null | ((newHeight: number) => void);
    font: string;
}) {
  const textRef = useRef<any>(null);
  const previousHeight = useRef<number>(0);

  // after every render check the height of the text and call onHeightChange if it changed
  // it's best to do this on every render with no dependencies.
  useEffect(() => {
    if (textRef.current && textRef.current.height() != previousHeight.current) {
        let h = textRef.current.height() as number;
        h = h * scaleY;
        if (h != previousHeight.current && previousHeight.current != 0) {
          onHeightChange && onHeightChange(h);
        }
        previousHeight.current = h;
    }
  });

  x = (x || 0) + paddingX * scaleX;
  y = (y || 0) + paddingY * scaleY;
  width = width / scaleX - paddingX * 2;
  let maybeHeight = height;
  if (maybeHeight) {
    maybeHeight = maybeHeight / scaleY - paddingY * 2;
  } else {
    width = Math.max(width, fontSize);
  }

  /*
The area that the (konva) text element gets can be visualised with:
<Rect x={x} y={y} width={width} height={maybeHeight} scaleX={scaleX} scaleY={scaleY}/>

But that actual text area can only be computed if we don't specify width and height, then konva
calculates the actual size

and then:
W = real width
<Rect x={x + scaleX*width/2 - scaleX*W/2} y={y} width={W} height={maybeHeight} scaleX={scaleX} scaleY={scaleY} fill="yellow" /> 
  */
  return (
    <Text
      ref={textRef}
      x={x}
      y={y}
      width={width}
      height={maybeHeight}
      scaleX={scaleX}
      scaleY={scaleY}
      fill={fill}
      opacity={text ? 1 : 0.3}
      text={text || placeholder}
      fontFamily={font}
      lineHeight={consts.LINE_HEIGHT}
      fontSize={fontSize}
      fontStyle={fontPropertiesToString(fontProps)}
      textDecoration={konvaTextDecoration(fontProps)}
      ellipsis={false}
      wrap={"word"}
      perfectDrawEnabled={false}
      isText={true}
      align={align}
      verticalAlign={verticalAlign}
      listening={false}
    />
  );
}
