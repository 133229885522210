import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let ThreeDots = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <IconBase
      ref={ref}
      renderPath={(color: string) => {
        return (
          <path
            fillRule="evenodd"
            d="M5 9.5C5 10.3284 4.32843 11 3.5 11C2.67157 11 2 10.3284 2 9.5C2 8.67157 2.67157 8 3.5 8C4.32843 8 5 8.67157 5 9.5ZM11.4 9.5C11.4 10.3284 10.7284 11 9.9 11C9.07157 11 8.4 10.3284 8.4 9.5C8.4 8.67157 9.07157 8 9.9 8C10.7284 8 11.4 8.67157 11.4 9.5ZM16.3 11C17.1284 11 17.8 10.3284 17.8 9.5C17.8 8.67157 17.1284 8 16.3 8C15.4716 8 14.8 8.67157 14.8 9.5C14.8 10.3284 15.4716 11 16.3 11Z"
            fill={color}
          />
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

ThreeDots.displayName = "ThreeDots";

export { ThreeDots };


