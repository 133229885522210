export const enum ResizeType {
  None = 0,
  Corners = 1,
  Sides = 2,
  TopBottom = 4,
  Right = 8,
  SidesAndTop = Sides | TopBottom,
  All = Corners | Sides | TopBottom,
}

export interface TransformerConfig {
  shouldOverdrawWholeArea: boolean;
  keepRatio: boolean;
  lockAspectRatio: boolean;
  showSnapGuidelines: boolean;
  snapToGuidelines: boolean;
  flipEnabled: boolean;
  replaceAnchorsWithEdgeResize: boolean;
  borderStrokeWidth: number;
  anchorStrokeWidth: number;
  borderEnabled: boolean;
  rotateEnabled: boolean;
  rotationSnapTolerance: number;
}

/**
 * All data about closest point on outline
 */
export interface IClosestPointOnOutline {
  /** closest point coordinates */
  xy: { x: number; y: number };
  /** closest point parameter (0..1) */
  t: number;
  /** distance from outline to point */
  distance: number;
  /** normal direction at outline's closest point (degrees) */
  normalDirectionDegrees: number;
}

export const NoneClosestPoint: IClosestPointOnOutline = Object.freeze({
  distance: Infinity,
  normalDirectionDegrees: 0,
  t: 0,
  xy: { x: 0, y: 0 },
});
