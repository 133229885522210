import React, { forwardRef } from "react";
import IconBase, { IconProps } from "./icon-base";

let TrashIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  // parse width and height from props as number
  let width = props.width ?? props.size ?? 14;
  if (typeof width === "string") {
    width = parseInt(width);
  }
  let height = props.height ?? props.size ?? width / (14 / 15);
  if (typeof height === "string") {
    height = parseInt(height);
  }

  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 14 15"
      ref={ref}
      renderPath={(color: string) => {
        return (
          <>
            <path
              d="M1 3.88892H12.5556"
              stroke={color}
              strokeLinecap="square"
              strokeLinejoin="round"
              fill={"transparent"}
            />
            <path
              d="M5.3335 6.77783V11.1112"
              stroke={color}
              strokeLinecap="square"
              strokeLinejoin="round"
              fill={"transparent"}
            />
            <path
              d="M8.22217 6.77783V11.1112"
              stroke={color}
              strokeLinecap="square"
              strokeLinejoin="round"
              fill={"transparent"}
            />
            <path d="M2 4.5L2.5 14H11L11.5 4" stroke={color} strokeLinecap="square" fill={"transparent"} />
            <path
              d="M4.61084 3.88889V1.72222C4.61084 1.53068 4.68693 1.34698 4.82237 1.21153C4.95782 1.07609 5.14152 1 5.33306 1H8.22195C8.4135 1 8.5972 1.07609 8.73264 1.21153C8.86808 1.34698 8.94417 1.53068 8.94417 1.72222V3.88889"
              stroke={color}
              strokeLinecap="square"
              strokeLinejoin="round"
              fill={"transparent"}
            />
          </>
        );
      }}
      {...props}
    >
      {props.children}
    </IconBase>
  );
});

TrashIcon.displayName = "TrashIcon";

export { TrashIcon };
