export const CloseIcon = ({
  onClick,
  className,
  color = "#848199",
}: {
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  className?: string;
  color?: string;
}) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      className={className}
      onClick={onClick}
    >
      <path d="M1 1.00195L8.99928 9.0009" stroke={color} strokeLinecap="round" />
      <path d="M9 1L1.00072 8.99895" stroke={color} strokeLinecap="round" />
    </svg>
  );
};
