import consts from "shared/consts";
import { defaultShapeDimensions } from "shared/datamodel/schemas";
import ShapesData from "frontend/data/shapes/shapes-visuals";

export function getDefaultSizeForShape(type: string, subtype?: string) {
  if (type != consts.CANVAS_ELEMENTS.SHAPE) {
    return defaultShapeDimensions;
  }
  let viewbox = (ShapesData as any)[subtype!]!.viewbox;
  const [, , width, height] = viewbox;
  return { width, height };
}
