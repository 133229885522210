import type { ElementProvider } from "elements/base/provider";
import type { ElementGraphicsProvider } from "elements/base/graphics";

class ElementProvidersRegistry<T> {
  private providers: Map<string, T> = new Map();

  registerProvider(type: string, provider: T) {
    this.providers.set(type, provider);
  }

  getProvider(type: string): T | undefined {
    return this.providers.get(type);
  }
}

export const elementProvidersRegistry = new ElementProvidersRegistry<ElementProvider<any>>();
export const elementGraphicsProvidersRegistry = new ElementProvidersRegistry<ElementGraphicsProvider<any>>();
