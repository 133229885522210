import React, { CSSProperties, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useThrottle, useUnmount } from "react-use";

import Konva from "konva";
import type { KonvaEventObject } from "konva/types/Node";
import { Circle, Group, Line, Rect, Shape, Text } from "react-konva";
import { Html, Portal } from "react-konva-utils";
import { useAtomValue, useSetAtom } from "jotai";

import { enablePatches, produceWithPatches } from "immer";
import { useImmerReducer } from "use-immer";

import { replaceColorOpacity } from "frontend/utils/color-utils";
import { noop, unique } from "frontend/utils/fn-utils";
import { clamp, Point, viewportToStage } from "frontend/utils/math-utils";
import { defaultTextStyleForTable, TypeTableCell, TypeTableElement } from "shared/datamodel/schemas/table";
import {
  internalSelectionAtom,
  transformerRefAtom,
  posScaleAtom,
  layerRefAtom,
  selectedElementIdsAtom,
  documentIdAtom,
  utilsAtom,
  isAnyKindOfExportAtom,
} from "state-atoms";
import { ITraits, Trait } from "../../elements-toolbar/elements-toolbar-types";

import { EVT_ELEMENT_DROP, EVT_ELEMENT_DRAG_START, EVT_ELEMENT_DRAG } from "../card-stack/card-stack-utils";
import consts from "shared/consts";
import { getElementTypeForId, isOfTypes, replaceGroupsAfterCopy } from "../canvas-elements-utils";
import { handleTabInTextArea } from "../../text-element/text-utils";
import {
  useSubscribeCellsData,
  cellPadding,
  cellNanoid,
  useSubscribeContainedElements,
  fullCellKey,
  TableIds,
  computeCoordinatesOfLines,
  splitKey,
} from "./table-utils";
import * as KeyboardHandler from "./table-keyboard-shortcuts";
import { LineCap } from "konva/types/Shape";
import { SyncService } from "frontend/services/syncService";
import Modal from "frontend/modal/modal";
import EditElementLinkModal from "frontend/modals/edit-element-link-modal";
import { PatchAnything } from "frontend/canvas-designer-new";
import { TransformHooks } from "frontend/hooks/use-transform-hooks";
import BoundingBox from "frontend/geometry/bounding-box";
import * as R from "rambda";
import { getTransformParams, measureNodes } from "frontend/utils/node-utils";
import { TableTitle } from "./table-title";
import {
  getColumnsThatAreFullySelected,
  getRowsThatAreFullySelected,
  initialTableState,
  isSelectionEmpty,
  selectionCells,
  tableReducer,
  TableState,
} from "./table-selection";
import { TableCellsInfo } from "./table-info";
import { useBgJob } from "frontend/hooks/use-bg-worker";
import { LockType } from "shared/datamodel/schemas";
import useAnyEvent from "frontend/hooks/use-any-event";
import {
  canvasElementPrefix,
  createElementId,
  dbKey,
  fontPropertiesToString,
  konvaTextDecoration,
} from "shared/util/utils";
import { textEnabledTraits } from "../text-block-element";
import { ActionHandler, actions } from "./table-actions";
import type { TableAction, ActionHandlers } from "./table-actions";
import { DraggableTableLines } from "./table-controls";
import type { SinglePatch } from "frontend/canvas-designer-new/index"

enablePatches();

const SelectedCellStroke = "#00a1ff";

function onDropElementsOnTable(cellKeyInReflect: string, cellData: any, ids: string[]) {
  let patch = [];
  const [_, cellPatch, cellInversePatch] = produceWithPatches((draft: any) => {
    let curContained = draft.containedIds ?? [];
    draft.containedIds = unique(curContained.concat(ids));
  })(cellData ?? {});
  patch.push({ id: cellKeyInReflect, patch: cellPatch, inversePatch: cellInversePatch });
  return patch;
}

let tableClipboard: any = null;

const isDroppableOnTable = isOfTypes(
  consts.CANVAS_ELEMENTS.STICKY_NOTE,
  consts.CANVAS_ELEMENTS.TEXT_BLOCK,
  consts.CANVAS_ELEMENTS.DRAWING,
  consts.CANVAS_ELEMENTS.SHAPE,
  consts.CANVAS_ELEMENTS.FILE
);

type OverrideSize = null | Record<string, number>;

/**
 * A function to collect the 'containedIds' arrays from the cells object
 * @param cells - the cells object: { cellId: null | { containedIds?: string[] } }
 * @returns an array of all the containedIds
 */
const getAllContainedIdsInTable = (cells: Record<string, { containedIds?: string[] }>) =>
  R.values(cells)
    .flatMap((x) => x.containedIds)
    .filter(Boolean) as string[];

function getCellsForOfTable(tableId: string, allElementsData: Array<[string, any]>): Record<string, any> {
  const uid = new TableIds(tableId).justTableId();
  let cells: Record<string, any> = {};
  for (const [key,element] of allElementsData) {
    if (key.includes(uid) && key.startsWith(consts.CANVAS_ELEMENTS.TABLE_CELL)) {
      cells[canvasElementPrefix + key] = element;
    }
  }
  return cells;
}

// --------------------------------------------------------------------

export const TableElement = React.memo(
  ({
    syncService,
    allElementsData,
    id,
    element,
    isSelected,
    isSelectable,
    isReadOnly,
    patchAnything,
    onResize,
    isEditingLink,
    changeElementLink,
    renderLink,
  }: {
    syncService?: SyncService;
    allElementsData?: Array<[string, any]>;
    id: string;
    element: TypeTableElement;
    isSelected: boolean;
    isSelectable: boolean;
    isReadOnly: boolean;
    patchAnything: PatchAnything;
    onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
    // for html links
    isEditingLink: boolean;
    changeElementLink: (element: any, newLink?: string) => void;
    renderLink: (element: any) => any;
  }) => {
    const ref = useRef<Konva.Rect>(null);
    const layerRef = useAtomValue(layerRefAtom);
    const canvasUtilsAtom = useAtomValue(utilsAtom(useAtomValue(documentIdAtom).documentId));

    // I'm conditionally activating hooks here. React forbids this, but I know that in a given canvas-stage
    // only one hook will ever be called because allElements either defined or not, and never changes per canvas.
    // This is still a terrible practice. we should abstract away data-fetching hook.
    const cells = allElementsData
      ? useMemo(() => getCellsForOfTable(id, allElementsData), [id, allElementsData])
      : useSubscribeCellsData(syncService?.getReplicache(), id);

    const [drag, setDrag] = useState({ col: -1, row: -1, dx: 0, dy: 0 }); // TODO: unify with overrideSize (track dragged by handles) TODO: allow multiple dragged tracks
    const setSelectedIds = useSetAtom(selectedElementIdsAtom);

    let containedIds = getAllContainedIdsInTable(cells);

    const { scaleX = 1, scaleY = 1 } = element;
    const locked = element.lock || isReadOnly;

    const containedElements = useSubscribeContainedElements(syncService?.getReplicache(), containedIds, allElementsData);
    const [state, dispatch] = useImmerReducer(tableReducer, null, initialTableState);
    const setInternalSelection = useSetAtom(internalSelectionAtom);
    const [overrideSize, setoverrideSize] = useState<OverrideSize>(null);
    const [highlightCell, setHighlightCell] = useState<null | { col: number; row: number }>(null);
    const [capturableElmsDrag, setCapturableElmsDrag] = useState<null | string[]>(null);
    const [trackResizing, setTrackResizing] = useState<any>(null);

    const resizer = useRef<TrackResizer | null>(null);
    const resizeContained = useRef<ResizeContainedListener | null>(null);

    // when this element selection state is changed we reset our internal state
    useEffect(() => {
      if (!isSelected) dispatch({ type: "reset" });
    }, [isSelected]);

    // when we're selected and we have a cell selected, we set an atom to reflect that
    // so canvas-stage won't handle clipboard events (because we do)
    // This can be avoided if we have a system for handling events in a more organized way,
    // like the keyboard shortcuts system.
    useEffect(() => {
      const keys = new TableIds(id);
      if (isSelected && !isSelectionEmpty(state.selection)) {
        // if we're selected and we have selected cells also, place them in the internal-selection
        const info = new TableCellsInfo(element.cols, element.rows, xs, ys);
        setInternalSelection((prev) =>
          [...selectionCells(state.selection, info)].map((cell) => keys.shortId(cell.col.key, cell.row.key))
        );
      } else {
        // else, clear the internal-selection from our cells
        const isCellFromThisTable = R.startsWith(keys.commonPrefix());
        setInternalSelection((prev) => prev.filter((id) => !isCellFromThisTable(id)));
      }
    }, [id, element.cols.length, element.rows.length, isSelected, state.selection]);

    if (locked) {
      patchAnything = noop;
    }

    let xs = computeCoordinatesOfLines(element.cols, overrideSize);
    let ys = computeCoordinatesOfLines(element.rows, overrideSize);

    //TODO: only needed when dragging a track line, or when contained-elements changed
    // and not even for all rows, just a single track
    let minColSize: number[] = new Array(element.cols.length).fill(10);
    let minRowSize: number[] = new Array(element.rows.length).fill(10);
    for (const cellid in cells) {
      const cell = cells[cellid];
      if (cell.containedIds?.length) {
        const bbox = R.reduce(
          BoundingBox.concat,
          BoundingBox.empty(),
          cell.containedIds
            ?.filter((id: string) => !!containedElements[id as any])
            .map((id: string) =>
              BoundingBox.fromRect(getTransformParams(getElementTypeForId(id), containedElements[id]))
            )
        );
        // The cell's containedIds array can have ids of deleted elements.
        // containedElements won't have them, and then the bounding box is the default
        // one, which isn't valid
        if (!BoundingBox.isValid(bbox)) {
          continue;
        }

        const { col, row } = splitKey(cellid);
        const colN = element.cols.findIndex((c) => c.id == col);
        const rowN = element.rows.findIndex((r) => r.id == row);
        minColSize[colN] = (bbox.right - (xs[colN] * scaleX + element.x)) / scaleX;
        minRowSize[rowN] = (bbox.bottom - (ys[rowN] * scaleY + element.y)) / scaleY;
      }
    }

    const info = new TableCellsInfo(element.cols, element.rows, xs, ys);

    useAnyEvent("transform-start", (ev: CustomEvent<{ nodes: any[] }>) => {
      let nodes = ev.detail.nodes;
      if (!nodes || nodes.length == 0 || nodes.some((node) => node.attrs.id == id)) {
        return
      }
      nodes = ev.detail.nodes.filter((node) => containedIds.includes(node.id()));

      if (nodes.length) {
        // create a mapping from every node being resized to its containing cell
        let ids = nodes.map((n) => n.id());
        let mapping: any = {};
        for (const cellId in cells) {
          const cell = cells[cellId];
          if (cell.containedIds?.length) {
            const shapesBelongingToCell = R.intersection(cell.containedIds, ids);
            shapesBelongingToCell.forEach((id) => (mapping[id] = TableIds.getColAndRow(cellId)));
          }
        }
        // set the data in a state that will start a listener to track the resizing
        if (resizeContained.current != null) {
          console.error("Forgot to cleanup resizer");
        }
        resizeContained.current = new ResizeContainedListener(
          id,
          element,
          getContainedElementsInCell,
          patchAnything,
          true,
          10
        );
        setTrackResizing({
          nodes,
          mapping,
          setoverrideSize: ({ requiredXs, requiredYs }: { requiredXs: any; requiredYs: any }) => {
            if (resizeContained.current) {
              for (let key in requiredXs) {
                resizeContained.current.updateXline(key, requiredXs[key]);
              }
              for (let key in requiredYs) {
                resizeContained.current.updateYline(key, requiredYs[key]);
              }
            }
            //TODO: maybe set-override-size here to see immediate effect on the screen
          },
        });
      }
    });

    function canICaptureElements(ids: string[]): null | string[] {
      const areWeOnStage = Boolean(ref.current?.getStage());
      if (!areWeOnStage) return null;

      // We check that dragged items are all allowed to be dropped on the table.
      return ids.every(isDroppableOnTable) ? ids : null;
    }

    useAnyEvent(EVT_ELEMENT_DRAG_START, (ev) => {
      if (!ev.detail?.ids?.length) return;
      const capturableItems = canICaptureElements(ev.detail.ids);
      if (!capturableItems?.length) return;
      // Elements that are being dragged and are contained in the table are released here
      window.setTimeout(() => {
        let patches: any = [];
        for (const [cellid, value] of Object.entries(cells)) {
          if (value.containedIds?.some((id: string) => ev.detail.ids.includes(id))) {
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              draft.containedIds = draft.containedIds.filter((id: string) => !ev.detail.ids.includes(id));
            })(cells[cellid]);
            patches.push({ id: cellid, patch, inversePatch });
          }
        }
        if (patches.length) patchAnything(patches);
      });
      setCapturableElmsDrag(capturableItems);
    });

    useAnyEvent(EVT_ELEMENT_DROP, (ev: CustomEvent<{ x: number; y: number; ids: string[] }>) => {
      // If the drop event comes after drag-and-drop, it's handled elsewhere.
      // This handler is for creation and paste events.
      if (capturableElmsDrag) {
        return;
      }

      const capturableItems = canICaptureElements(ev.detail.ids);
      if (!capturableItems?.length) return;

      const { x: mouseX, y: mouseY, ids } = ev.detail;
      const totalWidth = xs[xs.length - 1],
        totalHeight = ys[ys.length - 1];

      const tableLeft = element.x,
        tableTop = element.y,
        tableRight = element.x + totalWidth * scaleX,
        tableBottom = element.y + totalHeight * scaleY;

      const isOverTable = mouseX >= tableLeft && mouseX <= tableRight && mouseY >= tableTop && mouseY <= tableBottom;

      if (isOverTable) {
        // find the cell where the mouse is
        const relX = (mouseX - element.x) / scaleX;
        const relY = (mouseY - element.y) / scaleY;
        const col = xs.findIndex((_x) => _x >= relX) - 1;
        const row = ys.findIndex((_y) => _y >= relY) - 1;
        if (col != -1 && row != -1) {
          // just for compiler. we know it's not -1
          window.setTimeout(() => {
            const keyMaker = new TableIds(id);
            const key = keyMaker.longId(element.cols[col].id, element.rows[row].id);
            let patch = onDropElementsOnTable(key, cells[key], ids);

            // TODO: this doesn't happen in the same operation as the paste/creation, so this makes a new undo point
            const nodes = layerRef.current.find((node: Konva.Node) => ids.includes(node.id())).toArray();
            const bbox = BoundingBox.expandOnAll(measureNodes(nodes));
            const [_, p, ip] = produceWithPatches((draft: any) => {
              draft.cols[col].size = Math.max(bbox.width * 1.1, draft.cols[col].size);
              draft.rows[row].size = Math.max(bbox.height * 1.1, draft.rows[row].size);
            })(element);
            patch.push({ id: keyMaker.fullTableId(), patch: p, inversePatch: ip });
            patchAnything(patch);
          });
        }
      }
    });

    const idMaker = new TableIds(id);

    function* getContainedElementsInCell(col: number, row: number): Generator<[string, any]> {
      const cellid = idMaker.longId(element.cols[col].id, element.rows[row].id);
      const cell = cells[cellid];
      if (cell?.containedIds?.length) {
        for (const id of cell.containedIds) {
          if (containedElements[id]) yield [id, containedElements[id]];
        }
      }
    }

    const handlers: ActionHandlers = {
      click: ({ col, row, event }) => {
        if (isSelected) {
          if (event.evt.metaKey) {
            dispatch({ type: "toggle-cell", col: element.cols[col].id, row: element.rows[row].id, element });
          } else if (event.evt.shiftKey) {
            dispatch({ type: "extend-selection", col: element.cols[col].id, row: element.rows[row].id });
          } else {
            if (state.cursor?.col == element.cols[col].id && state.cursor?.row == element.rows[row].id) {
              dispatch({ type: "start-edit" });
            } else {
              dispatch({ type: "reset-on-cell", col: element.cols[col].id, row: element.rows[row].id });
            }
          }
          event.cancelBubble = true;
        }
      },
      dblClick: function ({ col, row, event }) {
        if (locked) return;
        dispatch({
          type: "reset-on-cell",
          col: element.cols[col].id,
          row: element.rows[row].id,
        });
        dispatch({ type: "start-edit" });
      },
      colResize: function ({ col, newSize }) {
        if (locked) return;
        if (resizer.current == null) {
          resizer.current = new TrackResizer(id, element, getContainedElementsInCell, patchAnything);
        }
        setoverrideSize({ [element.cols[col - 1].id]: newSize });
        resizer.current.updateCol(col - 1, newSize);
        // TODO: if element is unmounted before the timeout happens, don't use the data
      },
      colResizeEnd: function ({ col, newSize }) {
        if (locked) return;
        if (!resizer.current) {
          console.error("WHAT HAPPENED?");
        } else {
          resizer.current.updateCol(col - 1, newSize);
          resizer.current.finish();
          resizer.current = null;
        }
        setoverrideSize(null);
      },
      rowResize: function ({ row, newSize }) {
        if (locked) return;
        if (resizer.current == null) {
          resizer.current = new TrackResizer(id, element, getContainedElementsInCell, patchAnything);
        }
        setoverrideSize({ [element.rows[row - 1].id]: newSize });
        resizer.current.updateRow(row - 1, newSize);
        // TODO: if element is unmounted before the timeout happens, don't use the data
      },
      rowResizeEnd: function ({ row, newSize }): void {
        if (locked) return;
        if (!resizer.current) {
          console.error("WHAT HAPPENED?");
        } else {
          resizer.current.updateRow(row - 1, newSize);
          resizer.current.finish();
          resizer.current = null;
        }
        setoverrideSize(null);
      },
      cellEdited: function ({ colId, rowId, cur, initial }) {
        if (locked) return;
        let patches: any[] = [];
        let cell = cells[idMaker.longId(colId, rowId)] ?? {};
        let baseElement = element;
        // if I have initial state, I want produce to work from that, not current element
        if (initial) {
          baseElement = structuredClone(baseElement);
          const scaleY = element.scaleY ?? 1;
          const theRow = baseElement.rows.find(({ id }) => id == rowId);
          if (theRow) theRow.size = initial.height / scaleY;
          cell = { text: initial.text };
        }
        const [, patch, inversePatch] = produceWithPatches((draft: any) => {
          draft.text = cur.text;
        })(cell);
        const cellid_reflect = fullCellKey(id, colId, rowId);
        patches.push({ id: cellid_reflect, patch, inversePatch });
        if (cur.height) {
          const [newElement, patch, inversePatch] = produceWithPatches((draft) => {
            const theRow = draft.rows.find(({ id }: { id: string }) => id == rowId);
            if (theRow) {
              theRow.size = cur.height / (draft.scaleY ?? 1);
            }
          })(baseElement);
          patches.push({ id: "cElement-" + id, patch, inversePatch });
          //TODO: use the other funtion
          alignTableContentsAfterChange(id, element, newElement, cells, containedElements, { patches });
        }
        patchAnything(patches);
      },
      delete: function () {
        if (locked) return;
        let patches: any[] = [];
        const deleteElementFn = produceWithPatches((draft: any) => {
          draft.hidden = true;
        });

        for (const cell of selectionCells(state.selection, info)) {
          const theCell = cells[idMaker.longId(cell.col.key, cell.row.key)];
          // delete elements on the cells
          if (theCell && (theCell.containedIds?.length || !!theCell.text)) {
            if (theCell.containedIds?.length) {
              for (const elementId of theCell.containedIds) {
                if (containedElements[elementId]) {
                  const [, patch, inversePatch] = deleteElementFn(containedElements[elementId]);
                  patches.push({ id: "cElement-" + elementId, patch, inversePatch });
                }
              }
            }
            // delete the text in the cell
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              draft.text = "";
              delete draft.containedIds;
            })(theCell);

            if (patch.length) {
              const cellid_reflect = fullCellKey(id, cell.col.key, cell.row.key);
              patches.push({ id: cellid_reflect, patch, inversePatch });
            }
          }
        }
        // if the cells are all empty, delete the cells themselves, assuming selection is complete columns/rows
        if (!patches.length) {
          const selectedCols = getColumnsThatAreFullySelected(state.selection, info);
          const selectedRows = getRowsThatAreFullySelected(state.selection, info);

          if (selectedCols?.length && selectedCols.length != info.numColumns) {
            const [, patch, inversePatch] = produceWithPatches((draft: TypeTableElement) => {
              draft.cols = draft.cols.filter(({ id }) => !selectedCols.includes(id));
            })(element);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
          }
          if (selectedRows?.length && selectedRows.length != info.numRows) {
            const [, patch, inversePatch] = produceWithPatches((draft: TypeTableElement) => {
              draft.rows = draft.rows.filter(({ id }) => !selectedRows.includes(id));
            })(element);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
          }
          moveContainedElementsAfterDelete(element, selectedCols, selectedRows, getContainedElementsInCell, patches);
        }
        patchAnything(patches);
      },
      rowColDragEnd: function ({ item, src, target }) {
        if (locked) return;
        // change the order of columns/rows in element, and take contained elements with us
        const mainAxis = item == "row" ? "rows" : "cols";
        let patches: any[] = [];
        const [newElement, patch, inversePatch] = produceWithPatches((draft: any) => {
          let items = draft[mainAxis];
          let orig = items[src];
          items.splice(src, 1);
          items.splice(target, 0, orig);
        })(element);
        patches.push({ id: "cElement-" + id, patch, inversePatch });
        alignTableContentsAfterChange(id, element, newElement, cells, containedElements, { patches });
        patchAnything(patches);
      },
      addRow: function ({ row }) {
        if (locked) return;
        let patches: any[] = [];
        const copyPropsFrom = element.rows[Math.max(row, 0)];
        const [newElement, patch, inversePatch] = produceWithPatches((draft: any) => {
          draft.rows.splice(row + 1, 0, { id: cellNanoid(), size: copyPropsFrom.size });
        })(element);
        patches.push({ id: "cElement-" + id, patch, inversePatch });
        // copy styling for the new col from existing col
        if (row >= 0) {
          const idsMaker = new TableIds(id);
          const rowId = element.rows[row].id;
          for (let col = 0; col < element.cols.length; col++) {
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              const colId = element.cols[col].id;
              const neighborCell = cells[idsMaker.longId(colId, rowId)];
              if (neighborCell) {
                draft.fill = neighborCell.fill;
                draft.textColor = neighborCell.textColor;
                draft.fontSize = neighborCell.fontSize;
                draft.align = neighborCell.align;
                draft.valign = neighborCell.valign;
                draft.fontProps = neighborCell.fontProps;
                draft.font = neighborCell.font;
              }
            })({});
            patches.push({
              id: fullCellKey(id, newElement.cols[col].id, newElement.rows[row + 1].id),
              patch,
              inversePatch,
            });
          }
        }
        alignTableContentsAfterChange(id, element, newElement, cells, containedElements, { patches });
        patchAnything(patches);
      },
      addCol: function ({ col }) {
        if (locked) return;
        let patches: any[] = [];
        const copyPropsFrom = element.cols[Math.max(col, 0)];
        const [newElement, patch, inversePatch] = produceWithPatches((draft: any) => {
          draft.cols.splice(col + 1, 0, { id: cellNanoid(), size: copyPropsFrom.size });
        })(element);
        patches.push({ id: "cElement-" + id, patch, inversePatch });
        // copy styling for the new col from existing col
        // Either the one before it, or the one after it
        const srcCol = Math.max(col, 0);
        const colId = element.cols[srcCol].id;

        const idsMaker = new TableIds(id);
        for (let row = 0; row < element.rows.length; row++) {
          const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
            const rowId = element.rows[row].id;
            const neighborCell = cells[idsMaker.longId(colId, rowId)];
            if (neighborCell) {
              draft.fill = neighborCell.fill;
              draft.textColor = neighborCell.textColor;
              draft.fontSize = neighborCell.fontSize;
              draft.align = neighborCell.align;
              draft.valign = neighborCell.valign;
              draft.fontProps = neighborCell.fontProps;
              draft.font = neighborCell.font;
            }
          })({});
          patches.push({
            id: fullCellKey(id, newElement.cols[col + 1].id, newElement.rows[row].id),
            patch,
            inversePatch,
          });
        }
        alignTableContentsAfterChange(id, element, newElement, cells, containedElements, { patches });
        patchAnything(patches);
      },
      extendSelection: function ({ dx, dy }) {
        if (state.cursor) {
          const { col, row } = state.cursor;
          let x = element.cols.findIndex((c) => c.id == col);
          let y = element.rows.findIndex((r) => r.id == row);
          x = clamp(x + dx, 0, element.cols.length - 1);
          y = clamp(y + dy, 0, element.rows.length - 1);
          dispatch({ type: "extend-selection", col: element.cols[x].id, row: element.rows[y].id });
        }
      },
      selectRow: function ({ row, toggle }) {
        dispatch({ type: "select-row", row: element.rows[row].id, toggle });
        setTimeout(() => setSelectedIds([id])); // move selection to table
      },
      selectCol: function ({ col, toggle }) {
        dispatch({ type: "select-col", col: element.cols[col].id, toggle });
        setTimeout(() => setSelectedIds([id])); // move selection to table
      },
      moveCursor: function ({ dx, dy }) {
        dispatch({ type: "move-cursor", dx, dy, element });
      },
      clipboard: function (event) {
        if (event.target != document.body) return; // we're on input/textarea, let the browser handle it
        if (state.cursor != null && event.clipboardData) {
          const { col, row } = state.cursor;
          let x = element.cols.findIndex((c) => c.id == col);
          let y = element.rows.findIndex((r) => r.id == row);
          let cell_x = xs[x] * (element.scaleX||1) + element.x,
            cell_y = ys[y] * (element.scaleY||1) + element.y;
          const cellid = idMaker.longId(col, row);
          if (event.type == "copy" || event.type == "cut") {
            event.clipboardData.clearData();
            const text = cells[cellid]?.text || "";
            tableClipboard = structuredClone(cells[cellid]);
            if (tableClipboard?.containedIds?.length) {
              const ids = tableClipboard.containedIds;
              const elements: Array<{ id: string; type: string; element: any }> = [];
              let bbox = new BoundingBox();
              layerRef.current
                .find((node: Konva.Node) => ids.includes(node.id()))
                .each((node: Konva.Node) => {
                  const el = structuredClone(node.attrs.element);
                  el.lock = LockType.None; // everything is unlocked after copy
                  delete el.frameId; // remove from frame - will be calculated on paste
                  delete el.containerId;
                  el.x -= cell_x;
                  el.y -= cell_y;
                  bbox.expandRect(node.getClientRect({ skipShadow: true, relativeTo: layerRef.current! }));
                  elements.push({ id: node.id(), type: node.attrs.type, element: el });
                });
              tableClipboard.elements = elements;
              tableClipboard.bbox =  bbox.moveBy(-cell_x, -cell_y);
            }
            event.clipboardData.setData("text/plain", text); // doesn't copy text styling
            navigator.clipboard.writeText(text).catch(() => {});
            if (event.type == "cut") {
              const cell = cells[cellid] ?? {};
              const [_, patch, inversePatch] = produceWithPatches((draft: any) => void delete draft.text)(cell);
              patchAnything({
                id: fullCellKey(id, col, row),
                patch,
                inversePatch,
              });
            }
          } else {
            if (locked) return;
            if (tableClipboard) {
              const cell = cells[cellid] ?? {};
              let newContainedIds: string[] | undefined;
              // duplicate elements, create new containedIds list
              let expandColumnBy = 0, expandRowBy = 0;
              if (tableClipboard.elements?.length) {
                let elements: Array<{ id: string; type: string; element: any }> = [];
                let newIds: Record<string, string> = {};
                let groupIds: Record<string, string> = {};
                tableClipboard.elements.forEach(({ id, type, element }: { id: string; type: string; element: any }) => {
                  newIds[id] = createElementId(); // new id for the element
                  const el = structuredClone(element);
                  replaceGroupsAfterCopy(groupIds, el); // replace group-ids
                  el.x += cell_x;
                  el.y += cell_y;
                  elements.push({ id: newIds[id], type, element: el });
                  newContainedIds ??= [];
                  newContainedIds.push(dbKey(newIds[id], type));
                });
                let bbox = tableClipboard.bbox.moveBy(cell_x,cell_y);
                let cellRight = xs[x + 1] * (element.scaleX || 1) + element.x;
                let cellBottom = ys[y+1] * (element.scaleY || 1) + element.y;
                if (cellRight < bbox.right) {
                  expandColumnBy = bbox.right - cellRight;
                }
                if (cellBottom < bbox.bottom) {
                  expandRowBy = bbox.bottom - cellBottom;
                }
                canvasUtilsAtom?.onAddElements(elements);
              }

              const patches: SinglePatch[] = [];

              const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                ["text", "fill", "textColor", "fontSize", "align", "valign", "fontProps", "font"].forEach((trait) => {
                  if (tableClipboard[trait] != undefined) {
                    draft[trait] = tableClipboard[trait];
                  }
                });
                draft.containedIds = newContainedIds;
              })(cell);

              patches.push({
                id: fullCellKey(id, col, row),
                patch,
                inversePatch,
              });

              if (expandColumnBy || expandRowBy) {
                const [, patch, inversePatch] = produceWithPatches((draft: any) => {
                  draft.cols[x].size += expandColumnBy;
                  draft.rows[y].size += expandRowBy;
                 })(element);
                patches.push({
                  id: 'cElement-'+id,
                  patch,
                  inversePatch,
                });
              }
              patchAnything(patches);

              event.preventDefault(); // don't let the browser handle the event
            }
          }
        }
      },
      styleChange: function ({ trait, value }) {
        let patches: any[] = [];
        // if not cells are selected, the table should change
        for (const cell of selectionCells(state.selection, info)) {
          const cellData = cells[idMaker.longId(cell.col.key, cell.row.key)] || { ...element };
          const [_, patch, inversePatch] = produceWithPatches((draft) => {
            if (trait == "fill") {
              if (typeof value == "number") draft.fill = replaceColorOpacity(draft.fill, value);
              else draft.fill = value;
            } else if (trait == "fontProps-toggle") {
              draft.fontProps ^= value;
            } else {
              draft[trait] = value;
            }
          })(cellData);
          patches.push({ id: fullCellKey(id, cell.col.key, cell.row.key), patch, inversePatch });
        }
        patchAnything(patches);
      },
      editTitle: function ({ title }) {
        const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
          draft.title = title;
        })(element);
        patchAnything({ id: "cElement-" + id, patch, inversePatch });
      },
    };
    function eventHandler(action: TableAction) {
      if ("payload" in action) {
        (handlers[action.type] as (payload: any) => void)(action.payload);
      } else {
        handlers[action.type](void 0);
      }
    }

    return (
      <>
        <TableView
          ref={ref}
          id={id}
          element={element}
          xs={xs}
          ys={ys}
          onResize={onResize}
          cells={cells}
          info={info}
          containedIds={containedIds}
          isSelectable={isSelectable}
          state={state}
          onDismissEditing={() => dispatch({ type: "end-edit" })}
          drag={drag}
          isEditingLink={isEditingLink}
          changeElementLink={changeElementLink}
          renderLink={renderLink}
          dispatch={eventHandler}
        />
        {/* highlight the cell we're dragging elements over */}
        {highlightCell && (
          <Rect
            fill="#BFDCFF"
            opacity={0.5}
            x={element.x + xs[highlightCell.col] * scaleX}
            y={element.y + ys[highlightCell.row] * scaleY}
            width={(xs[highlightCell.col + 1] - xs[highlightCell.col]) * scaleX}
            height={(ys[highlightCell.row + 1] - ys[highlightCell.row]) * scaleY}
          />
        )}
        {capturableElmsDrag && (
          <TableDragListener
            ids={capturableElmsDrag}
            x={element.x}
            y={element.y}
            scaleX={element.scaleX}
            scaleY={element.scaleY}
            xs={xs}
            ys={ys}
            onDrag={(override: null | { col: number; row: number; colWidth: number; rowHeight: number }) => {
              // if xs,ys has the ids as well as size, would be much easier
              // this code is horrible for performance when I have several tables
              if (override) {
                const { col, row, colWidth, rowHeight } = override;
                const newSize = {
                  [element.cols[col].id]: Math.max(colWidth * 1.1, element.cols[col].size * scaleX) / scaleX,
                  [element.rows[row].id]: Math.max(rowHeight * 1.1, element.rows[row].size * scaleY) / scaleY,
                };
                setHighlightCell({ col, row });
                setoverrideSize((prev) => (R.equals(prev, newSize) ? prev : newSize));
              } else {
                setHighlightCell(null);
                setoverrideSize(null);
              }
            }}
            onDragEnd={function (col, row, bbox, ids): void {
              if (col != -1 && row != -1) {
                const key = idMaker.longId(element.cols[col].id, element.rows[row].id);
                let patch = onDropElementsOnTable(key, cells[key], ids);
                if (bbox) {
                  const [_, p, ip] = produceWithPatches((draft: any) => {
                    draft.cols[col].size = Math.max(bbox.width * 1.1, draft.cols[col].size * scaleX) / scaleX;
                    draft.rows[row].size = Math.max(bbox.height * 1.1, draft.rows[row].size * scaleY) / scaleY;
                  })(element);
                  patch.push({ id: "cElement-" + id, patch: p, inversePatch: ip });
                }
                patchAnything(patch);
              }
              setoverrideSize(null);
              setHighlightCell(null);
              setCapturableElmsDrag(null);
            }}
          />
        )}
        {trackResizing && (
          <TableResizeContainedListener
            data={trackResizing}
            xs={xs}
            ys={ys}
            element={element}
            onEnd={(val: OverrideSize) => {
              setoverrideSize(null);
              setTrackResizing(null);
            }}
          />
        )}
        {!locked && !capturableElmsDrag && (
          <Portal selector={".Overlay"} enabled={true}>
            <TableUIWidgets
              id={id}
              element={element}
              xs={xs}
              ys={ys}
              dispatch={eventHandler}
              setEditingCell={() => dispatch({ type: "start-edit" })}
              drag={drag}
              setDrag={setDrag}
              state={state}
              isSelected={isSelected}
              cells={cells}
              minColSize={minColSize} // can be computed only if needed
              minRowSize={minRowSize}
            />
          </Portal>
        )}
      </>
    );
  }
);

const TableView = React.memo(
  React.forwardRef(
    (
      {
        id,
        element,
        cells,
        containedIds,
        isSelectable,
        state,
        info,
        onDismissEditing,
        xs,
        ys,
        drag,
        dispatch,
        onResize,
        isEditingLink,
        changeElementLink,
        renderLink,
      }: {
        id: string;
        element: TypeTableElement;
        cells: Record<string, TypeTableCell>;
        containedIds: undefined | string[];
        isSelectable: boolean;
        state: TableState;
        info: TableCellsInfo;
        onDismissEditing: () => void;
        xs: number[];
        ys: number[];
        drag: { col: number; row: number; dx: number; dy: number };
        dispatch: ActionHandler;
        onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
        isEditingLink: boolean;
        changeElementLink: (element: any, newLink?: string) => void;
        renderLink: (element: any) => any;
      },
      ref: any
    ) => {
      const mutation = useMemo(() => new TransformHooks(id, onResize), [id]);
      const isExporting = useAtomValue(isAnyKindOfExportAtom);

      const tableWidth = xs[xs.length - 1],
        tableHeight = ys[ys.length - 1];

      function yCoord(yIndex: number) {
        let y = ys[yIndex];
        if (drag && drag.row == yIndex) {
          y += drag.dy;
        }
        return y;
      }
      function xCoord(xIndex: number) {
        let x = xs[xIndex];
        if (drag && drag.col == xIndex) {
          x += drag.dx;
        }
        return x;
      }

      //TODO: if most cases there is no drag operation, and then it's so much simpler and faster to
      // build up the sub-components of the table.
      // and for drag - create a separate function. (code reuse is not that horrible)
      const nodes: Array<{ row: number; col: number; zOrder: number; elm: React.ReactNode }> = [];
      for (let col = 0; col < element.cols.length; col++) {
        for (let row = 0; row < element.rows.length; row++) {
          const cellWidth = xs[col + 1] - xs[col];
          const cellHeight = ys[row + 1] - ys[row];
          const colKey = element.cols[col].id;
          const rowKey = element.rows[row].id;
          const isEditing =
            !element.lock && state.isEditing && state.cursor?.col == colKey && state.cursor?.row == rowKey;
          const cellid = fullCellKey(id, colKey, rowKey);

          nodes.push({
            row,
            col,
            zOrder: 0,
            elm: (
              <TableCell
                cellId={cellid.slice(9)}
                tableId={id}
                key={cellid}
                col={col}
                row={row}
                x={xCoord(col)}
                y={yCoord(row)}
                width={cellWidth}
                height={cellHeight}
                element={element}
                cell={cells[cellid]}
                isEditing={isEditing}
                onDismissEditing={onDismissEditing}
                dispatch={dispatch}
                onEdit={(cur, initial) => {
                  dispatch(
                    actions.cellEdited({
                      colId: element.cols[col].id,
                      rowId: element.rows[row].id,
                      cur: {
                        text: cur.text,
                        height: cur.height ? cur.height * (element.scaleY || 1) : undefined,
                      },
                      initial,
                    })
                  );
                }}
              />
            ),
          });
        }
      }

      if (state.cursor && !isExporting) {
        const col = element.cols.findIndex(({ id }) => id == state.cursor!.col);
        const row = element.rows.findIndex(({ id }) => id == state.cursor!.row);
        nodes.push({
          row,
          col,
          zOrder: 1,
          elm: (
            <CellOutline
              key="cursor highlight"
              x={xCoord(col)}
              y={yCoord(row)}
              width={xs[col + 1] - xs[col]}
              height={ys[row + 1] - ys[row]}
            />
          ),
        });
      }
      if (!isSelectionEmpty(state.selection) && !isExporting) {
        for (const cell of selectionCells(state.selection, info)) {
          const x = cell.col.i;
          const y = cell.row.i;
          nodes.push({
            row: y,
            col: x,
            zOrder: 2,
            elm: (
              <CellOutline
                key={"outline" + x + y}
                x={xCoord(x)}
                y={yCoord(y)}
                width={cell.col.end - cell.col.start}
                height={cell.row.end - cell.row.start}
              />
            ),
          });
        }
      }

      //TODO: if we don't have drag no need to sort, no need to create an array of objects
      // with elements inside it...
      if (drag.col != -1 || drag.row != -1) {
        nodes.sort((a, b) => {
          // non dragged elements are always before dragged elements
          // then decide by zOrder prop
          const aDragged = drag.col == a.col || drag.row == a.row;
          const bDragged = drag.col == b.col || drag.row == b.row;
          if (aDragged == bDragged) {
            return a.zOrder - b.zOrder;
          }
          return +aDragged - +bDragged;
        });

        if (drag.col != -1) {
          nodes.unshift({
            row: 0,
            col: 0,
            zOrder: 3,
            elm: (
              <Rect
                fill="#e5e8ea"
                x={xs[drag.col]}
                y={0}
                width={xs[drag.col + 1] - xs[drag.col]}
                height={ys[ys.length - 1]}
              />
            ),
          });
        } else {
          nodes.unshift({
            row: 0,
            col: 0,
            zOrder: 3,
            elm: (
              <Rect
                fill="#e5e8ea"
                x={0}
                y={ys[drag.row]}
                width={xs[xs.length - 1]}
                height={ys[drag.row + 1] - ys[drag.row]}
              />
            ),
          });
        }
      }
      const elms = nodes.map(({ elm }) => elm);

      return (
        <>
          { !isExporting && (
            <Shape
              x={element.x}
              y={element.y}
              rotation={(element as any).rotate ?? 0}
              name="table-background"
              hitFunc={(context, shape) => {
                const stage = shape.getStage();
                if (!stage) return;
                const stageScale = stage.scaleX();
                if (!showTableWidgets(tableWidth, tableHeight, stageScale)) return;
                const scaleFix = 1 / stageScale;
                const d = dragControlDistance * 3 * Math.min(5, scaleFix);
                const { scaleX = 1, scaleY = 1 } = element;
                context.beginPath();
                context.rect(-d, -d, xs[xs.length - 1] * scaleX + d * 2, ys[ys.length - 1] * scaleY + d * 2);
                context.fillShape(shape);
              }}
              onMouseEnter={setMousePointer}
              onMouseLeave={unsetMouse}
            />
          )}

          {!isExporting && <TableTitle id={id} element={element} dispatch={dispatch} />}
          <Group
            ref={ref}
            id={id}
            name={id}
            type={"table"}
            element={element}
            x={element.x}
            y={element.y}
            scaleX={element.scaleX}
            scaleY={element.scaleY}
            rotation={(element as any).rotate ?? 0}
            isCanvasElement={true}
            isConnectable={true}
            isConnector={false}
            isDraggable={true}
            isFrame={false}
            isSelectable={isSelectable}
            isTaskConvertible={false}
            attachedConnectors={element.attachedConnectors}
            {...mutation.getCallbacks()}
            // unique to table
            containedIds={containedIds}
            cells={cells}
          >
            <Rect
              fillEnabled={false}
              stroke={element.stroke}
              strokeWidth={1}
              strokeScaleEnabled={false}
              width={xs[xs.length - 1]}
              height={ys[ys.length - 1]}
              listening={false}
            />
            {elms}
            {renderLink(element)}
          </Group>
          {isEditingLink && (
            <Html>
              <Modal dimBackground={true}>
                <EditElementLinkModal element={element} onChangeLink={changeElementLink} />
              </Modal>
            </Html>
          )}
        </>
      );
    }
  )
);

function CellOutline({ x, y, width, height }: { x: number; y: number; width: number; height: number }) {
  return (
    <Rect
      x={x}
      y={y}
      width={width}
      height={height}
      fillEnabled={false}
      stroke={SelectedCellStroke}
      strokeWidth={2}
      strokeScaleEnabled={false}
      listening={false}
    />
  );
}

const TableUIWidgets = React.memo(
  ({
    id,
    element,
    xs,
    ys,
    dispatch,
    state,
    isSelected,
    setEditingCell,
    drag,
    setDrag,
    cells,
    minColSize,
    minRowSize,
  }: {
    id: string;
    element: TypeTableElement;
    xs: number[];
    ys: number[];
    dispatch: ActionHandler;
    state: TableState;
    isSelected: boolean;
    setEditingCell: () => void;
    drag: { col: number; row: number; dx: number; dy: number };
    setDrag: React.Dispatch<React.SetStateAction<{ col: number; row: number; dx: number; dy: number }>>;
    cells: any;
    minColSize: number[];
    minRowSize: number[];
  }) => {
    const transformer = useAtomValue(transformerRefAtom).current;
    const layerRef = useAtomValue(layerRefAtom);
    const [mouseColRow, setMouseColRow] = useState([-1, -1] as [number, number]);
    const [mouseIsClose, setMouseIsClose] = useState(false);
    const [colWidgets, setColWidgets] = useState<null | [number, number]>(null);
    const [rowWidgets, setRowWidgets] = useState<null | [number, number]>(null);

    const posScale = useAtomValue(posScaleAtom);
    const stageScale = posScale.scale || 1;
    const scaleFix = 1 / stageScale;
    const selectionEmpty = isSelectionEmpty(state.selection);
    const draggingColOrRow = drag?.col != -1 || drag?.row != -1;
    const { scaleX = 1, scaleY = 1 } = element;

    // when adding/deleting rows/cols, refresh the transformer if I'm selected
    useEffect(() => {
      setTimeout(() => transformer?.forceUpdate());
    }, [element.rows, element.cols]);

    // Listening to mouse movements to know where to draw the hover-widgets
    // I add event-listener to document instead of node on canvas, because I want the widgets
    // to follow the mouse even if it's outside the table area.

    const totalHeight = ys[ys.length - 1];
    const totalWidth = xs[xs.length - 1];

    useAnyEvent("mousemove", (e) => {
      const { x, y } = viewportToStage(posScale, { x: e.clientX, y: e.clientY });
      const { scaleX = 1, scaleY = 1 } = element;
      // calculate table bounding box
      const tableLeft = element.x,
        tableTop = element.y,
        tableRight = element.x + totalWidth * scaleX,
        tableBottom = element.y + totalHeight * scaleY;
      const somePadding = 50 * scaleFix; // 50 pixels scaled to canvas coordinates

      const closeEnough =
        x >= tableLeft - somePadding &&
        x <= tableRight + somePadding &&
        y >= tableTop - somePadding &&
        y <= tableBottom + somePadding;
      setMouseIsClose(closeEnough);
      // If mouse isn't close to the table, or we're doing another operation, abort early because
      // setting the state on every mouse-mouse will kill performance.
      // TODO: when ending a drag of column/row, I have to move the mouse to see the widgets again, because of this if
      if (!closeEnough || draggingColOrRow) {
        setColWidgets(null);
        setRowWidgets(null);
        // setMouseColRow([-1, -1]);
        return;
      }

      const xx = (x - element.x) / scaleX;
      const yy = (y - element.y) / scaleY;
      let col = xs.findIndex((x_) => x_ >= clamp(xx, 0, xs[xs.length - 1]));
      let row = ys.findIndex((y_) => y_ >= clamp(yy, 0, ys[ys.length - 1]));
      col = clamp(col - 1, 0, element.cols.length - 1);
      row = clamp(row - 1, 0, element.rows.length - 1);

      {
        // todo: do binary search
        let best = -1,
          bestDist = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < xs.length; i++) {
          const d = Math.abs(xx - xs[i]);
          if (d < bestDist) {
            best = i;
            bestDist = d;
          }
          // now show (+) buttons above the closest column,
          // show the (--) and (+) buttons on the next-closest, in the direction of the mouse
          let otherWidgetColumn = 0;
          if (best == -1) otherWidgetColumn = -1;
          // no column, no widgets
          else if (best == xs.length - 1) otherWidgetColumn = best - 1;
          // one column line before the last
          else otherWidgetColumn = best + (xx > xs[best] ? 1 : -1); // closest one to the right or the left
          setColWidgets([best, otherWidgetColumn]);
        }

        {
          let best = -1,
            bestDist = Number.MAX_SAFE_INTEGER;
          for (let i = 0; i < ys.length; i++) {
            const d = Math.abs(yy - ys[i]);
            if (d < bestDist) {
              best = i;
              bestDist = d;
            }
          }
          let otherRowWidget = 0;
          if (best == -1) otherRowWidget = -1;
          else if (best == ys.length - 1) otherRowWidget = best - 1;
          else otherRowWidget = best + (yy > ys[best] ? 1 : -1);
          setRowWidgets([best, otherRowWidget]);
        }
      }
      if (mouseColRow[0] != col || mouseColRow[1] != row) setMouseColRow([col, row]);
    });

    // check how big the table looks on the screen
    const testSize = Math.min(
      (xs[xs.length - 1] * scaleX * stageScale) / window.innerWidth,
      (ys[ys.length - 1] * scaleY * stageScale) / window.innerHeight
    );
    const displayWidgets = (isSelected || mouseIsClose) && testSize > 0.05;
    const displaySmallerWidgets = displayWidgets // todo: take into account other operations, like table-resize, contained-resize,etc.

    useUnmount(() => unsetMouse(layerRef));

    const isDraggingRow = drag.row != -1;
    const isDraggingCol = drag.col != -1;
    const isDragging = isDraggingCol || isDraggingRow;

    if (!displayWidgets) {
      return null;
    }

    let dragTrackMarker = null as React.ReactNode;
    if (drag.col != -1) {
      const x = drag.dx + (xs[drag.col] + xs[drag.col + 1]) / 2;
      let target = xs.findIndex((x_) => x_ >= x) - 1;
      if (target < 0) target = 0;
      dragTrackMarker = (
        <Line
          visible={target != drag.col}
          x={(target < drag.col ? xs[target] : xs[target + 1]) * scaleX}
          points={[0, 0, 0, ys[ys.length - 1] * scaleY]}
          strokeWidth={4 / stageScale}
          stroke={SelectedCellStroke}
        />
      );
    } else if (drag.row != -1) {
      const y = drag.dy + (ys[drag.row] + ys[drag.row + 1]) / 2;
      let target = ys.findIndex((y_) => y_ >= y) - 1;
      if (target < 0) target = 0;
      dragTrackMarker = (
        <Line
          visible={target != drag.row}
          y={(target < drag.row ? ys[target] : ys[target + 1]) * scaleY}
          points={[0, 0, xs[xs.length - 1] * scaleX, 0]}
          strokeWidth={4 / stageScale}
          stroke={SelectedCellStroke}
        />
      );
    }

    return (
      <>
        <Group
          isCanvasElement={false}
          isSelectable={false}
          isConnector={false}
          isConnectable={false}
          x={element.x}
          y={element.y}
          rotate={element.rotate ?? 0}
        >
          {dragTrackMarker}
          {!draggingColOrRow && (
            <Group scaleX={scaleX} scaleY={scaleY}>
              <DraggableTableLines
                id={id}
                xs={xs}
                ys={ys}
                onMouseEnter={(kind) => (kind == "col" ? setMouseColResize(layerRef) : setMouseRowResize(layerRef))}
                onMouseLeave={() => unsetMouse(layerRef)}
                onColResize={(action) => {
                  if (action.type == "drag") {
                    const { col, x, prevX, startSize } = action;
                    const value = Math.max(x, prevX + minColSize[col - 1] * 1.1);
                    let newSize = value - prevX;
                    dispatch(actions.colResize({ col, newSize, startSize: startSize, x, prevX }));
                    return value;
                  } else if (action.type == "drag-end") {
                    const { col, x, prevX, startSize } = action;
                    const value = Math.max(x, prevX + minColSize[col - 1] * 1.1);
                    const newSize = x - prevX;
                    dispatch(actions.colResizeEnd({ col, newSize, startSize: startSize }));
                    return value;
                  }
                  return 0;
                }}
                onRowResize={(action) => {
                  if (action.type == "drag") {
                    const { row, y, prevY, startSize } = action;
                    const value = Math.max(y, prevY + minRowSize[row - 1] * 1.1);
                    let newSize = value - prevY;
                    dispatch(actions.rowResize({ row, newSize, startSize: startSize }));
                    return value;
                  } else if (action.type == "drag-end") {
                    const { row, y, prevY, startSize } = action;
                    const value = Math.max(y, prevY + minRowSize[row - 1] * 1.1);
                    const newSize = y - prevY;
                    dispatch(actions.rowResizeEnd({ row, newSize, startSize: startSize }));
                    return value;
                  }
                  return 0;
                }}
              />
            </Group>
          )}

          {!selectionEmpty && (
            <KeyboardHandler.TableKeyboardShortcuts
              moveCursor={(dx, dy) => dispatch(actions.moveCursor({ dx, dy }))}
              editCell={(v) => setEditingCell()}
              extendSelection={(dx, dy) => {
                dispatch(actions.extendSelection({ dx, dy }));
              }}
              clearSelection={() => {
                dispatch(actions.delete());
                return true; // handled the event
              }}
              onClipboardEvent={(event) => dispatch(actions.clipboard(event))}
            />
          )}

          {/* Add column buttons that appear above column lines */}
          {/* each button is 26 pixels wide, and col handle is 30 pixels wide */}
          {/* I want the column to be at least 60 pixels  */}
          {displaySmallerWidgets &&
            colWidgets != null &&
            (colWidgets[1] == -1 || Math.abs(xs[colWidgets[1]] - xs[colWidgets[0]]) * scaleX * stageScale > 60) && (
              <Group>
                {colWidgets[0] > 0 && (
                  <Group
                    name="add-col-anchor"
                    visible={!isDragging}
                    x={xs[colWidgets[0]] * scaleX}
                    onClick={() => dispatch(actions.addCol({ col: colWidgets[0] - 1 }))}
                  >
                    {/* <Rect fill="red" width={100} height={100} /> */}
                    <Group y={-dragControlDistance * scaleFix} scaleX={scaleFix} scaleY={scaleFix}>
                      <AddButton />
                    </Group>
                  </Group>
                )}
                {colWidgets[1] > 0 && (
                  <Group visible={!isDragging} x={xs[colWidgets[1]] * scaleX}>
                    {/* <Rect fill="green" width={100} height={100}/> */}
                    <Group y={-dragControlDistance * scaleFix} scaleX={scaleFix} scaleY={scaleFix}>
                      <AddButton />
                    </Group>
                  </Group>
                )}
              </Group>
            )}

          {/* Add row buttons that appear to the left of the table, near row lines */}
          {/* same size requirements */}
          {displaySmallerWidgets &&
            rowWidgets != null &&
            (rowWidgets[1] == -1 || Math.abs(ys[rowWidgets[0]] - ys[rowWidgets[1]]) * scaleY * stageScale > 60) && (
              <Group>
                {ys[rowWidgets[0]] != 0 && (
                  <Group
                    name="add-row-anchor"
                    visible={!isDragging}
                    y={ys[rowWidgets[0]] * scaleY}
                    onClick={() => dispatch(actions.addRow({ row: rowWidgets[0] - 1 }))}
                  >
                    <Group x={-dragControlDistance * scaleFix} scaleX={scaleFix} scaleY={scaleFix}>
                      <AddButton />
                    </Group>
                  </Group>
                )}
                {rowWidgets[1] > 0 && (
                  <Group visible={!isDragging} y={ys[rowWidgets[1]] * scaleY}>
                    <Group x={-dragControlDistance * scaleFix} scaleX={scaleFix} scaleY={scaleFix}>
                      <AddButton />
                    </Group>
                  </Group>
                )}
              </Group>
            )}

          {/* Col select and drag widget */}
          {/* col handle is 30 pixels wide, so we want 40 pixels wide column */}
          {displaySmallerWidgets &&
            (isDraggingCol ||
              (colWidgets &&
                colWidgets[1] != -1 &&
                !isDraggingRow &&
                Math.abs(xs[colWidgets[1]] - xs[colWidgets[0]]) * scaleX * stageScale > 40)) && (
              <Group
                key="drag col handle"
                name="col-drag-root"
                x={isDraggingCol || !colWidgets ? undefined : ((xs[colWidgets[0]] + xs[colWidgets[1]]) * scaleX) / 2}
              >
                <Group scaleX={scaleFix} scaleY={scaleFix} y={-dragControlDistance * scaleFix}>
                  <ColHandle
                    onColSelect={(e: KonvaEventObject<MouseEvent>) =>
                      dispatch(
                        actions.selectCol({ col: Math.min(colWidgets![0], colWidgets![1]), toggle: e.evt.shiftKey })
                      )
                    }
                    onDragStart={(e: KonvaEventObject<MouseEvent>) => {
                      const col = Math.min(colWidgets![0], colWidgets![1]);
                      dispatch(actions.selectCol({ col, toggle: false }));
                      setDrag({ col: col, row: -1, dx: 0, dy: 0 });
                      let colId = element.cols[col].id;
                      // get all children from this column
                      const childIds = element.rows.flatMap(
                        (row) => cells[fullCellKey(id, colId, row.id)]?.containedIds ?? []
                      );
                      const layer = e.currentTarget
                        .getStage()!
                        .getLayers()
                        .toArray()
                        .find((x) => x.name() == "Elements") as Konva.Layer;
                      const nodes = layer!.find((node: any) => childIds.includes(node.id()));

                      e.currentTarget.setAttr("nodes", nodes);
                      e.currentTarget.setAttr("startPos", e.currentTarget.position());
                    }}
                    onDragMove={(e: KonvaEventObject<MouseEvent>) => {
                      const control = e.currentTarget;
                      control.y(0);
                      const scaleXsToScreen = scaleX / scaleFix;
                      const min = scaleXsToScreen * xs[0];
                      const max = scaleXsToScreen * xs[xs.length - 1];
                      const x = clamp(control.x(), min, max);
                      control.x(x);
                      // the drag-move events happen faster than react reacts to state changes.
                      // so this gets the uninitialized drag.
                      // I have to find a better way to do event-handling with state updates
                      if (drag.col != -1) {
                        let dx = x - (scaleXsToScreen * (xs[drag.col] + xs[drag.col + 1])) / 2; // dx in screen pixels
                        dx = dx / scaleXsToScreen; // change back to canvas coordinates
                        setDrag((d) => ({ ...d, dx }));
                        const nodes = e.currentTarget.attrs.nodes;
                        nodes.forEach((node: any) =>
                          node.offset({ x: (-dx * (element.scaleX || 1)) / node.scaleX(), y: 0 })
                        );
                      }
                    }}
                    onDragEnd={(e: KonvaEventObject<MouseEvent>) => {
                      const control = e.currentTarget;
                      const scaleXsToScreen = scaleX / scaleFix;
                      const x = control.x() / scaleXsToScreen;
                      let target = xs.findIndex((x_) => x_ >= x) - 1;
                      if (target < 0) target = 0;
                      const nodes = e.currentTarget.attrs.nodes;
                      if (target != drag.col) {
                        dispatch(actions.rowColDragEnd({ item: "col", src: drag.col, target }));
                      }
                      e.currentTarget.x((xs[target] + xs[target + 1]) / (2 * scaleFix));
                      e.currentTarget.y(0);
                      nodes.forEach((node: any) => node.offset({ x: 0, y: 0 }));
                      setDrag({ col: -1, row: -1, dx: 0, dy: 0 });
                    }}
                  />
                </Group>
              </Group>
            )}

          {displaySmallerWidgets &&
            (isDraggingRow ||
              (rowWidgets &&
                rowWidgets[1] != -1 &&
                !isDraggingCol &&
                Math.abs(ys[rowWidgets[0]] - ys[rowWidgets[1]]) * scaleY * stageScale > 40)) && (
              <Group
                key="drag row handle"
                name="row-drag-root"
                y={isDraggingRow || !rowWidgets ? undefined : ((ys[rowWidgets[0]] + ys[rowWidgets[1]]) * scaleY) / 2}
              >
                <Group x={-dragControlDistance * scaleFix} scaleX={scaleFix} scaleY={scaleFix}>
                  <RowHandle
                    onRowSelect={(e: KonvaEventObject<MouseEvent>) =>
                      dispatch(
                        actions.selectRow({ row: Math.min(rowWidgets![0], rowWidgets![1]), toggle: e.evt.shiftKey })
                      )
                    }
                    onDragStart={(e: KonvaEventObject<MouseEvent>) => {
                      const row = Math.min(rowWidgets![0], rowWidgets![1]);
                      dispatch(actions.selectRow({ row, toggle: false }));
                      setDrag({ col: -1, row: mouseColRow[1], dx: 0, dy: 0 });
                      let rowId = element.rows[row].id;
                      const childIds = element.cols.flatMap(
                        (col) => cells[fullCellKey(id, col.id, rowId)]?.containedIds ?? []
                      );
                      const layer = e.currentTarget
                        .getStage()!
                        .getLayers()
                        .toArray()
                        .find((x) => x.name() == "Elements") as Konva.Layer;
                      const nodes = layer!.find((node: any) => childIds.includes(node.id()));
                      e.currentTarget.setAttr("nodes", nodes);
                      e.currentTarget.setAttr("startPos", e.currentTarget.position());
                    }}
                    onDragMove={(e: KonvaEventObject<MouseEvent>) => {
                      const control = e.currentTarget;
                      const scaleYsToScreen = scaleX / scaleFix;
                      control.x(0);
                      const min = scaleYsToScreen * ys[0]; // should include the dragged column width
                      const max = scaleYsToScreen * ys[ys.length - 1];
                      let y = clamp(control.y(), min, max);
                      control.y(y);
                      // see the comment in ColWidgets about this
                      if (drag.row != -1) {
                        let dy = y - (scaleYsToScreen * (ys[drag.row] + ys[drag.row + 1])) / 2; // dy in pixels
                        dy = dy / scaleYsToScreen; // transform to canvas coordinates
                        setDrag((d) => ({ ...d, dy }));
                        const nodes = e.currentTarget.attrs.nodes;
                        nodes.forEach((node: any) =>
                          node.offset({ x: 0, y: (-dy * (element.scaleY || 1)) / node.scaleY() })
                        );
                      }
                    }}
                    onDragEnd={(e: KonvaEventObject<MouseEvent>) => {
                      const control = e.currentTarget;
                      const scaleYsToScreen = scaleY / scaleFix;
                      const y = control.y() / scaleYsToScreen;
                      let target = ys.findIndex((y_) => y_ >= y) - 1;
                      if (target < 0) target = 0;
                      const nodes = e.currentTarget.attrs.nodes;
                      if (target != drag.row) {
                        dispatch(actions.rowColDragEnd({ item: "row", src: drag.row, target }));
                      }
                      e.currentTarget.y((ys[target] + ys[target + 1]) / (2 * scaleFix));
                      e.currentTarget.x(0);
                      nodes.forEach((node: any) => node.offset({ x: 0, y: 0 }));
                      setDrag({ col: -1, row: -1, dx: 0, dy: 0 });
                    }}
                  />
                </Group>
              </Group>
            )}
          {displayWidgets && (
            <Group
              scaleX={scaleFix}
              scaleY={scaleFix}
              x={(totalWidth * scaleX) / 2}
              y={totalHeight * scaleY + dragControlDistance * scaleFix}
              onClick={() => dispatch(actions.addRow({ row: element.rows.length - 1 }))}
            >
              <AddButton />
            </Group>
          )}
          {displayWidgets && (
            <Group
              scaleX={scaleFix}
              scaleY={scaleFix}
              x={totalWidth * scaleX + dragControlDistance * scaleFix}
              y={(totalHeight * scaleY) / 2}
              onClick={() => dispatch(actions.addCol({ col: element.cols.length - 1 }))}
            >
              <AddButton />
            </Group>
          )}
        </Group>
      </>
    );
  }
);

const setMouseCursor = (cursor: CSSProperties["cursor"]) => (e: any) => {
  if (e?.currentTarget?.getStage) {
    e.currentTarget.getStage()!.container().style.cursor = cursor!;
  } else if (e?.current) {
    e.current.getStage()!.container().style.cursor = cursor!;
  }
};

const setMouseColResize = setMouseCursor("col-resize");
const setMouseRowResize = setMouseCursor("row-resize");
const setMousePointer = setMouseCursor("pointer");
const unsetMouse = setMouseCursor("inherit");

const dragControlDistance = 30;

function showTableWidgets(tableWidth: number, tableHeight: number, stageScale: number) {
  const scrWidth = tableWidth * stageScale,
    scrHeight = tableHeight * stageScale;
  const percentageOfScreen = Math.min(scrWidth / window.innerWidth, scrHeight / window.innerHeight);
  const displayWidgets = percentageOfScreen > 0.1;
  return displayWidgets;
}

function TableCell({
  cellId,
  tableId,
  x,
  y,
  col,
  row,
  width,
  height,
  cell,
  element,
  isEditing,
  onDismissEditing,
  onEdit,
  dispatch,
}: {
  cellId: string;
  tableId: string;
  x: number;
  y: number;
  col: number;
  row: number;
  width: number;
  height: number;
  cell?: any;
  element: TypeTableElement;
  isEditing: boolean;
  onEdit: (latest: EditorState, initial?: EditorState) => void;
  onDismissEditing: () => void;
  dispatch: ActionHandler;
}) {
  const { scaleX = 1, scaleY = 1 } = element;

  // text styling props
  const fontFamily = cell?.font || defaultTextStyleForTable.font;
  const fontSize = (cell?.fontSize || defaultTextStyleForTable.fontSize) * scaleX;
  const align = cell?.align || defaultTextStyleForTable.align;
  const verticalAlign = cell?.valign || defaultTextStyleForTable.valign;
  const textColor = cell?.textColor || defaultTextStyleForTable.textColor;

  const fontProps = cell?.fontProps ?? defaultTextStyleForTable.fontProps;
  const textDecoration = konvaTextDecoration(fontProps);
  const fontStyle = fontPropertiesToString(fontProps);

  const backgroundColor = cell?.fill;

  // If font or its size changed, text might have gotten bigger and then we enlarge the row.
  useLayoutEffect(() => {
    if (cell?.text?.length) {
      let text = new Konva.Text({
        fontFamily,
        fontSize,
        align,
        verticalAlign,
        textDecoration,
        fontStyle,
        ellipsis: false,
        wrap: "word",
        perfectDrawEnabled: false,
        width,
        padding: cellPadding * scaleX,
        text: cell.text,
      });
      if (text.height() * scaleY > height) {
        onEdit({ text: cell.text, height: text.height() });
      }
    }
  }, [fontFamily, fontSize, align, verticalAlign, textDecoration, fontStyle, cell?.text, scaleX]);

  return (
    <>
      <Rect
        id={cellId}
        x={x}
        y={y}
        width={width}
        height={height}
        fill={backgroundColor}
        element={element}
        cell={cell}
        isTableCell={true}
        tableId={tableId}
        strokeWidth={1}
        strokeScaleEnabled={false}
        stroke={element.stroke}
        listening={true}
        onDblClick={(event) => dispatch(actions.dblClick({ col, row, event }))}
        // the following 2 handlers check for a mouse-click - without dragging the mouse (that's a different operation)
        onMouseDown={(event) => {
          event.target.attrs.mouseDown = { x: event.evt.offsetX, y: event.evt.offsetY };
        }}
        onMouseUp={(event) => {
          try {
            const { x, y } = event.target.attrs.mouseDown;
            if (Math.max(Math.abs(event.evt.offsetX - x), Math.abs(event.evt.offsetY - y)) < 5)
              dispatch(actions.click({ col, row, event }));
          } catch {}
        }}
      />
      {isEditing ? (
        <CellTextEditor
          contentArea={{ x, y, width, height }}
          value={cell?.text}
          placeholder={"Add text"}
          onEdit={onEdit}
          onDismiss={onDismissEditing}
          cssTextArea={
            {
              font: `${fontPropertiesToString(fontProps)} ${fontSize}px/1 ${fontFamily}`,
              textAlign: align,
              color: textColor,
              textDecoration: konvaTextDecoration(fontProps),
            } as CSSProperties
          }
          padding={cellPadding * scaleX}
        />
      ) : (
        !!cell?.text && (
          <Text
            x={x}
            y={y}
            width={width}
            height={height}
            padding={cellPadding * scaleX}
            text={cell.text}
            fill={textColor}
            fontFamily={fontFamily}
            fontSize={fontSize}
            align={align}
            verticalAlign={verticalAlign}
            textDecoration={textDecoration}
            fontStyle={fontStyle}
            ellipsis={false}
            wrap="word"
            perfectDrawEnabled={false}
            listening={false}
          />
        )
      )}
    </>
  );
}

interface EditorState {
  text?: string;
  height?: number;
}

function CellTextEditor({
  contentArea,
  value = "",
  placeholder,
  onEdit,
  onDismiss,
  cssTextArea,
  padding,
}: {
  contentArea: { x: number; y: number; width: number; height: number };
  value: string;
  placeholder: string;
  onEdit: (latest: EditorState, initial?: EditorState) => void;
  onDismiss: () => void;
  cssTextArea?: CSSProperties;
  padding: number;
}) {
  // state when component is first created - for undo/redo
  const initial = useRef<Required<EditorState>>({ text: value, height: contentArea.height });

  const [curText, setCurText] = useState(value);
  const throttledText = useThrottle(curText, 500);
  const [height, setHeight] = useState(contentArea.height);

  useEffect(() => {
    onEdit({ text: throttledText });
  }, [throttledText]);

  useEffect(() => {
    onEdit({ text: curText, height });
  }, [height]);

  // final effect on unmount that sends initial state, and this will register an undo point to that state
  useUnmount(() => onEdit({ text: curText, height }, initial.current));

  return (
    <Html groupProps={{ x: contentArea.x, y: contentArea.y }}>
      <div style={{ width: contentArea.width, height: contentArea.height, cursor: "text", padding: padding + "px" }}>
        <textarea
          autoFocus
          onFocus={(e) => e.currentTarget.select()}
          style={{
            height: "100%",
            width: "100%",
            // reset the default styles of a text area
            overflow: "auto",
            outline: "none",
            border: "none",
            background: "unset",
            resize: "none",
            verticalAlign: "top",
            // include the custom css for font and color
            ...cssTextArea,
          }}
          onKeyDown={(e) => {
            handleTabInTextArea(e);
            if (e.key == "Escape") {
              e.stopPropagation();
              onDismiss();
            }
          }}
          onInput={(e) => {
            const input = e.currentTarget;
            input.style.height = "0"; // this forces browser to recalc minimal height needed for the text, without scrollbars
            const minimalHeight = input.scrollHeight;
            input.style.height = input.scrollHeight + "px"; // set the height to the minimal height
            let newHeight = minimalHeight + padding * 2; // calculate height + padding
            newHeight = Math.max(newHeight, initial.current.height); // never go below the initial height
            setCurText(input.value);
            setHeight(newHeight);
          }}
          defaultValue={value}
          placeholder={placeholder}
        />
      </div>
    </Html>
  );
}

function Button({
  size,
  regular,
  hover,
  active,
}: {
  size: number | { width: number; height: number };
  regular: React.ReactNode;
  hover?: React.ReactNode;
  active?: React.ReactNode;
}) {
  const [isHovering, setHovering] = useState(false);
  const [isActive, setActive] = useState(false);

  hover ??= regular;
  active ??= hover;

  const w = typeof size === "number" ? size : size.width;
  const h = typeof size === "number" ? size : size.height;

  return (
    <>
      <Rect
        name="anchor"
        offsetX={w / 2}
        offsetY={h / 2}
        width={w}
        height={h}
        fill="transparent"
        onMouseEnter={(e) => {
          setHovering(true);
          setMousePointer(e);
        }}
        onMouseLeave={(e) => {
          setHovering(false);
          unsetMouse(e);
        }}
        onMouseDown={() => setActive(true)}
        onMouseUp={() => setActive(false)}
      />
      <Group listening={false}>{isActive ? active : isHovering ? hover : regular}</Group>
    </>
  );
}

function PlusIcon({
  halfSize,
  color,
  width = 2,
  lineCap = "butt",
}: {
  halfSize: number;
  color: string;
  width?: number;
  lineCap?: LineCap;
}) {
  return (
    <>
      <Line
        points={[0, -halfSize, 0, halfSize]}
        stroke={color}
        strokeWidth={width}
        lineCap={lineCap}
        listening={false}
      />
      <Line
        points={[-halfSize, 0, halfSize, 0]}
        stroke={color}
        strokeWidth={width}
        lineCap={lineCap}
        listening={false}
      />
    </>
  );
}

function AddButton() {
  return (
    <Button
      size={26}
      regular={
        <>
          <Circle radius={11} fill="#BFDCFF" />
          <PlusIcon width={1.5} halfSize={4} color={"#0072FF"} />
        </>
      }
      hover={
        <>
          <Circle radius={11} fill="#6DAFFF" />
          <PlusIcon width={1.5} halfSize={4} color={"#ffffff"} />
        </>
      }
      active={
        <>
          <Circle radius={11} fill="#6DAFFF" />
          <PlusIcon width={1.5} halfSize={4} color={"#ffffff"} />
        </>
      }
    />
  );
}

// TODO: unify RowHandle and ColHandle
function RowHandle({
  y,
  onRowSelect,
  onDragStart,
  onDragMove,
  onDragEnd,
}: {
  y?: number;
  onRowSelect: (e: KonvaEventObject<MouseEvent>) => void;
  onDragStart: (e: KonvaEventObject<MouseEvent>) => void;
  onDragMove: (e: KonvaEventObject<MouseEvent>) => void;
  onDragEnd: (e: KonvaEventObject<MouseEvent>) => void;
}) {
  return (
    <Group
      y={y}
      draggable
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
      onClick={onRowSelect}
    >
      <Button
        size={30}
        regular={<Rect fill="#BFDCFF" width={7} height={30} x={-3.5} y={-15} cornerRadius={7 / 2} />}
        hover={<Rect fill="#6DAFFF" width={7} height={30} x={-3.5} y={-15} cornerRadius={7 / 2} />}
        active={<Rect fill="#6DAFFF" width={7} height={30} x={-3.5} y={-15} cornerRadius={7 / 2} />}
      />
    </Group>
  );
}

function ColHandle({
  onColSelect,
  onDragStart,
  onDragMove,
  onDragEnd,
}: {
  onColSelect: (e: KonvaEventObject<MouseEvent>) => void;
  onDragStart: (e: KonvaEventObject<MouseEvent>) => void;
  onDragMove: (e: KonvaEventObject<MouseEvent>) => void;
  onDragEnd: (e: KonvaEventObject<MouseEvent>) => void;
}) {
  return (
    <Group draggable onDragStart={onDragStart} onDragMove={onDragMove} onDragEnd={onDragEnd} onClick={onColSelect}>
      <Button
        size={30}
        regular={<Rect fill="#BFDCFF" width={30} height={7} x={-15} y={-3.5} cornerRadius={7 / 2} />}
        hover={<Rect fill="#6DAFFF" width={30} height={7} x={-15} y={-3.5} cornerRadius={7 / 2} />}
        active={<Rect fill="#6DAFFF" width={30} height={7} x={-15} y={-3.5} cornerRadius={7 / 2} />}
      />
    </Group>
  );
}

export function tableTraits(element: TypeTableElement): ITraits {
  // let { align, textColor, font, fontProps, fontSize } = textEnabledTraits(element);
  // fontSize *= element.scaleX ?? 1;

  return {
    align: undefined,
    textColor: undefined,
    font: undefined,
    fontProps: undefined,
    fontSize: undefined,
    [Trait.tableFillColor]: undefined,
    [Trait.tableStrokeColor]: element.stroke,
    [Trait.tableAddColumn]: "",
    [Trait.tableAddRow]: "",
  };
}

export function tableCellTraits(element: TypeTableCell & { scaleX: number }): ITraits {
  let { align, textColor, font, fontProps, fontSize } = textEnabledTraits({ ...defaultTextStyleForTable, ...element });
  fontSize *= element.scaleX ?? 1;
  return {
    align,
    textColor,
    font,
    fontProps,
    fontSize,
    [Trait.tableFillColor]: element.fill,
  };
}

export function tableValidateTraits(element: TypeTableElement, trait: Trait, value: any) {
  if (trait == Trait.tableAddColumn) {
    // TODO: copy styling for new cells from their left-neighbors.
    // this means I should read from reflect the state of those cells, and write
    // state for new cells.
    //kinda hard to do here with no access to the cell elements, or syncService...
    // if only I could send a patch like this:
    // newCellsKeys.map((id) => ({id, patch:{fill: () => this.get(key-for-left-neighbor)?.fill }}))
    // and the patchAnything will merge this json-merge-patch, run the function with 'this' set to ReadTransaction
    return {
      initialStyle: [{ col: element.cols.length, style: "__copy_previous_col__" }],
      cols: [...element.cols, { id: cellNanoid(), size: element.cols[element.cols.length - 1].size }],
    };
  }
  if (trait == Trait.tableAddRow) {
    return {
      initialStyle: [{ row: element.rows.length, style: "__copy_previous_row__" }],
      rows: [...element.rows, { id: cellNanoid(), size: element.rows[element.rows.length - 1].size }],
    };
  }

  if (trait == Trait.fontSize) {
    value = value / (element.scaleX ?? 1);
  }
  return value;
}

export function tableCellValidateTraits(element: TypeTableCell & { scaleX: number }, trait: Trait, value: any) {
  if (trait == Trait.fontSize) {
    return value / (element.scaleX ?? 1);
  }
  return value;
}

const findCellAtXY = (x: number, y: number, xs: number[], ys: number[]) => {
  const col = xs.findIndex((x_) => x_ >= x) - 1;
  const row = ys.findIndex((y_) => y_ >= y) - 1;
  return { col, row };
};

interface TableDragListenerProps {
  ids: string[];
  x: number;
  y: number;
  xs: number[];
  ys: number[];
  onDrag: (override: { col: number; row: number; colWidth: number; rowHeight: number } | null) => void;
  onDragEnd: (col: number, row: number, bbox: BoundingBox | null, ids: string[]) => void;
  scaleX?: number;
  scaleY?: number;
}

function TableDragListener({ ids, x, y, xs, ys, onDrag, onDragEnd, scaleX = 1, scaleY = 1 }: TableDragListenerProps) {
  const layerRef = useAtomValue(layerRefAtom);
  const bbox = useRef<BoundingBox>(BoundingBox.from(0, 0, 0, 0));
  const { queue } = useBgJob(onDrag, 1);
  // TODO: this element is rendered whenever xs,ys change, which can happen a lot during dragging
  // check how this affects performance

  // start a bg operation to find the dragged konva elements,
  // measure their unified bounding box, get total size
  useEffect(() => {
    setTimeout(() => {
      const nodes = layerRef.current.find((node: Konva.Node) => ids.includes(node.id())).toArray();
      bbox.current = BoundingBox.expandOnAll(measureNodes(nodes, { skipShadow: true }));
    });
  }, [ids]);

  useAnyEvent(EVT_ELEMENT_DRAG, (event: CustomEvent<{ mousePosition: Point; ids: string[] }>) => {
    const { mousePosition } = event.detail;
    const tableWidth = xs[xs.length - 1];
    const tableHeight = ys[ys.length - 1];
    // check if mousePosition is within the table rect
    const relX = (mousePosition.x - x) / scaleX;
    const relY = (mousePosition.y - y) / scaleY;
    const isOverTable = relX > 0 && relY > 0 && relX < tableWidth && relY < tableHeight;

    if (isOverTable) {
      const { col, row } = findCellAtXY(relX, relY, xs, ys);
      let colWidth = bbox.current.width;
      let rowHeight = bbox.current.height;
      queue({ col, row, colWidth, rowHeight });
    } else {
      queue(null);
    }
  });

  useAnyEvent(EVT_ELEMENT_DROP, (ev: CustomEvent<{ x: number; y: number; ids: string[] }>) => {
    const { x: mouseX, y: mouseY } = ev.detail;
    const tableWidth = xs[xs.length - 1];
    const tableHeight = ys[ys.length - 1];
    // check if mousePosition is within the table rect
    const relX = (mouseX - x) / scaleX;
    const relY = (mouseY - y) / scaleY;
    const isOverTable = relX > 0 && relY > 0 && relX < tableWidth && relY < tableHeight;
    queue(null);
    if (isOverTable) {
      const { col, row } = findCellAtXY(relX, relY, xs, ys);
      onDragEnd(col, row, bbox.current, ids);
    } else {
      onDragEnd(-1, -1, bbox.current, ids);
    }
  });

  return null;
}

//TBD: I think I should pass the originalXY and not the actual XY. That way I can memo the component
// and not re-render it on every mouse move.
function TableResizeContainedListener({
  data,
  element,
  xs,
  ys,
  onEnd,
}: {
  data: {
    nodes: Konva.Node[];
    mapping: Record<string, { col: string; row: string }>;
    setoverrideSize: (val: any) => void;
  };
  element: {
    x: number;
    y: number;
    scaleX?: number;
    scaleY?: number;
    cols: Array<{ id: string; size: number }>;
    rows: Array<{ id: string; size: number }>;
  };
  xs: number[];
  ys: number[];
  onEnd: (requiredSize: any) => void;
}) {
  const { queue, reset } = useBgJob(data.setoverrideSize, 1);

  function computeRequiredSize() {
    let { nodes, mapping } = data;
    let { cols, rows, scaleX = 1, scaleY = 1 } = element;
    const options = { skipShadow: true, relativeTo: nodes[0].getStage() as any };
    const sizes = measureNodes(nodes, options);
    let requiredXs: Record<string, number> = {};
    let requiredYs: Record<string, number> = {};
    for (let i = 0; i < nodes.length; i++) {
      const id = nodes[i].id();
      const box = sizes[i];
      const { col, row } = mapping[id];
      requiredXs[col] = Math.max(requiredXs[col] ?? Number.MIN_SAFE_INTEGER, box.x + box.width);
      requiredYs[row] = Math.max(requiredYs[row] ?? Number.MIN_SAFE_INTEGER, box.y + box.height);
    }
    // requiredXs/Ys is the rightmost/bottommost coordinate for cols and rows that are affected by the resize
    // It's in canvas coordinates (not table coordinates)

    return { requiredXs, requiredYs };

    // requiredXs/Ys is the rightmost/bottommost coordinate for cols and rows that are affected by the resize
    // It's in canvas coordinates (not table coordinates)
    // I can use it to find the required size for every col & row
    // let newSize: OverrideSize = null;
    // for (const [col, coord] of Object.entries(requiredXs)) {
    //   let colIndex = cols.findIndex((c) => c.id == col);
    //   if (colIndex === -1) continue; // should not happen
    //   const columnStart = element.x + xs[colIndex] * scaleX; // in canvas-stage coordinates
    //   if (coord - columnStart > element.cols[colIndex].size * scaleX) {
    //     newSize ??= {};
    //     newSize[col] = (coord - columnStart + 20) / scaleX; // 20 pixels to have a bit of padding
    //   }
    // }

    // for (const [row, coord] of Object.entries(requiredYs)) {
    //   let rowIndex = rows.findIndex((c) => c.id == row);
    //   if (rowIndex === -1) continue; // should not happen
    //   const rowStart = element.y + ys[rowIndex] * scaleY; // again, in canvas-stage coordinates
    //   if (coord - rowStart > element.rows[rowIndex].size * scaleY) {
    //     newSize ??= {};
    //     newSize[row] = (coord - rowStart + 20) / scaleY;
    //   }
    // }
    // return newSize;
  }

  useAnyEvent("transform", () => queue(computeRequiredSize()));
  useAnyEvent("transform-end", () => {
    reset();
    onEnd(computeRequiredSize());
  });
  return null;
}

interface OrderedMap<T> {
  order: string[];
  values: Record<string, T>;
}

function buildOrderedMap(data: { id: string; size: number }[], scale = 1): OrderedMap<number> {
  let order = new Array();
  let values: Record<string, number> = {};
  for (const { id, size } of data) {
    order.push(id);
    values[id] = size * scale;
  }
  return { order, values };
}

function accumulate(v: OrderedMap<number>, accumulator: (prev: number, cur: number) => number) {
  let prev = undefined;
  for (const id of v.order) {
    if (prev) {
      v.values[id] = accumulator(v.values[prev], v.values[id]);
    }
    prev = id;
  }
  return v;
}

function alignTableContentsAfterChange(
  id: string,
  element: TypeTableElement,
  newElement: TypeTableElement,
  cells: Record<string, TypeTableCell>,
  containedElements: Record<string, any>,
  precalculated?: {
    patches?: any[];
    xs?: number[];
    ys?: number[];
  }
) {
  const patches = precalculated?.patches ?? [];

  let xs = accumulate(buildOrderedMap(element.cols, element.scaleX), R.add);
  let ys = accumulate(buildOrderedMap(element.rows, element.scaleY), R.add);

  let new_xs = accumulate(buildOrderedMap(newElement.cols, newElement.scaleX), R.add);
  let new_ys = accumulate(buildOrderedMap(newElement.rows, newElement.scaleY), R.add);

  for (const row_id of new_ys.order) {
    const dy = new_ys.values[row_id] - ys.values[row_id]; // TODO: check old_ys[row_id] exists
    for (const col_id of new_xs.order) {
      const dx = new_xs.values[col_id] - xs.values[col_id]; // TODO: check old_xs[col_id] exists
      if (dx !== 0 || dy !== 0) {
        const cid = fullCellKey(id, col_id, row_id);
        if (cells[cid]?.containedIds?.length) {
          for (const elementId of cells[cid]!.containedIds!) {
            if (!containedElements[elementId]) {
              // it's possible the element was deleted, and the table still has it
              continue;
            }
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              draft.x += dx;
              draft.y += dy;
            })(containedElements[elementId]);

            patches.push({ id: "cElement-" + elementId, patch, inversePatch });
          }
        }
      }
    }
  }
  return patches;
}

function moveContainedElementsAfterResize(
  x: number,
  y: number,
  dx: number,
  dy: number,
  element: TypeTableElement,
  getContainedElementsInCell: (col: number, row: number) => Generator<[string, any]>,
  patches?: any[]
) {
  patches ??= [];
  // all elements in column right of the one resized should be moved
  for (let c = x; c < element.cols.length; c++) {
    for (let r = y; r < element.rows.length; r++) {
      for (const [id, shape] of getContainedElementsInCell(c, r)) {
        const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
          draft.x += dx;
          draft.y += dy;
        })(shape);
        patches.push({ id: "cElement-" + id, patch, inversePatch });
      }
    }
  }
  return patches;
}

function moveContainedElementsAfterDelete(
  element: TypeTableElement,
  deletedCols: null | string[],
  deletedRows: null | string[],
  getContainedElementsInCell: (col: number, row: number) => Generator<[string, any]>,
  patches?: any[]
) {
  patches ??= [];
  const { scaleX = 1, scaleY = 1 } = element;

  if (deletedCols?.length) {
    const sizes = element.cols.map(({ size }) => size * scaleX);
    const newsizes = element.cols.map((col) => (deletedCols.includes(col.id) ? 0 : col.size * scaleX));
    let dx = 0; // accumulated delta-x as we loop over the columns
    for (let c = 0; c < element.cols.length; c++) {
      dx += newsizes[c] - sizes[c];
      if (dx != 0) {
        for (let r = 0; r < element.rows.length; r++) {
          for (const [id, shape] of getContainedElementsInCell(c, r)) {
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              draft.x += dx;
            })(shape);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
          }
        }
      }
    }
  }

  if (deletedRows?.length) {
    const sizes = element.rows.map(({ size }) => size * scaleY);
    const newsizes = element.rows.map((col) => (deletedRows.includes(col.id) ? 0 : col.size * scaleY));
    let dy = 0; // accumulated delta-x as we loop over the columns
    for (let r = 0; r < element.rows.length; r++) {
      dy += newsizes[r] - sizes[r];
      if (dy != 0) {
        for (let c = 0; c < element.rows.length; c++) {
          for (const [id, shape] of getContainedElementsInCell(c, c)) {
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              draft.y += dy;
            })(shape);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
          }
        }
      }
    }
  }

  return patches;
}

//TODO: support multiple cols/rows being resized at once and unite with the ResizeContainedListener
class TrackResizer {
  id: string;
  original: TypeTableElement;
  getContainedElementsInCell: any;
  patchFn: any;

  scaleX: number;
  scaleY: number;
  timer = 0;

  pendingCols: Record<number,number>;
  pendingRows: Record<number,number>;

  constructor(
    id: string,
    element: TypeTableElement,
    getContainedElementsInCell: any,
    patchFn: any,
    private readonly shrinkToOriginalIfPossible = false
  ) {
    this.id = TableIds.ensureFullId(id);
    this.original = element;
    this.patchFn = patchFn;
    this.scaleX = element.scaleX || 1;
    this.scaleY = element.scaleY || 1;
    this.pendingCols = {};
    this.pendingRows = {};
    this.getContainedElementsInCell = getContainedElementsInCell;
    this.updateFn = this.updateFn.bind(this);
  }

  calcDiff() {
    let patches = [];
    const [newElement, patch, inversePatch] = produceWithPatches((draft: any) => {
      for (const col in this.pendingCols) {
        let newSize = this.pendingCols[col];
        if (this.shrinkToOriginalIfPossible) {
          newSize = Math.max(newSize, this.original.cols[+col].size);
        }
        draft.cols[+col].size = newSize;
      }
      for (const row in this.pendingRows) {
        let newSize = this.pendingRows[row];
        if (this.shrinkToOriginalIfPossible) {
          newSize = Math.max(newSize, this.original.rows[+row].size);
        }
        draft.rows[+row].size = newSize;
      }
    })(this.original);
    patches.push({ id: this.id, patch, inversePatch });
    // find dx,dy for the first changed row(this is not correct when resizing multiple contained elements)
    let dx = 0,
      dy = 0;
    let x = 0,
      y = 0;
    for (let i = 0; i < newElement.cols.length; i++) {
      if (newElement.cols[i].size != this.original.cols[i].size) {
        dx = (newElement.cols[i].size - this.original.cols[i].size) * this.scaleX;
        x = i;
        break;
      }
    }
    for (let i = 0; i < newElement.rows.length; i++) {
      if (newElement.rows[i].size != this.original.rows[i].size) {
        dy = (newElement.rows[i].size - this.original.rows[i].size) * this.scaleY;
        y = i;
        break;
      }
    }
    moveContainedElementsAfterResize(x + 1, y + 1, dx, dy, this.original, this.getContainedElementsInCell, patches);
    return patches;
  }

  private updateFn() {
    this.timer = 0;
    let patches = this.calcDiff();
    this.patchFn(patches, true);
  }

  private updateIn(table: any, index: number, value: number) {
    table[index] = value;
    this.timer ||= window.setTimeout(this.updateFn, 33); // update 30 times a second
  }

  updateCol(col: number, newSize: number) {
    this.updateIn(this.pendingCols, col, newSize);
  }

  updateRow(row: number, newSize: number) {
    this.updateIn(this.pendingRows, row, newSize);
  }

  finish() {
    if (this.timer) window.clearTimeout(this.timer);
    let patches = this.calcDiff();
    this.patchFn(patches);
  }
}

class ResizeContainedListener {
  id: string;
  original: any;
  curElement: any;
  getContainedElementsInCell: any;

  patchFn: any;
  scaleX: any;
  scaleY: any;

  originalXs: any[];
  originalYs: any[];
  pendingXs: any;
  pendingYs: any;

  timer = 0;

  constructor(
    id: string,
    element: any,
    getContainedElementsInCell: any,
    patchFn: any,
    private originIsMinimum = false,
    private minimumSize = 10
  ) {
    this.id = TableIds.ensureFullId(id);
    this.curElement = this.original = element;
    this.patchFn = patchFn;
    this.scaleX = element.scaleX || 1;
    this.scaleY = element.scaleY || 1;
    this.originalXs = computeCoordinatesOfLines(element.cols, null);
    this.originalYs = computeCoordinatesOfLines(element.rows, null);
    this.pendingXs = {};
    this.pendingYs = {};
    this.getContainedElementsInCell = getContainedElementsInCell;
    this.updateFn = this.updateFn.bind(this);
  }

  private calcDiff() {
    let patches = [];
    const [newElement, patch, inversePatch] = produceWithPatches((draft: any) => {
      let curX = 0;
      for (let i = 0; i < draft.cols.length; i++) {
        const key = draft.cols[i].id;
        if (key in this.pendingXs) {
          const requiredRightSideForThisColumn = (this.pendingXs[key] - this.original.x) / this.scaleX;
          let newSize = requiredRightSideForThisColumn - curX;
          if (this.originIsMinimum) {
            newSize = Math.max(newSize, this.original.cols[i].size);
          }
          newSize = Math.max(newSize, this.minimumSize);
          draft.cols[i].size = newSize;
        }
        curX += draft.cols[i].size;
      }

      let curY = 0;
      for (let i = 0; i < draft.rows.length; i++) {
        const key = draft.rows[i].id;
        if (key in this.pendingYs) {
          const requiredBottomForThisRow = (this.pendingYs[key] - this.original.y) / this.scaleY;
          let newSize = requiredBottomForThisRow - curY;
          if (this.originIsMinimum) {
            newSize = Math.max(newSize, this.original.rows[i].size);
          }
          newSize = Math.max(newSize, this.minimumSize);
          draft.rows[i].size = newSize;
        }
        curY += draft.rows[i].size;
      }
    })(this.curElement);
    patches.push({ id: this.id, patch, inversePatch });

    // TODO: this works when enlarging, not when shrinking the rows.
    // for example, I enlarge the row and get delta==10,
    // then I shrink the row back to original, delta==0

    let x = 0;
    for (let i = 0; i < newElement.cols.length - 1; i++) {
      // I'm looping until the last-column, no need to check it
      x += newElement.cols[i].size;
      const delta = newElement.cols[i].size - this.curElement.cols[i].size;
      if (delta) {
        for (let j = 0; j < newElement.rows.length; j++) {
          // problem: get-contained-elements always returns the original elements, not the most up-to-date
          for (const [id, element] of this.getContainedElementsInCell(i + 1, j)) {
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              const offset = (draft.x - this.original.x) / this.scaleX - this.originalXs[i + 1];
              draft.x = this.original.x + x * this.scaleX + offset;
            })(element);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
          }
        }
      }
    }

    let y = 0;
    for (let j = 0; j < newElement.rows.length - 1; j++) {
      y += newElement.rows[j].size;
      const delta = newElement.rows[j].size - this.curElement.rows[j].size;
      if (delta) {
        for (let i = 0; i < newElement.cols.length; i++) {
          for (const [id, element] of this.getContainedElementsInCell(i, j + 1)) {
            const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
              const offset = (draft.y - this.original.y) / this.scaleY - this.originalYs[j + 1];
              draft.y = this.original.y + y * this.scaleY + offset;
            })(element);
            patches.push({ id: "cElement-" + id, patch, inversePatch });
          }
        }
      }
    }
    this.curElement = newElement;

    return patches;
  }

  private updateFn() {
    this.timer = 0;
    let patches = this.calcDiff();
    this.patchFn(patches, true);
  }

  private updateIn(table: any, key: string, value: number) {
    table[key] = value;
    this.timer ||= window.setTimeout(this.updateFn, 16);
  }

  updateXline(key: string, newX: number) {
    this.updateIn(this.pendingXs, key, newX);
  }

  updateYline(key: string, newY: number) {
    this.updateIn(this.pendingYs, key, newY);
  }

  finish() {
    if (this.timer) window.clearTimeout(this.timer);
    let patches = this.calcDiff();
    this.patchFn(patches);
  }
}
