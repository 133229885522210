import React, { HTMLInputTypeAttribute, useEffect, useRef, useState } from "react";
import { FieldProps, FormFieldContainer } from "./form-field-container";
import style from "./form-input-field.module.css";
import { FormValidator } from "./use-form-validator";

type InputFieldProps = FieldProps<string> & {
  placeholder?: string;
  value?: string;
  type?: HTMLInputTypeAttribute;
  autoComplete?: "on" | "auto-complete-off";
  onSubmit?: () => void;
  customError?: React.ReactNode;
  customOnChange?: (value: string) => void;
  fontSize?: number;
  customInputStyle?: React.CSSProperties;
};

export default function FormInputField(props: InputFieldProps) {
  const {
    id,
    value,
    placeholder = "",
    type = "text",
    onChange,
    customOnChange,
    onFocus,
    onBlur,
    autoFocus = false,
    autoComplete = "on",
    tabIndex,
    onSubmit,
    disabled,
    fontSize,
    customInputStyle
  } = props;

  const [isFocused, setFocused] = useState(autoFocus);
  const inputRef = useRef<any | null>(null);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    inputRef.current?.addEventListener("keypress", keyPressed);
    return () => inputRef.current?.removeEventListener("keypress", keyPressed);
  }, [onSubmit]);

  function keyPressed(e: any) {
    if (e.key === "Enter" && onSubmit) {
      e.preventDefault();
      onSubmit();
      inputRef.current?.blur();
    }
  }

  function inputChanged(e: any) {
    onChange(e.target.value);
    customOnChange && customOnChange(e.target.value);
  }

  function focus(e: any) {
    setFocused(true);
    onFocus && onFocus(e.target.id);
  }

  function blur(e: any) {
    setFocused(false);
    onBlur && onBlur(e.target.id);
  }

  return (
    <FormFieldContainer {...props} isFocused={isFocused} key={id}>
      <input
        ref={inputRef}
        className={style.input}
        id={id}
        type={type}
        onChange={inputChanged}
        onFocus={focus}
        onBlur={blur}
        placeholder={placeholder}
        value={value}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        disabled={disabled}
        style={{ fontSize, ...customInputStyle}}
      />
    </FormFieldContainer>
  );
}

type InputFieldWithValidatorProps<O, K extends keyof O> = Omit<InputFieldProps, "onChange" | "id"> & {
  id: keyof O;
  validator: FormValidator<O, K>;
}

export function FormInputFieldWithValidator<O, K extends keyof O>(props: InputFieldWithValidatorProps<O, K>) {
  const { id, validator, customError, customOnChange } = props;
  const { values, errors, setValue } = validator;

  return <FormInputField
    { ...props }
    key={ id as string }
    id={ props.id as string }
    value={ values[id as K] }
    onChange={ (val) => setValue(id as K, val) }
    error={ errors[id as K]}
    customError={ customError }
    customOnChange={ customOnChange }
    />
}